import React, { useMemo } from 'react'
import { MembershipSummaryReport, reportsAPI } from '@/api/reports'
import { Loader } from '@/components/Loader'
import { Container, Typography } from '@mui/material'
import { useQuery } from 'react-query'
import { Box } from '@mui/system'
import { TitleWithBackButton } from '@/components/layout/TitleWIthBackButton'
import { reportsConfig } from '@/reportsConfig'

export const MembershipSummary = () => {
  const { data, isLoading } = useQuery<MembershipSummaryReport, Error>(
    ['membership-summary-report'],
    () => reportsAPI.getMembershipSummaryReport(),
    {
      cacheTime: 0,
    },
  )

  const rows = useMemo(
    () => [
      {
        title: 'Number of current memberships:',
        value: data?.number_current_memberships,
      },
      {
        title: 'Number of current members:',
        value: data?.number_current_members,
      },
      {
        title: 'Number of Student (young) collectors:',
        value: data?.number_student_collectors,
      },
      {
        title: 'Number of memberships in grace period:',
        value: data?.number_memberships_in_grace_period,
      },
      {
        title: 'Number of people in grace period:',
        value: data?.number_people_in_grace_period,
      },
      {
        title: 'Number of outstanding renewals:',
        value: data?.number_outstanding_renewals,
      },
      {
        title: 'Number of Traditional Memberships:',
        value: data?.number_of_traditional_memberships,
      },
      {
        title: 'Number of Fully Digital Memberships:',
        value: data?.number_of_digital_memberships,
      },
      {
        title: 'Number of Memberships Transitioning to Digital:',
        value: data?.number_of_transitioning_to_digital_memberships,
      },
    ],
    [
      data?.number_current_members,
      data?.number_current_memberships,
      data?.number_outstanding_renewals,
      data?.number_people_in_grace_period,
      data?.number_memberships_in_grace_period,
      data?.number_student_collectors,
      data?.number_of_traditional_memberships,
      data?.number_of_digital_memberships,
      data?.number_of_transitioning_to_digital_memberships,
    ],
  )

  return (
    <Container maxWidth="xl">
      <TitleWithBackButton
        title={reportsConfig.membershipSummary.title}
        to="/reports"
      />
      {isLoading ? (
        <Loader />
      ) : (
        <Box sx={{ mt: 5 }}>
          {rows.map((row) => (
            <Box
              key={row.title}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                mb: 2,
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  width: '460px',
                  textAlign: 'right',
                  fontWeight: 'bold',
                  marginRight: '20px',
                }}
              >
                {row.title}
              </Typography>
              <Typography sx={{ fontWeight: 'bold' }} variant="h6">
                {row.value}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
    </Container>
  )
}
