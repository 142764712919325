import { createTheme } from '@mui/material/styles'

/**
 * @see https://mui.com/customization/theming/
 */
export const theme = createTheme({
  palette: {
    primary: {
      main: '#B78B5F',
    },
  },
  spacing: 4,
  shape: {
    borderRadius: 0,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ':root a:not(.MuiButton-root)': {
          color: '#8b6239',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          'borderRadius': 0,
          'boxShadow': 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
      variants: [
        {
          props: { variant: 'text' },
          style: {
            textDecoration: 'underline',
            minWidth: 0,
          },
        },
      ],
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          'paddingTop': 7,
          'paddingBottom': 7,
          'paddingLeft': 7,
          'paddingRight': 7,
          '&:first-child': {
            paddingLeft: 14,
          },
          '&:last-child': {
            paddingRight: 14,
          },
        },
      },
    },
  },
})

export default theme
