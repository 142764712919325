import { Button, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const scheme = Yup.object().shape({
  old_password: Yup.string().required('This field is required'),
  new_password: Yup.string().required('This field is required'),
})
interface Props {
  onSubmit: (form: any) => void
  isDisabled: boolean
  errors: string[]
}

export const PasswordSettings: React.FC<Props> = ({
  onSubmit,
  isDisabled,
  errors,
}) => {
  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(scheme),
  })

  return (
    <Box sx={{ my: 5 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h6" sx={{ mb: 3 }}>
          Change Password
        </Typography>
        <Box sx={{ my: 4 }}>
          <TextField
            {...register('old_password')}
            label="Old Password"
            variant="outlined"
            type="password"
            size="small"
            fullWidth
            disabled={isDisabled}
            helperText={formState.errors.old_password?.message}
            error={!!formState.errors.old_password?.message}
          />
          <TextField
            {...register('new_password')}
            label="New Password"
            variant="outlined"
            type="password"
            size="small"
            fullWidth
            sx={{ mt: 4 }}
            disabled={isDisabled}
            helperText={formState.errors.new_password?.message}
            error={!!formState.errors.new_password?.message}
          />
        </Box>
        <Box sx={{ my: 3 }}>
          {errors.map((error) => (
            <div style={{ color: 'tomato' }} key={error}>
              {error}
            </div>
          ))}
        </Box>
        <Button type="submit" variant="contained" disabled={isDisabled}>
          Change password
        </Button>
      </form>
    </Box>
  )
}
