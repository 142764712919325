import React, { useEffect, useState } from 'react'
import {
  Button,
  FormControl,
  Grid,
  TextField,
  Box,
  DialogActions,
  Dialog,
  DialogTitle,
  Typography,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Country } from '@/api/countries'
import { validationErrors } from '@/defaults'

export type Mode = 'edit' | 'create'

interface Props {
  onSubmit: (form: any) => void
  isDisabled: boolean
  values?: Country
  mode?: Mode
  onDelete?: () => void
}

const scheme = Yup.object().shape({
  name: Yup.string()
    .required('This field is required')
    .max(100, validationErrors.maxWidth(100)),
})

export const CountriesForm: React.FC<Props> = ({
  onSubmit,
  isDisabled,
  values,
  mode = 'create',
  onDelete,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(scheme),
  })

  useEffect(() => {
    if (mode === 'edit') {
      reset({ ...values })
    }
  }, [reset, values, mode])

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Box sx={{ my: 5 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} sx={{ my: 5 }}>
          <Grid item xs={3} />
          <Grid item xs={6}>
            <Typography variant="h6" sx={{ mb: 3 }}>
              Country
            </Typography>
            <TextField
              sx={{ mt: 4 }}
              {...register('name')}
              label="Name"
              variant="standard"
              size="small"
              fullWidth
              helperText={errors.name?.message}
              error={!!errors.name?.message}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 5 }}>
          {mode === 'edit' ? (
            <>
              <Grid item xs={3} />
              <Grid item xs={2}>
                <Button
                  onClick={handleClickOpen}
                  fullWidth
                  color="error"
                  disabled={isDisabled}
                >
                  <DeleteIcon />
                  Delete
                </Button>
                <Dialog open={open} onClose={handleClose}>
                  <DialogTitle>
                    Do you really want to delete that country?
                  </DialogTitle>
                  <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button onClick={onDelete} color="error">
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            </>
          ) : null}
          {mode === 'create' && <Grid item xs={4} />}
          <Grid item xs={2}>
            <Link to="/countries" style={{ textDecoration: 'none' }}>
              <Button type="submit" variant="outlined" fullWidth>
                Cancel
              </Button>
            </Link>
          </Grid>
          <Grid item xs={2}>
            <Button
              disabled={isDisabled}
              type="submit"
              variant="contained"
              fullWidth
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}
