export function trimObjectFields<
  T extends Record<string, any> = Record<string, any>,
>(obj: T): T {
  const result = {}

  Object.keys(obj).forEach((k) => {
    const v = obj[k]
    if (typeof v === 'string') {
      ;(result as any)[k] = v.trim()
    } else {
      ;(result as any)[k] = v
    }
  })

  return result as T
}
