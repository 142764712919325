import React from 'react'
import { Container } from '@mui/material'
import { useMutation } from 'react-query'
import { formatForm } from '@/utils/format-form'
import { toast } from 'react-toastify'
import { TitleWithBackButton } from '@/components/layout/TitleWIthBackButton'
import { PaymentsForm } from '@/components/payments'
import { paymentsAPI } from '@/api/payments'
import { useHistory } from 'react-router'

export const PaymentsCreate = () => {
  const history = useHistory()

  const { isLoading, mutate } = useMutation((form: any) =>
    paymentsAPI.create(form),
  )

  const handleSubmit = (form: any) => {
    mutate(formatForm(form), {
      onSuccess: (data) => {
        toast.success('Payment created')
        if (data?.id) {
          history.push(`/payments/edit/${data.id}`)
        }
      },
      onError: () => {
        toast.error('Something went wrong. Please try again.')
      },
    })
  }

  return (
    <Container maxWidth="xl">
      <TitleWithBackButton title="New Payment Details" to="/payments" />
      <PaymentsForm onSubmit={handleSubmit} isDisabled={isLoading} />
    </Container>
  )
}
