import React, { useEffect } from 'react'
import { Container, Grid } from '@mui/material'
import { useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router'
import { Box } from '@mui/system'
import { TitleWithBackButton } from '@/components/layout/TitleWIthBackButton'
import { Loader } from '@/components/Loader'
import { CountryPreview } from '@/components/countries'
import { countriesAPI } from '@/api/countries'

export const CountriesView = () => {
  const { id } = useParams<{ id: string }>()

  const {
    data: country,
    isLoading: isCountryLoading,
    isError,
  } = useQuery(['country', id], () => countriesAPI.getOne(+id))

  const loading = isCountryLoading

  const history = useHistory()
  useEffect(() => {
    if (isError) {
      history.push('/countries')
    }
  }, [history, isError])

  return (
    <Container maxWidth="xl">
      <TitleWithBackButton title="View Country Details" to="/countries" />
      {loading ? (
        <Loader />
      ) : (
        <Box sx={{ my: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CountryPreview country={country} />
            </Grid>
          </Grid>
        </Box>
      )}
    </Container>
  )
}
