import { Maybe } from '@/types'

export function clipText(
  text: Maybe<string>,
  length = 150,
  ellipsis = '...',
): string {
  if (!text) return ''
  if (text.length > length) {
    return text.slice(0, length - ellipsis.length) + ellipsis
  }
  return text
}
