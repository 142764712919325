import React, { useEffect, useState } from 'react'
import { membershipsAPI } from '@/api/memberships'
import { membersAPI } from '@/api/members'
import { MembershipPreview } from '@/components/memberships'
import { Button, Container, Grid, Typography } from '@mui/material'
import { useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router'
import { Box } from '@mui/system'
import { TitleWithBackButton } from '@/components/layout/TitleWIthBackButton'
import { Loader } from '@/components/Loader'
import { PaymentsTable } from '@/components/payments'
import { paymentsAPI } from '@/api/payments'
import { Link } from 'react-router-dom'
import { EmptyResult } from '@/components/shared/EmptyResult'
import {
  GetAllPaymentsMethods,
  paymentsMethodsAPI,
} from '@/api/payments-methods'
import { useCountries } from '@/hooks/useCountries'
import { Country } from '@/api/countries'

export const MembershipsView = () => {
  const { id } = useParams<{ id: string }>()
  const countries = useCountries()

  const [page, setPage] = useState(1)

  const {
    data: membership,
    isLoading: isMembershipLoading,
    isError,
  } = useQuery(['membership', id], () => membershipsAPI.getOne(+id))
  const history = useHistory()
  useEffect(() => {
    if (isError) {
      history.push('/memberships')
    }
  }, [history, isError])

  const { data: members, isLoading: isMembersLoading } = useQuery(
    ['membersForMembership', id],
    () =>
      membersAPI.getAll({
        membership: +id,
      }),
  )

  const { data: methods, isLoading: methodsLoading } = useQuery<
    GetAllPaymentsMethods,
    Error
  >('payments-methods', paymentsMethodsAPI.getAll)

  const { data: paymentsData, isLoading: isPaymentsLoading } = useQuery(
    ['payments', page, id],
    () => paymentsAPI.get(page, { membership_id: id }),
  )

  const handlePageChange = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    current: number,
  ) => {
    setPage(current + 1)
  }

  const loading =
    isMembershipLoading ||
    isPaymentsLoading ||
    isMembersLoading ||
    methodsLoading ||
    countries.isLoading

  return (
    <Container maxWidth="xl">
      <TitleWithBackButton title="View Membership Details" to="/memberships" />
      {loading ? (
        <Loader />
      ) : (
        <Box sx={{ my: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MembershipPreview
                membership={membership}
                members={members}
                countries={countries.data as Country[]}
              />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ mt: 3 }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="h5" sx={{ textTransform: 'uppercase' }}>
                    Payment History
                  </Typography>
                  {`${membership?.status}` !== '2' && (
                    <Button
                      component={Link}
                      to={`/payments/create?membership=${id}`}
                      variant="contained"
                    >
                      + Add Payment
                    </Button>
                  )}
                </Box>
                {paymentsData?.results.length ? (
                  <PaymentsTable
                    payments={paymentsData?.results || []}
                    onPageChange={handlePageChange}
                    page={page - 1}
                    isLoading={isPaymentsLoading}
                    count={paymentsData?.count || 0}
                    hasNext={!!paymentsData?.next}
                    hasPrevious={!!paymentsData?.previous}
                    methods={methods}
                  />
                ) : (
                  <EmptyResult />
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Container>
  )
}
