/**
 * @description The Wordpress component.
 */
import React from 'react'
import { WordpressSettings } from '@/components/wordpress/WordpressSettings'
import { Box, Container } from '@mui/material'
import { PageTitle } from '@/components/shared/PageTitle'

export const Wordpress: React.FC = function (props) {
  return (
    <Container maxWidth="xl">
      <Box sx={{ mt: 6, mb: 4 }}>
        <PageTitle>WordPress</PageTitle>
      </Box>
      <Box>
        <WordpressSettings />
      </Box>
    </Container>
  )
}
