export function renderMembershipMembersNames(
  // eslint-disable-next-line camelcase
  members: { first_name: string; last_name: string }[],
): string {
  return members
    .map((member) =>
      [member.first_name, member.last_name].filter((s) => !!s).join(' '),
    )
    .join(', ')
}
