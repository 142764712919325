import { http } from '@/services/api/http'
import { PaginatedResponse } from './types/shared'

/* eslint-disable */

export interface Country {
  id: number
  name: string
  date_created: string
  date_updated: string
}

export type GetCountriesResponse = PaginatedResponse<Country>

export type GetAllCountriesResponse = Country[]

export type CreateCountryPayload = Omit<
  Country,
  'id' | 'date_created' | 'date_updated'
>

export type GetOneCountryResponse = Country

export type UpdateCountryPayload = Country

/* eslint-enable */

const RESOURCE = '/directories/country'

const get = async (page = 1, filters = {}) => {
  const response = await http.get<GetCountriesResponse>(`${RESOURCE}/`, {
    params: {
      page,
      ...filters,
      ordering: 'name',
    },
  })

  return response.data
}

const getAll = async () => {
  const response = await http.get<GetAllCountriesResponse>(`${RESOURCE}/`, {
    params: {
      no_page: true,
      ordering: 'name',
    },
  })
  return response.data
}

const create = async (payload: CreateCountryPayload) => {
  const response = await http.post(`${RESOURCE}/`, payload)

  return response.data
}

const getOne = async (id: number) => {
  const response = await http.get<GetOneCountryResponse>(`${RESOURCE}/${id}/`)

  return response.data
}

const update = async (id: string, payload: UpdateCountryPayload) => {
  const response = await http.put(`${RESOURCE}/${id}/`, payload)

  return response.data
}

const deleteOne = async (id: string) => {
  const response = await http.delete(`${RESOURCE}/${id}/`)

  return response.data
}

export const countriesAPI = {
  get,
  getAll,
  getOne,
  create,
  update,
  deleteOne,
}
