import React, { useState } from 'react'
import { PasswordSettings, ProfileSettings } from '@/components/account'
import { Container, Grid, Box } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from 'react-query'
import { AuthAPI } from '@/api/auth'
import { fetchUser } from '@/services/store/actions/auth'
import { toast } from 'react-toastify'
import { AxiosError } from 'axios'
import { PageTitle } from '@/components/shared/PageTitle'

export const Account = () => {
  const dispatch = useDispatch()
  /* eslint-disable */
  const { email, first_name, last_name } = useSelector(
    (state: any) => state.auth.user,
  )

  const [passwordErrors, setPasswordErrors] = useState<string[]>([])

  const { mutate: changePasswordMutation, isLoading: isChangePasswordLoading } =
    useMutation((form: any) => AuthAPI.changePassword(form), {
      onSuccess() {
        dispatch(fetchUser())
        toast.success('Password changed')
      },
      onError(error: AxiosError) {
        const data = error?.response?.data
        if (data?.old_password) {
          console.log(data?.old_password)
          setPasswordErrors([...data.old_password])
        }

        if (data?.new_password) {
          setPasswordErrors([...data.new_password])
        }
      },
    })

  const { mutate: updateProfileMutation, isLoading: isUpdateProfileLoading } =
    useMutation((form: any) => AuthAPI.updateProfile(form), {
      onSuccess() {
        dispatch(fetchUser())
        toast.success('Profile updated')
      },
    })

  const handleChangePassword = (form: any) => {
    changePasswordMutation(form)
  }

  const handleUpdateProfile = (form: any) => {
    updateProfileMutation(form)
  }

  return (
    <Container maxWidth="xl">
      <Box sx={{ mt: 6, mb: 4 }}>
        <PageTitle>My Account</PageTitle>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <ProfileSettings
            values={{ email, first_name, last_name }}
            onSubmit={handleUpdateProfile}
            isDisabled={isUpdateProfileLoading}
          />
          <PasswordSettings
            onSubmit={handleChangePassword}
            isDisabled={isChangePasswordLoading}
            errors={passwordErrors}
          />
        </Grid>
      </Grid>
    </Container>
  )
}
