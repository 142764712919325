import React from 'react'
import { EmailListingReport, reportsAPI } from '@/api/reports'
import { Container } from '@mui/material'
import { useQuery } from 'react-query'
import { Box } from '@mui/system'
import { TitleWithBackButton } from '@/components/layout/TitleWIthBackButton'
import { DownloadButton } from '@/components/reports/shared/DownloadButton'
import { EmailListingTable } from '@/components/reports/tables/EmailListingTable'
import { reportsConfig } from '@/reportsConfig'

export const EmailListing = () => {
  const { data: reports, isLoading } = useQuery<EmailListingReport[], Error>(
    ['email-listing-report'],
    () => reportsAPI.getEmailListingReport(),
    {
      cacheTime: 0,
    },
  )

  return (
    <Container maxWidth="xl">
      <TitleWithBackButton
        title={reportsConfig.emailListing.title}
        to="/reports"
      />
      <DownloadButton onClick={() => reportsAPI.downloadEmailListingReport()} />
      <Box sx={{ my: 5 }}>
        <EmailListingTable isLoading={isLoading} reports={reports || []} />
      </Box>
    </Container>
  )
}
