import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { formatter } from '@/utils/format'
import { Button, Grid } from '@mui/material'
import { Country } from '@/api/countries'

interface Props {
  country?: Country | null
}

export const StyledMembershipPreview = styled.article`
  margin: 30px 0 0;

  .row {
    margin: 10px 0 0;
    display: flex;

    .label {
      min-width: 200px;
      color: #717070;
      margin: 0 20px 0 0;
      text-align: right;
    }

    .link {
      color: #8b6239 !important;
    }

    .text {
      color: #000;
    }
  }

  .button-container {
    margin: 40px 0 0;
    display: flex;
    justify-content: center;
  }
`

export const CountryPreview: React.FC<Props> = ({ country }) =>
  country ? (
    <StyledMembershipPreview>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="row">
            <span className="label"> Country ID </span>
            <Link className="text link" to={`/countries/view/${country.id}`}>
              {country.id}
            </Link>
          </div>
          <div className="row">
            <span className="label"> Name </span>
            <span className="text">{country.name}</span>
          </div>
          <div className="row">
            <span className="label"> Date Created </span>
            <span className="text">{formatter.date(country.date_created)}</span>
          </div>
          <div className="row">
            <span className="label"> Date Updated </span>
            <span className="text">{formatter.date(country.date_updated)}</span>
          </div>
        </Grid>
      </Grid>

      <div className="button-container">
        <Button
          variant="contained"
          component={Link}
          to={`/countries/edit/${country.id}`}
        >
          Edit Country
        </Button>
      </div>
    </StyledMembershipPreview>
  ) : null
