import React, { useState } from 'react'
import { AuthAPI, ResetPasswordPayload } from '@/api/auth'
import { Box, Button, Container, TextField, Typography } from '@mui/material'
import { AxiosError } from 'axios'

import { useMutation } from 'react-query'
import { useForm } from 'react-hook-form'
import { Link, Redirect } from 'react-router-dom'

import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useUser } from '@/hooks/useUser'
import { useHistory } from 'react-router'

const scheme = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .required('This field is required'),
})

export const PasswordReset = () => {
  const [message, setMessage] = useState('')
  const history = useHistory()

  const user = useUser()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(scheme) })

  const { isLoading, mutate, isError, isSuccess } = useMutation(
    (form: ResetPasswordPayload) => AuthAPI.resetPassword(form),
    {
      onError: (e: AxiosError) => {
        if (e.response?.status === 400) {
          history.push('/login?afterReset=true')
        } else {
          setMessage('Error happened please try again.')
        }
      },
      onSuccess: () => {
        history.push('/login?afterReset=true')
        reset()
      },
    },
  )

  const onSubmit = (form: ResetPasswordPayload) => mutate(form)

  if (user) {
    return <Redirect to="/" />
  }

  return (
    <Container maxWidth="md">
      <Box sx={{ display: 'flex', alignItems: 'center' }} mt={8}>
        <Box sx={{ width: '100%' }}>
          <Typography
            variant="h3"
            gutterBottom
            fontFamily="Lateef"
            fontSize="65px"
            color="primary"
          >
            Forgot password?
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Enter the email address associated with your account and we will
            send you a link to reset your password.
          </Typography>
          <Box sx={{ mt: 3, maxWidth: '500px' }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box sx={{ my: 3 }}>
                <TextField
                  {...register('email')}
                  fullWidth
                  label="Email"
                  variant="standard"
                  type="email"
                  disabled={isLoading}
                  helperText={errors.email?.message}
                  error={!!errors.email?.message}
                />
              </Box>

              {(isError || isSuccess) && (
                <Box sx={{ mt: 3 }}>
                  <Typography
                    variant="body2"
                    gutterBottom
                    color={isError ? 'error.main' : 'text.main'}
                  >
                    {message}
                  </Typography>
                </Box>
              )}

              <div>
                <Button component={Link} variant="text" to="/login">
                  Back to Login
                </Button>
              </div>

              <Button
                type="submit"
                variant="contained"
                disabled={isLoading}
                sx={{ mt: 4 }}
              >
                Request Password Reset
              </Button>
            </form>
          </Box>
        </Box>
      </Box>
    </Container>
  )
}
