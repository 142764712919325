import React, { useEffect } from 'react'
import { Container, Grid } from '@mui/material'
import { useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router'
import { Box } from '@mui/system'
import { TitleWithBackButton } from '@/components/layout/TitleWIthBackButton'
import { Loader } from '@/components/Loader'
import { CountryPreview } from '@/components/countries'
import { countriesAPI } from '@/api/countries'
import { PaymentsMethodsPreview } from '@/components/payments-methods'
import { paymentsMethodsAPI } from '@/api/payments-methods'

export const PaymentsMethodsView = () => {
  const { id } = useParams<{ id: string }>()

  const {
    data: paymentMethod,
    isLoading: isPaymentMethodLoading,
    isError,
  } = useQuery(['payment-method', id], () => paymentsMethodsAPI.getOne(+id))

  const history = useHistory()
  useEffect(() => {
    if (isError) {
      history.push('/payments-methods')
    }
  }, [history, isError])

  const loading = isPaymentMethodLoading

  return (
    <Container maxWidth="xl">
      <TitleWithBackButton
        title="View Payments Method Details"
        to="/payments-methods"
      />
      {loading ? (
        <Loader />
      ) : (
        <Box sx={{ my: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PaymentsMethodsPreview paymentMethod={paymentMethod} />
            </Grid>
          </Grid>
        </Box>
      )}
    </Container>
  )
}
