export function getAutocompleteLabel<T extends { id: number }>(
  option: T | number,
  options: T[],
  callback: (result: T) => string,
): string {
  let result!: T

  if (typeof option === 'number') {
    result = options.find((item) => item.id === option) as unknown as T
    return callback(result)
  }

  return callback(option)
}

type LabelDispayedRows = {
  from: number
  to: number
  count: number
}

export function labelDisplayedRows(options: LabelDispayedRows): string {
  return `${options.from}-${
    options.to === -1 ? options.count : options.to
  } from ${options.count}`
}
