import { Membership } from '@/api/memberships'
import { Country } from '@/api/countries'
import { clipText } from '@/utils/clipText'

export type RenderMembershipAddressParams = {
  membership: Membership
  countries: Country[]
  html?: boolean
  clip?: boolean
}

export function renderMembershipAddress({
  membership,
  countries,
  html = true,
  clip = false,
}: RenderMembershipAddressParams): string {
  const { state, street, city, country, zip } = membership
  const countryData = countries.find((c) => c.id === country)

  const firstRow = [street]
  const secondRow = [city, [state, zip ? `${zip}` : '']]
  const thirdRow = [countryData?.name || '']

  return [firstRow, secondRow, thirdRow]
    .map((row) =>
      row
        .map((item) => {
          if (typeof item !== 'string' && item) {
            return item
              .filter((el) => el !== '' && el !== undefined && el !== null)
              .join(' ')
          }
          return item
        })
        .filter((el) => el !== '' && el !== undefined && el !== null)
        .join(', '),
    )
    .filter((el) => !!el)
    .map((el) => (clip ? clipText(el) : el))
    .join(html ? '<br/>' : '\n')
}
