import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { formatter } from '@/utils/format'
import { Member } from '@/api/members'
import { Button } from '@mui/material'
import { LinkToEntity } from '@/components/shared/LinkToEntity'
import { Maybe } from '@/types'

interface Props {
  member?: Maybe<Member>
  sourceMember?: Maybe<Member>
  sponsorMember?: Maybe<Member>
}

export const StyledMemberPreview = styled.article`
  margin: 30px 0 0;

  .row {
    margin: 10px 0 0;
    display: flex;

    .label {
      min-width: 200px;
      color: #717070;
      margin: 0 20px 0 0;
      text-align: right;
    }

    .link {
      color: #8b6239 !important;
    }

    .text {
      color: #000;
    }
  }
`

export const MemberPreview: React.FC<Props> = ({
  member,
  sourceMember,
  sponsorMember,
}) =>
  member ? (
    <StyledMemberPreview>
      <div className="row">
        <span className="label"> Member ID </span>
        <Link className="text link" to={`/members/view/${member.id}`}>
          {member.id}
        </Link>
      </div>
      <div className="row">
        <span className="label"> Name </span>
        <span className="text">{`${member.first_name} ${member.last_name}`}</span>
      </div>
      <div className="row">
        <span className="label"> Email </span>
        <span className="text">{member.email ?? '-'}</span>
      </div>
      <div className="row">
        <span className="label"> Phone </span>
        <span className="text">{member.phone ?? '-'}</span>
      </div>
      <div className="row">
        <span className="label"> Company </span>
        <span className="text">{member.company ?? '-'}</span>
      </div>
      <div className="row">
        <span className="label"> Interest </span>
        <span className="text">{member.interests ?? '-'}</span>
      </div>
      <div className="row">
        <span className="label"> Source </span>
        <span>{member.source ?? '-'}</span>
      </div>
      {sourceMember && (
        <div className="row">
          <span className="label"> Source Member </span>
          <Link to={`/members/view/${sourceMember.id}`}>
            {`${sourceMember.first_name} ${sourceMember.last_name} ID(${sourceMember.id})`}
          </Link>
        </div>
      )}
      <div className="row">
        <span className="label"> Student Collector </span>
        <span className="text">{member.young_collector ? 'Yes' : 'No'}</span>
      </div>
      <div className="row">
        <span className="label"> S-C Age at Enrollment </span>
        <span className="text">{member.yc_age ?? '-'}</span>
      </div>
      <div className="row">
        <span className="label"> Sponsor </span>
        {sponsorMember ? (
          <Link to={`/members/view/${sponsorMember.id}`} className="text link">
            {`${sponsorMember.first_name} ${sponsorMember.last_name} (ID ${sponsorMember.id})`}
          </Link>
        ) : (
          <span className="text">-</span>
        )}
      </div>
      <div className="row">
        <span className="label"> Relationship </span>
        <span className="text">{member.sponsor_relationship ?? '-'}</span>
      </div>
      <div className="row">
        <span className="label"> Date Added </span>
        <span className="text">{formatter.date(member.date_created)}</span>
      </div>
      <div className="row">
        <span className="label"> Last Edited </span>
        <span className="text">{formatter.date(member.date_updated)}</span>
      </div>
      <div className="row">
        <span className="label" />
        <Button
          component={LinkToEntity}
          variant="contained"
          entity="member"
          operation="update"
          params={{ id: member.id }}
          dontStyle={true}
        >
          Edit Member
        </Button>
      </div>
    </StyledMemberPreview>
  ) : null
