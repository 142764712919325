import React, { useState } from 'react'
import { Box } from '@mui/system'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, TextField, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { AuthAPI, LoginPayload } from '@/api/auth'
import { useForm } from 'react-hook-form'
import { AxiosError } from 'axios'
import { useMutation } from 'react-query'

const scheme = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .required('This field is required'),
  password: Yup.string().required('This field is required'),
})

interface Props {
  onSuccess: (email: string) => void
}

export const LoginForm: React.FC<Props> = ({ onSuccess }) => {
  const [error, setError] = useState(null)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(scheme) })

  const { isLoading, isError, mutate } = useMutation(
    (form: LoginPayload) => AuthAPI.login(form),
    {
      onError: (e: AxiosError) => {
        if (e?.response?.status === 400) {
          setError(e.response.data.non_field_errors)
        }
        console.error(e)
      },
      onSuccess: (data, variables) => {
        onSuccess(variables.email)
      },
    },
  )

  const onSubmit = (form: LoginPayload) => mutate(form)

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ mt: 3 }}>
        <TextField
          {...register('email')}
          fullWidth
          label="Email"
          variant="standard"
          type="email"
          disabled={isLoading}
          helperText={errors.email?.message}
          error={!!errors.email?.message}
        />
      </Box>
      <Box sx={{ mt: 3 }}>
        <TextField
          {...register('password')}
          fullWidth
          label="Password"
          variant="standard"
          type="password"
          disabled={isLoading}
          helperText={errors.password?.message}
          error={!!errors.password?.message}
        />
      </Box>

      {isError && (
        <Box sx={{ mt: 3 }}>
          <Typography variant="body2" gutterBottom color="error.main">
            {error}
          </Typography>
        </Box>
      )}

      <Box sx={{ mt: 3 }}>
        <Button component={Link} to="/login/reset-password">
          {' '}
          Reset your password
        </Button>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Button type="submit" variant="contained" disabled={isLoading}>
          Login
        </Button>
      </Box>
    </form>
  )
}
