import { http } from '@/services/api/http'
import { PaginatedResponse } from './types/shared'
import saveFile from 'save-file'
import { makeDownloadFileName } from '@/utils/makeDownloadFileName'
import { trimObjectFields } from '@/utils/trimObjectFields'

/* eslint-disable */

export interface Payment {
  id: number
  membership: string
  payment_type: number
  date: string
  donate_amount: number
  check: string
  amount: number
  total_amount: number
  date_created: string
  membership_id: number
  digital: boolean
  members: {
    id: number
    first_name: string
    last_name: string
  }[]
}

export type GetPaymentsResponse = PaginatedResponse<Payment>

export type UpdatePaymentPayload = Partial<Payment>

export type GetOnePaymentResponse = Payment

export type CreatePaymentPayload = Partial<Payment>

/* eslint-enable */

const RESOURCE = '/payments/payment'

const get = async (page = 1, filters = {}) => {
  const response = await http.get<GetPaymentsResponse>(`${RESOURCE}/`, {
    params: {
      page,
      order_by: '-id',
      ...trimObjectFields(filters),
    },
  })
  return response.data
}

const getOne = async (id: number) => {
  const response = await http.get<GetOnePaymentResponse>(`${RESOURCE}/${id}/`)

  return response.data
}

const create = async (payload: CreatePaymentPayload) => {
  const data = {
    ...payload,
  }
  delete data.date_created
  delete data.membership
  delete data.total_amount

  const response = await http.post(`${RESOURCE}/`, trimObjectFields(data))

  return response.data
}

const update = async (id: string, payload: UpdatePaymentPayload) => {
  const data = {
    ...payload,
  }
  delete data.date_created
  delete data.membership
  delete data.total_amount

  const response = await http.put(`${RESOURCE}/${id}/`, trimObjectFields(data))

  return response.data
}

const deleteOne = async (id: string) => {
  const response = await http.delete(`${RESOURCE}/${id}/`)

  return response.data
}

const exportToFile = async (filters = {}) => {
  const response = await http.get(`${RESOURCE}/export/`, {
    responseType: 'blob',
    params: {
      ...trimObjectFields(filters),
      order_by: '-date',
    },
  })

  await saveFile(response.data, makeDownloadFileName('ipba-payments-list'))
}

export const paymentsAPI = {
  get,
  getOne,
  create,
  update,
  deleteOne,
  exportToFile,
}
