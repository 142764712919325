/**
 * @description The EmptyResult component.
 */
import React from 'react'
import { Box } from '@mui/material'

type Props = {
  text?: string
}

export const EmptyResult: React.FC<Props> = function ({
  text = 'No records found.',
}) {
  return (
    <Box my={5} textAlign="center">
      {text}
    </Box>
  )
}
