import React, { useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Box,
  DialogActions,
  Dialog,
  DialogTitle,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { Controller, useForm, FieldValues } from 'react-hook-form'
import { useQuery } from 'react-query'
import { Membership, MembershipStatus } from '@/api/memberships'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { countriesAPI } from '@/api/countries'
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { renderMembershipStatus } from '@/utils/renderMembershipStatus'
import { BackButton } from '@/components/shared/BackButton'
import { validationErrors } from '@/defaults'
import { parse, addDays } from 'date-fns'

type Mode = 'edit' | 'create'

interface Props {
  onSubmit: (form: any) => void
  isDisabled: boolean
  values?: Membership
  mode?: Mode
  onDelete?: () => void
}

const scheme = Yup.object().shape({
  directory_email: Yup.string()
    // .email('Wrong email format')
    .required('This field is required')
    .max(100, validationErrors.maxWidth(100)),
  street: Yup.string()
    .required('This field is required')
    .max(100, validationErrors.maxWidth(100)),
  country: Yup.number().nullable().required('This field is required'),
  status: Yup.number().required('This field is required'),
  city: Yup.string()
    .required('This field is required')
    .max(100, validationErrors.maxWidth(100)),
  zip: Yup.string()
    .required('This field is required')
    .max(100, validationErrors.maxWidth(100)),
  expiration: Yup.date().required('This field is required').nullable(),
  lifetime: Yup.boolean(),
  digital: Yup.boolean(),
  digital_effective_date: Yup.date().when('digital', {
    is: true,
    then: Yup.date().required('This field is required'),
    otherwise: Yup.date().notRequired().nullable(),
  }),
  historical_notes: Yup.string()
    .nullable()
    .max(2000, 'This field must be at most 2000 characters'),
})

export const MembershipsForm: React.FC<Props> = ({
  onSubmit,
  isDisabled,
  values,
  mode = 'create',
  onDelete,
}) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setValue,
  } = useForm<FieldValues>({
    resolver: yupResolver(scheme),
    defaultValues:
      mode === 'create'
        ? {
            status: MembershipStatus.Active,
          }
        : {},
  })

  const digitalFieldValue = watch('digital')
  const digitalEffectiveDateFieldValue = watch('digital_effective_date')
  const expirationDateFieldValue = watch('expiration')

  useEffect(() => {
    if (
      !digitalFieldValue ||
      digitalEffectiveDateFieldValue ||
      !expirationDateFieldValue
    ) {
      return
    }
    const defaultValue =
      expirationDateFieldValue instanceof Date
        ? expirationDateFieldValue
        : parse(expirationDateFieldValue, 'yyyy-MM-dd', new Date())
    setValue('digital_effective_date', addDays(defaultValue, 1))
  }, [
    digitalEffectiveDateFieldValue,
    digitalFieldValue,
    expirationDateFieldValue,
    setValue,
  ])

  useEffect(() => {
    if (digitalFieldValue) {
      return
    }
    if (mode === 'create') {
      setValue('digital_effective_date', null)
      return
    }
    if (mode === 'edit') {
      setValue('digital_effective_date', values?.digital_effective_date || null)
    }
  }, [digitalFieldValue, mode, setValue, values?.digital_effective_date])

  const { data: countries } = useQuery('countries', countriesAPI.getAll)

  useEffect(() => {
    if (mode === 'edit') {
      reset({ ...values })
    }
  }, [reset, values, mode])

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Box sx={{ my: 5 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} sx={{ my: 5 }}>
          <Grid item xs={5}>
            <TextField
              {...register('street')}
              label="Street"
              variant="standard"
              type="text"
              size="small"
              fullWidth
              helperText={errors.street?.message}
              error={!!errors.street?.message}
            />
            <TextField
              {...register('city')}
              label="City"
              variant="standard"
              type="text"
              size="small"
              fullWidth
              margin="dense"
              helperText={errors.city?.message}
              error={!!errors.city?.message}
            />

            <Box mt={2}>
              <FormControl
                fullWidth
                size="small"
                sx={{ my: 2 }}
                error={!!errors.country?.message}
              >
                <InputLabel id="membership_country-label">Country</InputLabel>

                <Controller
                  control={control}
                  name="country"
                  defaultValue=""
                  render={({ field: { onChange, ...props } }) => (
                    <Select
                      labelId="membership_country-label"
                      label="Country"
                      onChange={onChange}
                      {...props}
                      size="small"
                      error={!!errors.country?.message}
                    >
                      <MenuItem value="">- select -</MenuItem>
                      {countries?.map((country) => (
                        <MenuItem value={country.id} key={country.name}>
                          {country.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />

                {errors.country?.message && (
                  <FormHelperText color="error">
                    {errors.country?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>

            <TextField
              {...register('state')}
              label="State / Region"
              variant="standard"
              type="text"
              size="small"
              fullWidth
              margin="dense"
              helperText={errors.state?.message}
              error={!!errors.state?.message}
            />
            <TextField
              {...register('zip')}
              label="ZIP / Postal Code"
              variant="standard"
              type="text"
              size="small"
              fullWidth
              margin="dense"
              helperText={errors.zip?.message}
              error={!!errors.zip?.message}
            />
            <TextField
              {...register('directory_email')}
              label="Email"
              variant="standard"
              type="text"
              size="small"
              fullWidth
              margin="dense"
              helperText={errors.directory_email?.message}
              error={!!errors.directory_email?.message}
            />

            <Controller
              control={control}
              name="address_private"
              defaultValue={false}
              render={({ field }) => (
                <FormControlLabel
                  sx={{ mt: 3 }}
                  control={<Checkbox {...field} checked={field.value} />}
                  label="Address Private"
                />
              )}
            />
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={5}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name="expiration"
                  defaultValue={null}
                  render={({ field: { onChange, value } }) => {
                    const originValue = value
                    if (typeof value === 'string') {
                      value = parse(value, 'yyyy-MM-dd', new Date())
                      if (Number.isNaN(value.getTime())) {
                        value = parse(originValue, 'MM/dd/yyyy', new Date())
                      }
                    }
                    return (
                      <DesktopDatePicker
                        label="Expiration Date"
                        inputFormat="MM/dd/yyyy"
                        onChange={onChange}
                        value={value}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            helperText={errors.expiration?.message}
                            error={!!errors.expiration?.message}
                            size="small"
                          />
                        )}
                      />
                    )
                  }}
                />
              </FormControl>
            </LocalizationProvider>

            <Box mt={2}>
              <FormControl
                fullWidth
                size="small"
                sx={{ mt: 3 }}
                error={!!errors.status?.message}
              >
                <InputLabel id="membership_status-label">
                  Membership Status
                </InputLabel>

                <Controller
                  control={control}
                  name="status"
                  defaultValue=""
                  render={({ field: { onChange, ...props } }) => (
                    <Select
                      labelId="membership_status-label"
                      label="Membership Status"
                      onChange={onChange}
                      size="small"
                      {...props}
                    >
                      <MenuItem value={MembershipStatus.Active}>
                        {renderMembershipStatus(MembershipStatus.Active)}
                      </MenuItem>
                      <MenuItem value={MembershipStatus.Inactive}>
                        {renderMembershipStatus(MembershipStatus.Inactive)}
                      </MenuItem>
                      <MenuItem value={MembershipStatus.Deceased}>
                        {renderMembershipStatus(MembershipStatus.Deceased)}
                      </MenuItem>
                    </Select>
                  )}
                />

                {errors.status?.message && (
                  <FormHelperText color="error">
                    {errors.status?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>

            <Box>
              <Controller
                control={control}
                name="lifetime"
                defaultValue={false}
                render={({ field }) => (
                  <FormControlLabel
                    sx={{ mt: 3 }}
                    control={<Checkbox {...field} checked={field.value} />}
                    label="Lifetime Membership"
                  />
                )}
              />
            </Box>

            <Box>
              <Controller
                control={control}
                name="digital"
                defaultValue={false}
                render={({ field }) => (
                  <FormControlLabel
                    sx={{ mt: 3 }}
                    control={<Checkbox {...field} checked={field.value} />}
                    label="Digital Membership"
                  />
                )}
              />
            </Box>

            {digitalFieldValue && (
              <Box mt={2}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <FormControl fullWidth>
                    <Controller
                      control={control}
                      name="digital_effective_date"
                      defaultValue={null}
                      render={({ field: { onChange, value } }) => {
                        const originValue = value
                        if (typeof value === 'string') {
                          value = parse(value, 'yyyy-MM-dd', new Date())
                          if (Number.isNaN(value.getTime())) {
                            value = parse(originValue, 'MM/dd/yyyy', new Date())
                          }
                        }
                        return (
                          <DesktopDatePicker
                            label="Digital Effective Date"
                            inputFormat="MM/dd/yyyy"
                            onChange={onChange}
                            value={value}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                helperText={
                                  errors.digital_effective_date?.message
                                    ? 'This field is required'
                                    : undefined
                                }
                                error={!!errors.digital_effective_date?.message}
                                size="small"
                              />
                            )}
                          />
                        )
                      }}
                    />
                  </FormControl>
                </LocalizationProvider>
              </Box>
            )}

            <TextField
              {...register('historical_notes')}
              label="Historical Notes"
              variant="standard"
              type="text"
              size="small"
              fullWidth
              margin="dense"
              multiline
              helperText={errors.historical_notes?.message}
              error={!!errors.historical_notes?.message}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mt: 5 }}>
          {mode === 'edit' ? (
            <>
              <Grid item xs={3} />
              <Grid item xs={2}>
                <Button
                  onClick={handleClickOpen}
                  fullWidth
                  color="error"
                  disabled={isDisabled}
                >
                  <DeleteIcon />
                  Delete
                </Button>
                <Dialog open={open} onClose={handleClose}>
                  <DialogTitle>
                    Do you really want to delete that membership?
                  </DialogTitle>
                  <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button onClick={onDelete} color="error">
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            </>
          ) : null}
          {mode === 'create' && <Grid item xs={4} />}
          <Grid item xs={2}>
            <BackButton defaultTo="/memberships" variant="outlined" fullWidth>
              Cancel
            </BackButton>
          </Grid>
          <Grid item xs={2}>
            <Button
              disabled={isDisabled}
              type="submit"
              variant="contained"
              fullWidth
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}
