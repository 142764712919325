import React from 'react'
import { Button } from '@mui/material'
import { Box } from '@mui/system'

interface Props {
  onClick: () => void
}

export const DownloadButton: React.FC<Props> = ({ onClick }) => (
  <Box sx={{ mt: 1, mb: 2, display: 'flex', justifyContent: 'flex-end' }}>
    <Button color="primary" variant="contained" onClick={onClick}>
      EXPORT...
    </Button>
  </Box>
)
