import moment from 'moment'
import numbro from 'numbro'

const dateFormats = {
  full: 'MMMM D, YYYY',
  filter: 'YYYY-MM-DD',
  short: 'MMM D, YYYY',
}

type DateFormatsKeys = keyof typeof dateFormats

const dateFormatter = (
  date: Date | string,
  format: DateFormatsKeys = 'full',
): string => moment(date).format(dateFormats[format])

const textFormatter = (text: string, maxLength = 20): string => {
  if (!text.length) {
    return '-'
  }

  return `${text?.slice(0, maxLength - 3)}...`
}

export const currencyFormatter = (total: number): string =>
  numbro(total).formatCurrency({
    mantissa: 2,
    currencyPosition: 'prefix',
  })

export const formatter = {
  date: dateFormatter,
  text: textFormatter,
  currency: currencyFormatter,
}
