import React from 'react'
import { Membership } from '@/api/memberships'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { formatter } from '@/utils/format'
import { Button, Grid } from '@mui/material'
import { renderMembershipStatus } from '@/utils/renderMembershipStatus'
import { Member } from '@/api/members'
import { LinkToEntity } from '@/components/shared/LinkToEntity'
import { renderMembershipWordpressRole } from '@/utils/renderMembershipWordpressRole'

interface Props {
  membership?: Membership | null
  members?: Member[]
}

export const StyledMembershipShortPreview = styled.div`
  .row {
    margin: 10px 0 0;
    display: flex;

    .label {
      min-width: 200px;
      color: #717070;
      margin: 0 20px 0 0;
      text-align: right;
    }

    .link {
      color: #8b6239 !important;
    }

    .text {
      color: #000;
    }
  }

  .button-container {
    margin: 40px 0 0;
    display: flex;
    justify-content: center;
  }
`

export const MembershipShortPreview: React.FC<Props> = ({
  membership,
  members,
}) =>
  membership ? (
    <StyledMembershipShortPreview>
      <div className="row">
        <span className="label"> Membership ID </span>
        <Link className="text link" to={`/memberships/view/${membership.id}`}>
          {membership.id}
        </Link>
      </div>
      <div className="row">
        <span className="label"> Status </span>
        <span className="text">
          {renderMembershipStatus(membership.status)}
        </span>
      </div>
      <div className="row">
        <span className="label"> WordPress role </span>
        <span className="text">
          {renderMembershipWordpressRole(membership.status)}
        </span>
      </div>
      <div className="row">
        <span className="label"> # of Members </span>
        <div>
          <span className="text">{membership.members_number}</span>
          {members?.map((member) => (
            <div key={member.id}>
              <LinkToEntity
                entity="member"
                params={{ id: member.id }}
                operation="read"
              >
                {`${[member.first_name, member.last_name]
                  .filter((s) => s.length)
                  .join(' ')} (ID: ${member.id})`}
              </LinkToEntity>
            </div>
          ))}
          <div>
            <LinkToEntity
              queryParams={{ membership: membership.id }}
              entity="member"
              operation="create"
            >
              + Add new Member
            </LinkToEntity>
          </div>
        </div>
      </div>
      <div className="row">
        <span className="label"> Expiration Date </span>
        <span className="text">{formatter.date(membership.expiration)}</span>
      </div>
      <div className="row">
        <span className="label"> Date Created </span>
        <span className="text">{formatter.date(membership.date_created)}</span>
      </div>
      <div className="row">
        <span className="label"> Last Edited </span>
        <span className="text">{formatter.date(membership.date_updated)}</span>
      </div>
    </StyledMembershipShortPreview>
  ) : null
