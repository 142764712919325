import { MembershipStatus } from '@/api/memberships'

export function renderMembershipWordpressRole(
  status: MembershipStatus,
): string {
  const s = typeof status === 'number' ? status : parseInt(status, 10)
  if (s === MembershipStatus.Active) return 'Member'
  if (s === MembershipStatus.Inactive) return 'Subscriber'
  return 'N/A'
}
