import { createStore, AnyAction, applyMiddleware, compose } from 'redux'
import thunk, { ThunkMiddleware } from 'redux-thunk'
import createRootReducer from './reducers'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'

export type RootState = ReturnType<ReturnType<typeof createRootReducer>>

const thunkMiddleware: ThunkMiddleware<RootState, AnyAction> = thunk

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const history = createBrowserHistory()

// eslint-disable-next-line
export default function configureStore() {
  return createStore(
    createRootReducer(history), // root reducer with router state
    compose(
      applyMiddleware(thunkMiddleware, routerMiddleware(history)),
      (window as any).devToolsExtension
        ? (window as any).devToolsExtension()
        : (f: any) => f,
    ),
  )
}

export const store = configureStore()
