import { formatter } from './format'

export const formatForm = (
  form: Record<string, unknown>,
): Record<string, unknown> => {
  const result: Record<string, unknown> = {}

  Object.keys(form).forEach((key) => {
    const value = form[key]
    if (value instanceof Date) {
      const date = form[key] as Date
      result[key] = formatter.date(date, 'filter')
      return
    }
    if (Array.isArray(value) && value.length > 0) {
      result[key] = value.join(', ')
      if (key === 'source_arr') {
        result.source = result[key]
      }
      return
    }

    if (Array.isArray(value) && value.length <= 0) {
      return
    }

    if (value || value === 0 || value === false) {
      result[key] = value
    }
  })

  return result
}
