import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { formatter } from '@/utils/format'
import { Button, Grid } from '@mui/material'
import { PaymentsMethod } from '@/api/payments-methods'

interface Props {
  paymentMethod?: PaymentsMethod | null
}

export const StyledMembershipPreview = styled.article`
  margin: 30px 0 0;

  .row {
    margin: 10px 0 0;
    display: flex;

    .label {
      min-width: 200px;
      color: #717070;
      margin: 0 20px 0 0;
      text-align: right;
    }

    .link {
      color: #8b6239 !important;
    }

    .text {
      color: #000;
    }
  }

  .button-container {
    margin: 40px 0 0;
    display: flex;
    justify-content: center;
  }
`

export const PaymentsMethodsPreview: React.FC<Props> = ({ paymentMethod }) =>
  paymentMethod ? (
    <StyledMembershipPreview>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="row">
            <span className="label"> Payment Method ID </span>
            <Link
              className="text link"
              to={`/payments-methods/view/${paymentMethod.id}`}
            >
              {paymentMethod.id}
            </Link>
          </div>
          <div className="row">
            <span className="label"> Title </span>
            <span className="text">{paymentMethod.title}</span>
          </div>
          <div className="row">
            <span className="label"> Date Created </span>
            <span className="text">
              {formatter.date(paymentMethod.date_created)}
            </span>
          </div>
          <div className="row">
            <span className="label"> Date Updated </span>
            <span className="text">
              {formatter.date(paymentMethod.date_updated)}
            </span>
          </div>
        </Grid>
      </Grid>

      <div className="button-container">
        <Button
          variant="contained"
          component={Link}
          to={`/payments-methods/edit/${paymentMethod.id}`}
        >
          Edit Payment Method
        </Button>
      </div>
    </StyledMembershipPreview>
  ) : null
