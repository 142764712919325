import React, { useEffect, useMemo } from 'react'
import { Container, Grid } from '@mui/material'
import { useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router'
import { Box } from '@mui/system'
import { TitleWithBackButton } from '@/components/layout/TitleWIthBackButton'
import { Loader } from '@/components/Loader'
import { paymentsAPI } from '@/api/payments'
import { PaymentPreview } from '@/components/payments'
import {
  GetAllPaymentsMethods,
  paymentsMethodsAPI,
} from '@/api/payments-methods'
import { useMembership } from '@/hooks/useMembership'
import { useCountries } from '@/hooks/useCountries'

export const PaymentsView = () => {
  const { id } = useParams<{ id: string }>()
  const countries = useCountries()

  const {
    data: payment,
    isLoading: isPaymentLoading,
    isError,
  } = useQuery(['payment', id], () => paymentsAPI.getOne(+id))
  const history = useHistory()

  useEffect(() => {
    if (isError) {
      history.push('/payments')
    }
  }, [history, isError])

  const membership = useMembership(payment?.membership_id)

  const { data: methods, isLoading: methodsLoading } = useQuery<
    GetAllPaymentsMethods,
    Error
  >('payments-methods', paymentsMethodsAPI.getAll)

  const loading = isPaymentLoading || methodsLoading || countries.isLoading

  const paymentMethod: string = useMemo(() => {
    if (!payment || !methods) return ''
    return methods.find((m) => m.id === payment.payment_type)?.title || ''
  }, [methods, payment])

  return (
    <Container maxWidth="xl">
      <TitleWithBackButton title="View Payment Details" to="/payments" />
      {loading ? (
        <Loader />
      ) : (
        <Box sx={{ my: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PaymentPreview
                payment={payment}
                paymentMethod={paymentMethod}
                membership={membership.data || null}
                countries={countries.data}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Container>
  )
}
