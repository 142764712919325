export enum AuthActionTypes {
  Login = 'Auth/Login',
  Logout = 'Auth/Logout',
  SetLoading = 'Auth/SetLoading',
}

export type UserRole =
  | 'administrator'
  | 'president'
  | 'news_editor'
  | 'publisher'

export interface User {
  id: number
  email: string

  // eslint-disable-next-line
  first_name: string

  // eslint-disable-next-line
  last_name: string
  roles: UserRole[]
}

interface LoginAction {
  type: AuthActionTypes.Login
  payload: User
}

interface LogoutAction {
  type: AuthActionTypes.Logout
}

interface SetLoadingAction {
  type: AuthActionTypes.SetLoading
  payload: boolean
}

export type AuthAction = LoginAction | LogoutAction | SetLoadingAction
