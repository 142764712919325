import React, { useEffect } from 'react'
import { formatForm } from '@/utils/format-form'
import { Container } from '@mui/material'
import { useMutation, useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router'
import { toast } from 'react-toastify'
import { TitleWithBackButton } from '@/components/layout/TitleWIthBackButton'
import { Loader } from '@/components/Loader'
import { paymentsAPI } from '@/api/payments'
import { PaymentsForm } from '@/components/payments'
import { useGoBack } from '@/hooks/useGoBack'

export const PaymentsEdit = () => {
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const goBack = useGoBack()

  const {
    data: payment,
    isLoading: isPaymentLoading,
    isError,
  } = useQuery(['payment', id], () => paymentsAPI.getOne(+id))
  useEffect(() => {
    if (isError) {
      history.push('/payments')
    }
  }, [history, isError])

  const { isLoading, mutate: updateMutation } = useMutation(
    ({ id: paymentID, form }: { id: string; form: any }) =>
      paymentsAPI.update(paymentID, form),
  )
  const { mutate: deleteMutation } = useMutation((memberId: string) =>
    paymentsAPI.deleteOne(memberId),
  )

  const handleSubmit = (form: any) => {
    updateMutation(
      { id, form: formatForm(form) },
      {
        onSuccess: () => {
          toast.success('Payment successfully updated.')
          // goBack('/payments')
        },
      },
    )
  }

  const handleDelete = () => {
    deleteMutation(id, {
      onSuccess: () => {
        toast.success('Payment successfully deleted.')
        history.push('/payments')
      },
    })
  }

  return (
    <Container maxWidth="xl">
      <TitleWithBackButton title="Edit Payment Details" to="/payments" />
      {isPaymentLoading ? (
        <Loader />
      ) : (
        <PaymentsForm
          onSubmit={handleSubmit}
          onDelete={handleDelete}
          isDisabled={isLoading}
          values={payment}
          mode="edit"
        />
      )}
    </Container>
  )
}
