import React, { useMemo } from 'react'
import { Membership } from '@/api/memberships'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Button, Grid } from '@mui/material'
import { Member } from '@/api/members'
import { MembershipShortPreview } from '@/components/memberships/MembershipShortPreview'
import { Country } from '@/api/countries'
import { formatter } from '@/utils/format'

interface Props {
  membership?: Membership | null
  members?: Member[]
  countries?: Country[]
}

export const StyledMembershipPreview = styled.article`
  margin: 30px 0 0;

  .row {
    margin: 10px 0 0;
    display: flex;

    .label {
      min-width: 200px;
      color: #717070;
      margin: 0 20px 0 0;
      text-align: right;
    }

    .link {
      color: #8b6239 !important;
    }

    .text {
      color: #000;
    }
  }

  .button-container {
    margin: 40px 0 0;
    display: flex;
    justify-content: center;
  }
`

export const MembershipPreview: React.FC<Props> = ({
  membership,
  members,
  countries,
}) => {
  const country = useMemo(() => {
    if (!countries) {
      return null
    }
    return countries.find((c) => c.id === membership?.country)
  }, [countries, membership])

  return membership ? (
    <StyledMembershipPreview>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <MembershipShortPreview membership={membership} members={members} />
        </Grid>
        <Grid item xs={6}>
          <div className="row">
            <span className="label"> Address </span>
            <span className="text">
              {[
                membership.street,
                membership.city,
                membership.state,
                country?.name,
                membership.zip,
              ]
                .filter((s) => !!s)
                .join(', ')}
            </span>
          </div>
          <div className="row">
            <span className="label"> Address Status </span>
            <span className="text">
              {membership.address_private ? 'Private' : 'Public'}
            </span>
          </div>

          <div className="row">
            <span className="label"> Directory Email </span>
            <a
              className="text link"
              href={`mailto:${membership.directory_email}`}
            >
              {membership.directory_email}
            </a>
          </div>
          <div className="row">
            <span className="label"> Lifetime Membership </span>
            <span className="text">{membership.lifetime ? 'Yes' : 'No'}</span>
          </div>
          <div className="row">
            <span className="label"> Digital Membership </span>
            <span className="text">{membership.digital ? 'Yes' : 'No'}</span>
          </div>
          {membership.digital && (
            <div className="row">
              <span className="label"> Digital Effective Date </span>
              <span className="text">
                {membership.digital_effective_date
                  ? formatter.date(membership.digital_effective_date)
                  : ''}
              </span>
            </div>
          )}
          <div className="row">
            <span className="label"> Historical Notes </span>
            <span className="text">{membership.historical_notes ?? '-'}</span>
          </div>
        </Grid>
      </Grid>
      <div className="button-container">
        <Button
          variant="contained"
          component={Link}
          to={`/memberships/edit/${membership.id}`}
        >
          Edit Membership
        </Button>
      </div>
    </StyledMembershipPreview>
  ) : null
}
