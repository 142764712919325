import { appConfig } from '@/config'
import axios from 'axios'
import { dispatchEvent } from '@/events/auth'

export const http = axios.create({
  baseURL: appConfig.api.baseURL,
})

const blacklist = ['/auth/signin/', '/auth/signin/confirm/']

/* eslint-disable */

http.interceptors.request.use((config) => {
  if (config.url && !blacklist.includes(config.url)) {
    const token = localStorage.getItem('token')
    config.headers.Authorization = token ? `Token ${token}` : ''
    return config
  }

  return config
})

http.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response.status === 401) {
      dispatchEvent()
    }
    return Promise.reject(error)
  },
)
