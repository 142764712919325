import React from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import { Box, styled } from '@mui/system'
import { formatter } from '@/utils/format'
import { Loader } from '../Loader'
import { Membership, MembershipOrderBy } from '@/api/memberships'
import { labelDisplayedRows } from '@/utils/inputs'
import { LinkToEntity } from '@/components/shared/LinkToEntity'
import { renderMembershipStatus } from '@/utils/renderMembershipStatus'
import { EmptyResult } from '@/components/shared/EmptyResult'
import { TablePaginationActions } from '@/components/shared/TablePaginationActions'
import { OrderingTableButton } from '@/components/shared/OrderingTableButton'
import { Country } from '@/api/countries'
import { clipText } from '@/utils/clipText'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

interface Props {
  memberships: Membership[]
  isLoading: boolean
  count: number
  hasNext: boolean
  hasPrevious: boolean
  page: number
  ordering: MembershipOrderBy
  onChangeOrdering: (ordering: MembershipOrderBy) => void
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number,
  ) => void
  countries?: Country[]
}

export const MembershipsTable: React.FC<Props> = ({
  memberships,
  isLoading,
  count,
  hasNext,
  hasPrevious,
  page,
  onPageChange,
  ordering,
  onChangeOrdering,
  countries,
}) => {
  if (isLoading) {
    return <Loader />
  }

  const renderPagination = () => (
    <TablePagination
      component="div"
      count={count}
      rowsPerPage={100}
      rowsPerPageOptions={[100]}
      labelDisplayedRows={labelDisplayedRows}
      page={page}
      onPageChange={onPageChange}
      backIconButtonProps={{
        disabled: !hasPrevious,
      }}
      nextIconButtonProps={{
        disabled: !hasNext,
      }}
      ActionsComponent={TablePaginationActions}
    />
  )

  return (
    <Box sx={{ mt: 5 }}>
      {memberships.length ? (
        <>
          {renderPagination()}
          <TableContainer
            component={Paper}
            sx={{
              minWidth: '100%',
              width: 'min-content',
            }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow sx={{ whiteSpace: 'nowrap' }}>
                  <TableCell>
                    <OrderingTableButton
                      onClick={onChangeOrdering}
                      current={ordering}
                      asc="id"
                    >
                      ID
                    </OrderingTableButton>
                  </TableCell>
                  <TableCell>
                    <OrderingTableButton
                      onClick={onChangeOrdering}
                      current={ordering}
                      asc="street,city,state,zip,country"
                      desc="-street,-city,-state,-zip,-country"
                    >
                      Address
                    </OrderingTableButton>
                  </TableCell>
                  <TableCell>
                    <OrderingTableButton
                      onClick={onChangeOrdering}
                      current={ordering}
                      asc="members_count"
                    >
                      # of Mbrs
                    </OrderingTableButton>
                  </TableCell>
                  <TableCell>
                    <OrderingTableButton
                      onClick={onChangeOrdering}
                      current={ordering}
                      asc="members__last_name"
                    >
                      Name(s)
                    </OrderingTableButton>
                  </TableCell>
                  <TableCell>
                    <OrderingTableButton
                      onClick={onChangeOrdering}
                      current={ordering}
                      asc="directory_email"
                    >
                      Directory Email
                    </OrderingTableButton>
                  </TableCell>
                  <TableCell>
                    <OrderingTableButton
                      onClick={onChangeOrdering}
                      current={ordering}
                      asc="lifetime"
                    >
                      Lifetime Mbrship
                    </OrderingTableButton>
                  </TableCell>
                  <TableCell>
                    <OrderingTableButton
                      onClick={onChangeOrdering}
                      current={ordering}
                      asc="status"
                    >
                      Status
                    </OrderingTableButton>
                  </TableCell>
                  <TableCell>
                    <OrderingTableButton
                      onClick={onChangeOrdering}
                      current={ordering}
                      asc="date_created"
                    >
                      Date Created
                    </OrderingTableButton>
                  </TableCell>
                  <TableCell>
                    <OrderingTableButton
                      onClick={onChangeOrdering}
                      current={ordering}
                      asc="expiration"
                    >
                      Expiration Date
                    </OrderingTableButton>
                  </TableCell>
                  <TableCell>
                    <OrderingTableButton
                      onClick={onChangeOrdering}
                      current={ordering}
                      asc="digital"
                    >
                      Digital Mbrship
                    </OrderingTableButton>
                  </TableCell>
                  <TableCell>
                    <OrderingTableButton
                      onClick={onChangeOrdering}
                      current={ordering}
                      asc="digital_effective_date"
                    >
                      Digital Eff. Date
                    </OrderingTableButton>
                  </TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {memberships.map((membership) => (
                  <StyledTableRow
                    key={membership.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {membership.id}
                    </TableCell>
                    <TableCell>
                      {clipText(
                        [
                          membership.street,
                          membership.city,
                          membership.state,
                          countries?.find((c) => c.id === membership.country)
                            ?.name,
                          membership.zip,
                        ]
                          .filter((l) => !!l)
                          .join(', '),
                      )}
                    </TableCell>
                    <TableCell>{membership.members_number}</TableCell>
                    <TableCell>
                      {membership.members.map((m) => (
                        <span key={m.id}>
                          {clipText(
                            [m.first_name, m.last_name]
                              .filter((s) => !!s)
                              .join(' '),
                          )}
                          <br />
                        </span>
                      ))}
                    </TableCell>
                    <TableCell>
                      {clipText(membership.directory_email)}
                    </TableCell>
                    <TableCell>{membership.lifetime ? 'Yes' : ''}</TableCell>
                    <TableCell>
                      {renderMembershipStatus(membership.status)}
                    </TableCell>
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>
                      {formatter.date(membership.date_created, 'short')}
                    </TableCell>
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>
                      {formatter.date(membership.expiration, 'short')}
                    </TableCell>
                    <TableCell>{membership.digital ? 'Yes' : ''}</TableCell>
                    <TableCell>
                      {membership.digital_effective_date
                        ? formatter.date(
                            membership.digital_effective_date,
                            'short',
                          )
                        : ''}
                    </TableCell>
                    <TableCell>
                      <LinkToEntity
                        entity="membership"
                        operation="read"
                        params={{ id: membership.id }}
                        style={{ marginRight: '10px' }}
                      >
                        View
                      </LinkToEntity>
                      <LinkToEntity
                        entity="membership"
                        operation="update"
                        params={{ id: membership.id }}
                      >
                        Edit
                      </LinkToEntity>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {renderPagination()}
        </>
      ) : (
        <EmptyResult />
      )}
    </Box>
  )
}
