/**
 * @description The BackButton component.
 */
import React from 'react'
import { Button, ButtonProps } from '@mui/material'
import { Link, useHistory } from 'react-router-dom'

type Props = {
  defaultTo?: string
} & ButtonProps

export const BackButton: React.FC<Props> = function (props) {
  const { defaultTo, ...rest } = props
  const history = useHistory()

  const propsToUse = {
    ...rest,
    ...(history.length === 0 && defaultTo
      ? {
          to: defaultTo,
          component: Link,
        }
      : {}),
    ...(history.length > 0
      ? {
          onClick: () => history.goBack(),
        }
      : {}),
  }

  return <Button {...propsToUse} />
}
