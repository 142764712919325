/**
 * @description The WordpressSettings component.
 */
import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import { syncWpUsers } from '@/api/wordpress'
import { useMutation } from 'react-query'

export const WordpressSettings: React.FC = function (props) {
  const syncMutation = useMutation(syncWpUsers)

  return (
    <Box>
      {!syncMutation.isSuccess && (
        <Box>
          <Typography>
            Synchronize perfumebottles.org users (roles "Member" and
            "Subscriber") according to their current membership statuses
          </Typography>
        </Box>
      )}
      <Box mt={5}>
        {syncMutation.isSuccess ? (
          <Typography sx={{ mt: 2 }} color="success">
            Synchronization has started. It may take up to 60 minutes.
          </Typography>
        ) : (
          <Box>
            <Button
              onClick={() => syncMutation.mutate()}
              disabled={syncMutation.isLoading}
              variant="contained"
            >
              {syncMutation.isLoading
                ? 'Starting...'
                : 'Update Users in perfumebottles.org'}
            </Button>
            <Typography sx={{ mt: 2 }} color="success">
              (The synchronization procedure may take up to 60 minutes)
            </Typography>
          </Box>
        )}
        {syncMutation.isError && (
          <Typography sx={{ mt: 2 }} color="error">
            Can't start synchronization. Something went wrong.
          </Typography>
        )}
      </Box>
    </Box>
  )
}
