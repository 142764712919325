import { useHistory } from 'react-router-dom'
import { useCallback } from 'react'

export function useGoBack() {
  const history = useHistory()

  return useCallback(
    (defaultTo = '/') => {
      if (history.length) {
        history.goBack()
        return
      }
      history.push(defaultTo)
    },
    [history],
  )
}
