import React from 'react'
import { Member, MemberOrderBy } from '@/api/members'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import { Box, styled } from '@mui/system'
import { formatter } from '@/utils/format'
import { Loader } from '../Loader'
import { labelDisplayedRows } from '@/utils/inputs'
import { LinkToEntity } from '@/components/shared/LinkToEntity'
import { EmptyResult } from '@/components/shared/EmptyResult'
import { renderMembershipAddress } from '@/utils/renderMembershipAddress'
import { Country } from '@/api/countries'
import { TablePaginationActions } from '@/components/shared/TablePaginationActions'
import { OrderingTableButton } from '@/components/shared/OrderingTableButton'
import { clipText } from '@/utils/clipText'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

interface Props {
  members: Member[]
  countries?: Country[]
  isLoading: boolean
  count: number
  hasNext: boolean
  hasPrevious: boolean
  page: number
  ordering: MemberOrderBy
  onChangeOrdering: (ordering: MemberOrderBy) => void
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number,
  ) => void
}

export const MembersTable: React.FC<Props> = ({
  members,
  isLoading,
  count,
  hasNext,
  hasPrevious,
  page,
  onPageChange,
  countries,
  onChangeOrdering,
  ordering,
}) => {
  if (isLoading || countries === undefined) {
    return <Loader />
  }

  const renderPagination = () => (
    <TablePagination
      component="div"
      count={count}
      rowsPerPage={100}
      rowsPerPageOptions={[100]}
      labelDisplayedRows={labelDisplayedRows}
      page={page}
      onPageChange={onPageChange}
      backIconButtonProps={{
        disabled: !hasPrevious,
      }}
      nextIconButtonProps={{
        disabled: !hasNext,
      }}
      ActionsComponent={TablePaginationActions}
    />
  )

  return (
    <Box sx={{ mt: 5 }}>
      {members.length ? (
        <>
          {renderPagination()}
          <TableContainer
            sx={{
              minWidth: '100%',
              width: 'min-content',
            }}
            component={Paper}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow sx={{ whiteSpace: 'nowrap' }}>
                  <TableCell>
                    <OrderingTableButton
                      onClick={onChangeOrdering}
                      current={ordering}
                      asc="id"
                    >
                      ID
                    </OrderingTableButton>
                  </TableCell>
                  <TableCell>
                    <OrderingTableButton
                      onClick={onChangeOrdering}
                      current={ordering}
                      asc="first_name,last_name"
                      desc="-first_name,-last_name"
                    >
                      Name
                    </OrderingTableButton>
                  </TableCell>
                  <TableCell>
                    <OrderingTableButton
                      onClick={onChangeOrdering}
                      current={ordering}
                      asc="company"
                    >
                      Company
                    </OrderingTableButton>
                  </TableCell>
                  <TableCell>
                    <OrderingTableButton
                      onClick={onChangeOrdering}
                      current={ordering}
                      asc="email"
                    >
                      Email
                    </OrderingTableButton>
                  </TableCell>
                  <TableCell>
                    <OrderingTableButton
                      onClick={onChangeOrdering}
                      current={ordering}
                      asc="young_collector"
                    >
                      S-C
                    </OrderingTableButton>
                  </TableCell>
                  <TableCell>
                    <OrderingTableButton
                      onClick={onChangeOrdering}
                      current={ordering}
                      asc="yc_age"
                    >
                      S-C Age
                    </OrderingTableButton>
                  </TableCell>
                  <TableCell>
                    <OrderingTableButton
                      onClick={onChangeOrdering}
                      current={ordering}
                      asc="interests"
                    >
                      Interests
                    </OrderingTableButton>
                  </TableCell>
                  <TableCell>
                    <OrderingTableButton
                      onClick={onChangeOrdering}
                      current={ordering}
                      asc="source"
                    >
                      Source
                    </OrderingTableButton>
                  </TableCell>
                  <TableCell>
                    <OrderingTableButton
                      onClick={onChangeOrdering}
                      current={ordering}
                      asc="membership"
                    >
                      Mbrship ID
                    </OrderingTableButton>
                  </TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>
                    <OrderingTableButton
                      onClick={onChangeOrdering}
                      current={ordering}
                      asc="date_created"
                    >
                      Date Added
                    </OrderingTableButton>
                  </TableCell>
                  <TableCell>
                    <OrderingTableButton
                      onClick={onChangeOrdering}
                      current={ordering}
                      asc="date_updated"
                    >
                      Last Edited
                    </OrderingTableButton>
                  </TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {members.map((member) => (
                  <StyledTableRow key={member.id}>
                    <TableCell scope="row">{member.id}</TableCell>
                    <TableCell>
                      {clipText(`${member.first_name} ${member.last_name}`)}
                    </TableCell>
                    <TableCell>{clipText(member.company)}</TableCell>
                    <TableCell>{clipText(member.email)}</TableCell>
                    <TableCell>{member.young_collector ? 'yes' : ''}</TableCell>
                    <TableCell>{member.yc_age ? member.yc_age : ''}</TableCell>
                    <TableCell>{clipText(member.interests)}</TableCell>
                    <TableCell>{clipText(member.source)}</TableCell>
                    <TableCell>
                      {member.membership && (
                        <LinkToEntity
                          entity="membership"
                          operation="read"
                          params={{ id: member.membership }}
                        >
                          {member.membership}
                        </LinkToEntity>
                      )}
                    </TableCell>
                    <TableCell>
                      {member.membership_data && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: renderMembershipAddress({
                              membership: member.membership_data,
                              countries,
                              clip: true,
                            }),
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>
                      {formatter.date(member.date_created, 'short')}
                    </TableCell>
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>
                      {formatter.date(member.date_updated, 'short')}
                    </TableCell>

                    <TableCell sx={{ whiteSpace: 'nowrap' }}>
                      <LinkToEntity
                        entity="member"
                        operation="read"
                        params={{ id: member.id }}
                        style={{ marginRight: '10px' }}
                      >
                        View
                      </LinkToEntity>
                      <LinkToEntity
                        entity="member"
                        operation="update"
                        params={{ id: member.id }}
                      >
                        Edit
                      </LinkToEntity>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {renderPagination()}
        </>
      ) : (
        <EmptyResult />
      )}
    </Box>
  )
}
