import { http } from '@/services/api/http'
import { User } from '@/services/store/types'

/* eslint-disable */
export interface LoginPayload {
  email: string
  password: string
}

export interface LoginConfirmResponse {
  token: string
}

export interface LoginConfirmPayload {
  code: string
  email: string
}

export type SetNewPasswordPayload = {
  auth: string
  token: string
  new_password: string
}

export type ChangePasswordPayload = {
  new_password: string
  old_password: string
}

export type UpdateProfilePayload = {
  first_name: string
  last_name: string
}

export type ResetPasswordPayload = {
  email: string
}

/* eslint-enable */

const login = async (payload: LoginPayload) =>
  http.post('/auth/signin/', payload)

const loginConfirm = async (payload: LoginConfirmPayload) => {
  const response = await http.post<LoginConfirmResponse>(
    '/auth/signin/confirm/',
    payload,
  )

  return response.data
}

const fetchUser = async () => {
  const response = await http.get<User>('/users/me/')
  return response.data
}

const changePassword = async (payload: ChangePasswordPayload) => {
  const response = await http.put('/users/change-password/', payload)
  return response.data
}

const updateProfile = async (payload: UpdateProfilePayload) => {
  const response = await http.put('/users/me/', payload)

  return response.data
}

const resetPassword = async (payload: ResetPasswordPayload) => {
  const response = await http.post('/auth/reset-password/', payload)
  return response.data
}

const setNewPassword = async (payload: SetNewPasswordPayload) => {
  const response = await http.post('/auth/new-password/', payload)

  return response.data
}

export const AuthAPI = {
  login,
  loginConfirm,
  fetchUser,
  updateProfile,
  changePassword,
  resetPassword,
  setNewPassword,
}
