/**
 * @description The PageTitle component.
 */
import React from 'react'
import { TypographyProps, Typography } from '@mui/material'

type Props = TypographyProps<'h1'>

export const PageTitle: React.FC<Props> = function (props) {
  return (
    <Typography
      component="h1"
      variant="h6"
      sx={{
        textTransform: 'uppercase',
        fontFamily: 'Roboto Slab',
        color: '#717070',
      }}
      {...props}
    />
  )
}
