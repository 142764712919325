import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { DesktopDatePicker } from '@/components/shared/DesktopDatePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { useQuery } from 'react-query'
import { countriesAPI, GetAllCountriesResponse } from '@/api/countries'
import { MembershipStatus } from '@/api/memberships'
import { renderMembershipStatus } from '@/utils/renderMembershipStatus'

/* eslint-disable */

const fields = {
  street: '',
  state: '',
  country: '',
  city: '',
  status: MembershipStatus.Active,
  member_first_name: '',
  member_last_name: '',
  members_count: null,
  directory_email: '',
  lifetime: null,
  date_created_from: null,
  date_created_to: null,
  address_private: null,
  zip: '',
  expiration_date_from: null,
  expiration_date_to: null,
  digital: null,
  digital_effective_date_from: null,
  digital_effective_date_to: null,
}

/* eslint-enable */

interface Props {
  onSubmit: (filters: Record<string, unknown>) => void
  onReset: () => void
}

export const MembershipsFilters: React.FC<Props> = ({ onSubmit, onReset }) => {
  const { data } = useQuery<GetAllCountriesResponse, Error>(
    'countries',
    countriesAPI.getAll,
  )

  const countries = data || []

  const { register, handleSubmit, control, reset } = useForm({
    defaultValues: fields,
  })

  return (
    <Box sx={{ background: '#f9f9f9' }} py={6} px={3}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Grid container spacing={2}>
            <Grid item sx={{ width: '200px', minWidth: '200px' }}>
              <TextField
                {...register('member_first_name')}
                label="First Name"
                variant="outlined"
                type="text"
                size="small"
                fullWidth
              />
            </Grid>

            <Grid item sx={{ width: '200px', minWidth: '200px' }}>
              <TextField
                {...register('member_last_name')}
                label="Last Name"
                variant="outlined"
                type="text"
                size="small"
                fullWidth
              />
            </Grid>

            <Grid item sx={{ width: '150px', minWidth: '150px' }}>
              <TextField
                {...register('directory_email')}
                label="Directory Email"
                variant="outlined"
                type="text"
                size="small"
                fullWidth
              />
            </Grid>

            <Grid item sx={{ width: '200px', minWidth: '200px' }}>
              <FormControl fullWidth size="small">
                <InputLabel id="membership_status-label">
                  Membership Status
                </InputLabel>

                <Controller
                  control={control}
                  name="status"
                  render={({ field: { onChange, ...props } }) => (
                    <Select
                      labelId="membership_status-label"
                      label="Membership Status"
                      onChange={onChange}
                      size="small"
                      inputRef={props.ref}
                      onBlur={props.onBlur}
                      name={props.name}
                      value={props.value}
                    >
                      <MenuItem value="">- select -</MenuItem>
                      <MenuItem value={MembershipStatus.Active}>
                        {renderMembershipStatus(MembershipStatus.Active)}
                      </MenuItem>
                      <MenuItem value={MembershipStatus.Inactive}>
                        {renderMembershipStatus(MembershipStatus.Inactive)}
                      </MenuItem>
                      <MenuItem value={MembershipStatus.Deceased}>
                        {renderMembershipStatus(MembershipStatus.Deceased)}
                      </MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item sx={{ width: '100px', minWidth: '100px' }}>
              <TextField
                {...register('members_count')}
                label="Mbrs #"
                variant="outlined"
                type="number"
                size="small"
                fullWidth
              />
            </Grid>

            <Grid item>
              <Controller
                control={control}
                name="lifetime"
                render={({ field: { value, onChange, ...field } }) => (
                  <FormControlLabel
                    sx={{ whiteSpace: 'nowrap' }}
                    control={
                      <Checkbox
                        onChange={(_, checked) => onChange(checked ? 1 : false)}
                        {...field}
                        checked={!!value}
                      />
                    }
                    label="Lifetime Membership"
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item sx={{ width: '200px', minWidth: '200px' }}>
              <TextField
                {...register('street')}
                label="Street"
                variant="outlined"
                type="text"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item sx={{ width: '200px', minWidth: '200px' }}>
              <TextField
                {...register('city')}
                label="City"
                variant="outlined"
                type="text"
                size="small"
                fullWidth
              />
            </Grid>

            <Grid item sx={{ width: '150px', minWidth: '150px' }}>
              <TextField
                {...register('zip')}
                label="Postal Code"
                variant="outlined"
                type="text"
                size="small"
                fullWidth
              />
            </Grid>

            <Grid item sx={{ width: '200px', minWidth: '200px' }}>
              <FormControl fullWidth size="small">
                <InputLabel id="membership_country-label">Country</InputLabel>

                <Controller
                  control={control}
                  name="country"
                  defaultValue=""
                  render={({ field: { onChange, ...props } }) => (
                    <Select
                      labelId="membership_country-label"
                      label="Country"
                      onChange={onChange}
                      {...props}
                      size="small"
                    >
                      <MenuItem value="">- select -</MenuItem>
                      {countries.map((country) => (
                        <MenuItem value={country.name} key={country.name}>
                          {country.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item>
              <Controller
                control={control}
                name="address_private"
                render={({ field: { value, ...field } }) => (
                  <FormControlLabel
                    sx={{ whiteSpace: 'nowrap' }}
                    control={<Checkbox {...field} checked={!!value} />}
                    label="Address Private"
                  />
                )}
              />
            </Grid>
          </Grid>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container spacing={2} sx={{ mt: 1, flexWrap: 'nowrap' }}>
              <Grid item sx={{ width: '100%' }}>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name="date_created_from"
                    defaultValue={null}
                    render={({ field: { onChange, value } }) => (
                      <DesktopDatePicker
                        label="Date Created From"
                        inputFormat="MM/dd/yyyy"
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item sx={{ width: '100%' }}>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name="date_created_to"
                    defaultValue={null}
                    render={({ field: { onChange, value } }) => (
                      <DesktopDatePicker
                        label="To"
                        inputFormat="MM/dd/yyyy"
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item sx={{ width: '100%' }}>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name="expiration_date_from"
                    defaultValue={null}
                    render={({ field: { onChange, value } }) => (
                      <DesktopDatePicker
                        label="Expiration Date From"
                        inputFormat="MM/dd/yyyy"
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item sx={{ width: '100%' }}>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name="expiration_date_to"
                    defaultValue={null}
                    render={({ field: { onChange, value } }) => (
                      <DesktopDatePicker
                        label="To"
                        inputFormat="MM/dd/yyyy"
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mt: 1, flexWrap: 'nowrap' }}>
              <Grid item sx={{ width: '100%' }}>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name="digital_effective_date_from"
                    defaultValue={null}
                    render={({ field: { onChange, value } }) => (
                      <DesktopDatePicker
                        label="Digital Effective Date From"
                        inputFormat="MM/dd/yyyy"
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item sx={{ width: '100%' }}>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name="digital_effective_date_to"
                    defaultValue={null}
                    render={({ field: { onChange, value } }) => (
                      <DesktopDatePicker
                        label="To"
                        inputFormat="MM/dd/yyyy"
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <Controller
                  control={control}
                  name="digital"
                  render={({ field: { value, onChange, ...field } }) => (
                    <FormControlLabel
                      sx={{ whiteSpace: 'nowrap' }}
                      control={
                        <Checkbox
                          onChange={(_, checked) =>
                            onChange(checked ? 1 : false)
                          }
                          {...field}
                          checked={!!value}
                        />
                      }
                      label="Digital Membership"
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Button
                  sx={{ whiteSpace: 'nowrap', height: '40px' }}
                  variant="outlined"
                  type="submit"
                >
                  Apply filter
                </Button>
              </Grid>
              <Grid item>
                <Button
                  sx={{ whiteSpace: 'nowrap', height: '40px' }}
                  variant="outlined"
                  onClick={() => {
                    reset(fields)
                    onReset()
                  }}
                >
                  Reset filter
                </Button>
              </Grid>
            </Grid>
          </LocalizationProvider>
        </div>
      </form>
    </Box>
  )
}
