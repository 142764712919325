import { AuthAPI, LoginConfirmPayload } from '@/api/auth'
import { loginConfirm } from '@/services/store/actions/auth'
import { Button, Grid, TextField, Box } from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from 'react-query'

const scheme = Yup.object().shape({
  code: Yup.string().required('This field is required').length(6),
})

interface Props {
  email: string
}

export const ConfirmForm: React.FC<Props> = ({ email }) => {
  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(scheme),
  })

  const mutation = useMutation(
    async (vars: { data: LoginConfirmPayload }) => {
      const data = await AuthAPI.loginConfirm({
        ...vars.data,
        email,
      })
      return data
    },
    {
      onSuccess: (data) => {
        dispatch(loginConfirm({ token: data.token }))
      },
    },
  )

  const onSubmit = (form: LoginConfirmPayload) => {
    mutation.mutate({ data: form })
  }

  return (
    <Grid item xs={8}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          {...register('code')}
          fullWidth
          label="Code"
          variant="standard"
          type="text"
          helperText={errors.code?.message}
          error={!!errors.code?.message}
        />
        <Button type="submit" variant="contained" sx={{ mt: 5 }}>
          Verify Code
        </Button>
        {mutation.error && (
          <Box sx={{ mt: 2, color: 'error.main' }}>
            Incorrect 2FA code. Try re-entering the code.
          </Box>
        )}
      </form>
    </Grid>
  )
}
