import React from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box } from '@mui/system'
import { PageTitle } from '@/components/shared/PageTitle'
import { BackButton } from '@/components/shared/BackButton'

interface Props {
  title: string
  to: string
}

export const TitleWithBackButton: React.FC<Props> = ({ title, to = '/' }) => (
  <Box sx={{ mt: 6 }}>
    <PageTitle>{title}</PageTitle>
    <Box sx={{ mt: 4 }}>
      <BackButton defaultTo={to} startIcon={<ArrowBackIcon />}>
        Back
      </BackButton>
    </Box>
  </Box>
)
