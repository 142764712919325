import React from 'react'
import { Button, Container } from '@mui/material'
import Logo from '@/assets/logo.svg'
import styled from 'styled-components'
import { NavLink, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '@/services/store/actions/auth'
import { useUser } from '@/hooks/useUser'

const StyledHeader = styled.div`
  background: #000;
  padding: 16px 0;

  .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .nav {
    display: flex;
    align-items: center;

    .nav-item {
      color: #fff;
      font-size: 20px;
      line-height: 28px;
      text-transform: uppercase;
      text-decoration: none;
      margin: 0 15px;
      position: relative;
      font-family: 'Lateef', cursive;
    }

    .nav-item.active {
      &::before {
        content: '';
        width: 100%;
        height: 2px;
        bottom: -2px;
        left: 0;
        background: #fff;
        position: absolute;
      }
    }
  }
`

export const Header: React.FC = () => {
  const dispatch = useDispatch()
  const { isAuthenticated } = useSelector((state: any) => state.auth)
  const user = useUser()
  const isAdmin =
    user?.roles.find((role) => role === 'administrator') !== undefined

  const handleLogout = () => {
    dispatch(logout())
  }

  return (
    <StyledHeader>
      <Container maxWidth="xl">
        <div className="inner">
          {isAuthenticated ? (
            <img src={Logo} alt="IPBA" />
          ) : (
            <Link to="/login">
              <img src={Logo} alt="IPBA" />
            </Link>
          )}
          {isAuthenticated && (
            <nav className="nav">
              {isAdmin && (
                <>
                  <NavLink
                    to="/members"
                    className="nav-item"
                    activeClassName="active"
                  >
                    members
                  </NavLink>
                  <NavLink
                    to="/memberships"
                    className="nav-item"
                    activeClassName="active"
                  >
                    memberships
                  </NavLink>
                  <NavLink
                    to="/payments"
                    className="nav-item"
                    activeClassName="active"
                  >
                    payments
                  </NavLink>
                  <NavLink
                    to="/countries"
                    className="nav-item"
                    activeClassName="active"
                  >
                    countries
                  </NavLink>
                  <NavLink
                    to="/payments-methods"
                    className="nav-item"
                    activeClassName="active"
                  >
                    payments methods
                  </NavLink>
                </>
              )}
              <NavLink
                to="/reports"
                className="nav-item"
                activeClassName="active"
              >
                reports
              </NavLink>
              {isAdmin && (
                <NavLink
                  to="/wordpress"
                  className="nav-item"
                  activeClassName="active"
                >
                  wordpress
                </NavLink>
              )}
              <NavLink
                to="/account"
                className="nav-item"
                activeClassName="active"
              >
                my account
              </NavLink>
              <Button
                className="nav-item"
                variant="text"
                color="inherit"
                onClick={handleLogout}
              >
                logout
              </Button>
            </nav>
          )}
        </div>
      </Container>
    </StyledHeader>
  )
}
