export const EVENT_NAME = 'ipba_logout'

export function dispatchEvent() {
  window.dispatchEvent(new CustomEvent(EVENT_NAME))
}

export function addEventListener(cb: () => any) {
  window.addEventListener(EVENT_NAME, cb)
}

export function removeEventListener(cb: () => any) {
  window.removeEventListener(EVENT_NAME, cb)
}
