import React, { useMemo } from 'react'
import { Membership, MembershipStatus } from '@/api/memberships'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { formatter } from '@/utils/format'
import { LinkToEntity } from '@/components/shared/LinkToEntity'
import { renderMembershipStatus } from '@/utils/renderMembershipStatus'
import { Button } from '@mui/material'
import { renderMembershipWordpressRole } from '@/utils/renderMembershipWordpressRole'
import { renderMembershipMembersNames } from '@/utils/renderMembershipMembersNames'
import { Country } from '@/api/countries'

interface Props {
  membership?: Membership | null
  isPaymentView?: boolean
  countries?: Country[]
}

export const StyledMembershipPreview = styled.article`
  margin: 30px 0 0;

  .row {
    margin: 10px 0 0;
    display: flex;

    .label {
      min-width: 200px;
      color: #717070;
      margin: 0 20px 0 0;
      text-align: right;
    }

    .link {
      color: #8b6239 !important;
    }

    .text {
      color: #000;
    }
  }
`

export const MembershipPreview: React.FC<Props> = ({
  membership,
  isPaymentView,
  countries,
}) => {
  const country = useMemo(() => {
    if (!countries) {
      return null
    }
    return countries.find((c) => c.id === membership?.country)
  }, [countries, membership])

  return membership ? (
    <StyledMembershipPreview>
      <div className="row">
        <span className="label"> Membership ID </span>
        <LinkToEntity
          entity="membership"
          operation="read"
          params={{ id: membership.id }}
        >
          {membership.id}
        </LinkToEntity>
      </div>
      <div className="row">
        <span className="label"> Status </span>
        <span className="text">
          {renderMembershipStatus(membership.status)}
        </span>
      </div>
      <div className="row">
        <span className="label"> Members </span>
        <span className="text">
          {renderMembershipMembersNames(membership.members)}
        </span>
      </div>
      {!isPaymentView && (
        <>
          <div className="row">
            <span className="label"> WordPress role </span>
            <span className="text">
              {renderMembershipWordpressRole(membership.status)}
            </span>
          </div>
          <div className="row">
            <span className="label"> Expiration Date </span>
            <span className="text">
              {formatter.date(membership.expiration)}
            </span>
          </div>
          <div className="row">
            <span className="label"> Date Created </span>
            <span className="text">
              {formatter.date(membership.date_created)}
            </span>
          </div>
          <div className="row">
            <span className="label"> Last Edited </span>
            <span className="text">
              {formatter.date(membership.date_updated)}
            </span>
          </div>
          <div className="row">
            <span className="label"> Address </span>
            <span className="text">
              {[
                membership.street,
                membership.city,
                membership.state,
                country?.name,
                membership.zip,
              ]
                .filter((s) => !!s)
                .join(', ')}
            </span>
          </div>
          <div className="row">
            <span className="label"> Address Status </span>
            <span className="text">
              {membership.address_private ? 'Private' : 'Public'}
            </span>
          </div>

          <div className="row">
            <span className="label"> Directory Email </span>
            <a
              className="text link"
              href={`mailto:${membership.directory_email}`}
            >
              {membership.directory_email}
            </a>
          </div>
          <div className="row">
            <span className="label"> Lifetime Membership </span>
            <span className="text">{membership.lifetime ? 'Yes' : 'No'}</span>
          </div>
          <div className="row">
            <span className="label"> Digital Membership </span>
            <span className="text">{membership.digital ? 'Yes' : 'No'}</span>
          </div>
          {membership.digital && (
            <div className="row">
              <span className="label"> Digital Effective Date </span>
              <span className="text">
                {membership.digital_effective_date
                  ? formatter.date(membership.digital_effective_date)
                  : ''}
              </span>
            </div>
          )}
          <div className="row">
            <span className="label"> Historical Notes </span>
            <span className="text">{membership.historical_notes}</span>
          </div>
          <div className="row">
            <span className="label" />
            <Button
              component={LinkToEntity}
              variant="contained"
              entity="membership"
              operation="update"
              params={{ id: membership.id }}
              dontStyle={true}
            >
              Edit Membership
            </Button>
          </div>
        </>
      )}
    </StyledMembershipPreview>
  ) : null
}
