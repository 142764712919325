import React from 'react'
import { Container } from '@mui/material'
import { useMutation } from 'react-query'
import { formatForm } from '@/utils/format-form'
import { toast } from 'react-toastify'
import { TitleWithBackButton } from '@/components/layout/TitleWIthBackButton'
import { CountriesForm } from '@/components/countries'
import { countriesAPI } from '@/api/countries'
import { useHistory } from 'react-router'

export const CountriesCreate = () => {
  const history = useHistory()

  const { isLoading, mutate } = useMutation((form: any) =>
    countriesAPI.create(form),
  )

  const handleSubmit = (form: any) => {
    mutate(formatForm(form), {
      onSuccess: (data) => {
        toast.success('Country created')
        if (data?.id) {
          history.push(`/countries/edit/${data.id}`)
        }
      },
      onError: () => {
        toast.error('Something went wrong. Please try again.')
      },
    })
  }

  return (
    <Container maxWidth="xl">
      <TitleWithBackButton title="New Country Details" to="/countries" />
      <CountriesForm onSubmit={handleSubmit} isDisabled={isLoading} />
    </Container>
  )
}
