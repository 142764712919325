/**
 * @description The MembershipAutocomplete component.
 */
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { RefCallBack } from 'react-hook-form'
import { Autocomplete, TextField, CircularProgress } from '@mui/material'
import { Membership, membershipsAPI } from '@/api/memberships'
import { useQuery } from 'react-query'
import { Maybe } from '@/types'
import { useCountries } from '@/hooks/useCountries'
import { Country } from '@/api/countries'

type Props = {
  label: string
  error?: string
  onChange: (id: Maybe<number>) => any
  onBlur: () => any
  inputRef: RefCallBack
  value: Maybe<number>
}

const formatOption = (value: Membership, countries?: Country[]) =>
  [
    value.members
      .map((member) =>
        [member.first_name, member.last_name].filter((s) => !!s).join(' '),
      )
      .join(' & '),
    value.street,
    value.city,
    countries?.find((c) => c.id === value.country)?.name,
    value.zip,
    `#${value.id}`,
    value.directory_email,
  ]
    .filter((v) => !!v)
    .join(', ')

export const MembershipAutocomplete: React.FC<Props> = function (props) {
  const countries = useCountries()
  const formatMembershipOption = useCallback(
    (v: Membership) => formatOption(v, countries.data),
    [countries.data],
  )

  const { label, error, onChange, onBlur, inputRef, value } = props

  const [text, setText] = useState('')
  const [textToSearch, setTextToSearch] = useState('')
  const [options, setOptions] = useState<Membership[]>([])
  const valueOption = options.find((opt) => opt.id === value)
  const presetCountRef = useRef(0)

  useEffect(() => {
    if (valueOption && text === formatMembershipOption(valueOption)) {
      return
    }

    const t = setTimeout(() => {
      setTextToSearch(text)
    }, 200)

    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(t)
    }
  }, [formatMembershipOption, text, valueOption])

  const listQuery = useQuery(
    ['membershipAutocomplete', textToSearch],
    async () => {
      const res = await membershipsAPI.getForAutocomplete(textToSearch)
      return res
    },
  )
  const singleQuery = useQuery(
    ['membershipAutocompleteSingle', value],
    async () => {
      const res = await membershipsAPI.getOne(value as number)
      return res
    },
    {
      enabled: !!value,
    },
  )

  const loading =
    listQuery.isLoading || singleQuery.isLoading || countries.isLoading

  useEffect(() => {
    setOptions(
      [
        ...(singleQuery.data ? [singleQuery.data] : []),
        ...(listQuery.data || []),
      ].filter(
        (item, i, arr) => arr.findIndex((el) => el.id === item.id) === i,
      ),
    )

    if (listQuery.data && singleQuery.data && presetCountRef.current < 1) {
      setText(formatMembershipOption(singleQuery.data))
      presetCountRef.current += 1
    }
  }, [formatMembershipOption, listQuery.data, singleQuery.data])

  return (
    <Autocomplete
      onBlur={onBlur}
      ref={inputRef}
      value={valueOption}
      options={options}
      loading={listQuery.isLoading}
      onChange={(_, option) => onChange(option ? option.id : null)}
      onInputChange={(_, v, reason) => {
        if (reason === 'reset' && v === '') {
          return
        }
        setText(v)
      }}
      inputValue={text}
      isOptionEqualToValue={(option, v) => option.id === v.id}
      getOptionLabel={formatMembershipOption}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          size="small"
          variant="standard"
          autoComplete="off"
          helperText={error}
          error={!!error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={15} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}
