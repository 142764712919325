import React from 'react'

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { MembershipDirectoryReport } from '@/api/reports'
import { styled } from '@mui/system'
import { Loader } from '@/components/Loader'
import { EmptyResult } from '@/components/shared/EmptyResult'
import { clipText } from '@/utils/clipText'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

interface Props {
  reports: MembershipDirectoryReport[]
  isLoading: boolean
}

export const MembershipDirectoryTable: React.FC<Props> = ({
  reports,
  isLoading,
}) => {
  if (isLoading) {
    return <Loader />
  }

  return reports.length ? (
    <TableContainer
      sx={{
        minWidth: '100%',
        width: 'min-content',
      }}
      component={Paper}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Membership ID</TableCell>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>Company</TableCell>
            <TableCell>Street</TableCell>
            <TableCell>City</TableCell>
            <TableCell>State</TableCell>
            <TableCell>ZIP Code</TableCell>
            <TableCell>Country</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Email Addresses</TableCell>
            <TableCell>Interests</TableCell>
            <TableCell>Digital</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reports.map((report) => (
            <StyledTableRow
              key={report.first_name}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
              }}
            >
              <TableCell>{report.membership_id}</TableCell>
              <TableCell>{clipText(report.first_name)}</TableCell>
              <TableCell>{clipText(report.last_name)}</TableCell>
              <TableCell>{clipText(report.company)}</TableCell>
              <TableCell>{clipText(report.street)}</TableCell>
              <TableCell>{clipText(report.city)}</TableCell>
              <TableCell>{clipText(report.state)}</TableCell>
              <TableCell component="th" scope="row">
                {report.ZIP}
              </TableCell>
              <TableCell>{clipText(report.country)}</TableCell>

              <TableCell component="th" scope="row">
                {clipText(report.phone)}
              </TableCell>

              <TableCell component="th" scope="row">
                {clipText(report.directory_email)}
              </TableCell>
              <TableCell component="th" scope="row">
                {clipText(report.interests)}
              </TableCell>
              <TableCell component="th" scope="row">
                {clipText(report.digital)}
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <EmptyResult />
  )
}
