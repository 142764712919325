import { AxiosError } from 'axios'

export function getErrorMessage(error: unknown): string {
  const err = error as AxiosError
  const errorText = 'Something went wrong'
  if (err.response?.data) {
    if (typeof err.response?.data === 'object') {
      const resData: Record<string, unknown> = err.response?.data
      const [firstError] = Object.values(resData)
      if (!firstError) {
        return errorText
      }
      if (typeof firstError === 'string') {
        return firstError
      }
      if (firstError instanceof Array && typeof firstError[0] === 'string') {
        return firstError[0]
      }
    }
  }
  return errorText
}
