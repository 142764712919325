import React, { useEffect } from 'react'
import { membersAPI } from '@/api/members'
import { MembersForm } from '@/components/members'
import { formatForm } from '@/utils/format-form'
import { Container } from '@mui/material'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams, useHistory } from 'react-router'
import { toast } from 'react-toastify'
import { TitleWithBackButton } from '@/components/layout/TitleWIthBackButton'
import { Loader } from '@/components/Loader'
import { getErrorMessage } from '@/api/getErrorMessage'

export const MembersEdit = () => {
  const { id } = useParams<{ id: string }>()
  const queryClient = useQueryClient()

  const {
    data: member,
    isLoading: isMemberLoading,
    isError,
  } = useQuery(['member', id], () => membersAPI.getOne(id))
  const history = useHistory()
  useEffect(() => {
    if (isError) {
      history.push('/members')
    }
  }, [history, isError])

  const { isLoading, mutate: updateMutation } = useMutation(
    ({ memberId, form }: { memberId: string; form: any }) =>
      membersAPI.update(memberId, form),
  )

  const { mutate: deleteMutation } = useMutation((memberId: string) =>
    membersAPI.deleteOne(memberId),
  )

  const handleSubmit = (form: any) => {
    updateMutation(
      { memberId: id, form: formatForm(form) },
      {
        onSuccess: () => {
          toast.success('Member successfully updated.')
          queryClient.invalidateQueries('members')
          queryClient.invalidateQueries(['member', id])
          // goBack('/members')
        },
        onError: (error) => {
          const message = getErrorMessage(error)
          toast.error(`Member isn't updated. ${message}`)
        },
      },
    )
  }

  const handleDelete = () => {
    deleteMutation(id, {
      onSuccess: () => {
        toast.success('Member successfully deleted.')
        queryClient.invalidateQueries('members')
        history.push('/members')
      },
    })
  }

  return (
    <Container maxWidth="xl">
      <TitleWithBackButton title="Edit Member Details" to="/members" />
      {isMemberLoading ? (
        <Loader />
      ) : (
        <MembersForm
          onSubmit={handleSubmit}
          onDelete={handleDelete}
          isDisabled={isLoading}
          values={member}
          mode="edit"
        />
      )}
    </Container>
  )
}
