import React, { useState } from 'react'
import { Container, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { ConfirmForm, LoginForm } from '@/components/login'
import { useUser } from '@/hooks/useUser'
import { Redirect } from 'react-router-dom'
import { useSearchParams } from '@/hooks/useSearchParams'

export const Login = () => {
  const [email, setEmail] = useState('')
  const [isLoginSuccess, setIsLoginSuccess] = useState(false)
  const user = useUser()
  const search = useSearchParams()

  if (user) {
    return <Redirect to="/" />
  }

  const handleSuccessLogin = (payload: string) => {
    setEmail(payload)
    setIsLoginSuccess(true)
  }

  // eslint-disable-next-line no-nested-ternary
  const text = isLoginSuccess
    ? 'We sent a verification code to your email. Enter the code from the email in the field below.'
    : search.get('afterReset')
    ? 'We have emailed you a link to reset your password.'
    : 'Please enter your login credentials'

  return (
    <Container maxWidth="md">
      <Box sx={{ display: 'flex', alignItems: 'center' }} mt={8}>
        <Box sx={{ width: '100%' }}>
          <Typography
            variant="h3"
            gutterBottom
            fontFamily="Lateef"
            fontSize="65px"
            color="primary"
          >
            {isLoginSuccess ? "Verify it's you" : 'IPBA Membership Database'}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {text}
          </Typography>
          <Box sx={{ mt: 3, maxWidth: '500px' }}>
            {isLoginSuccess ? (
              <ConfirmForm email={email} />
            ) : (
              <LoginForm onSuccess={handleSuccessLogin} />
            )}
          </Box>
        </Box>
      </Box>
    </Container>
  )
}
