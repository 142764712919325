import React, { useState } from 'react'
import { Container, Typography, Button } from '@mui/material'
import { Box } from '@mui/system'
import { GetMembersResponse, MemberOrderBy, membersAPI } from '@/api/members'
import { useQuery } from 'react-query'
import { MembersFilters, MembersTable } from '@/components/members'
import { formatFilters } from '@/utils/format-filters'
import { Link } from 'react-router-dom'
import { PageTitle } from '@/components/shared/PageTitle'
import { useCountries } from '@/hooks/useCountries'
import { MembershipStatus } from '@/api/memberships'

export const MembersList = () => {
  const [page, setPage] = useState(1)
  const [filters, setFilters] = useState<Record<string, any>>({
    membership_status: MembershipStatus.Active,
  })
  const [ordering, setOrdering] = useState<MemberOrderBy>('-id')
  const { data, isLoading, error } = useQuery<GetMembersResponse, Error>(
    ['members', page, filters, ordering],
    () => membersAPI.get(page, filters, ordering),
  )
  const countries = useCountries()

  const handlePageChange = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    current: number,
  ) => {
    setPage(current + 1)
  }

  const handleChangeFilters = (notFormatedFilters: Record<string, unknown>) => {
    setPage(1)
    setFilters(formatFilters(notFormatedFilters))
  }

  const handleExportMembers = () => {
    membersAPI.exportToFile({ ...filters }, ordering)
  }

  const handleResetFilters = () => setFilters({})

  if (error || countries.error) {
    return <p>Something went wrong. Please, try again later.</p>
  }

  return (
    <Container maxWidth="xl">
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', mt: 6, mb: 4 }}
      >
        <PageTitle gutterBottom>Members</PageTitle>
        <div>
          <Button
            component={Link}
            to="/members/create"
            variant="contained"
            sx={{ mr: 3 }}
          >
            + Add New
          </Button>
          <Button onClick={handleExportMembers} variant="contained">
            Export...
          </Button>
        </div>
      </Box>
      <MembersFilters
        onReset={handleResetFilters}
        onSubmit={handleChangeFilters}
      />
      <MembersTable
        members={data?.results || []}
        countries={countries.data}
        onPageChange={handlePageChange}
        page={page - 1}
        isLoading={isLoading || countries.isLoading}
        count={data?.count || 0}
        hasNext={!!data?.next}
        hasPrevious={!!data?.previous}
        ordering={ordering}
        onChangeOrdering={(o) => {
          setPage(1)
          setOrdering(o)
        }}
      />
    </Container>
  )
}
