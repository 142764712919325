import React, { useState } from 'react'
import { MemberContestReport, reportsAPI } from '@/api/reports'
import { Container } from '@mui/material'
import { useQuery } from 'react-query'
import { Box } from '@mui/system'
import { TitleWithBackButton } from '@/components/layout/TitleWIthBackButton'
import { DownloadButton } from '@/components/reports/shared/DownloadButton'
import { MemberContestTable } from '@/components/reports/tables/MemberContestTable'
import { MembersContestFilters } from '@/components/reports/filters/MembersContestFilters'
import { formatFilters } from '@/utils/format-filters'
import { reportsConfig } from '@/reportsConfig'

export const MemberContest = () => {
  const [filters, setFilters] = useState({})

  const { data: reports, isLoading } = useQuery<MemberContestReport[], Error>(
    ['member-contest-report', filters],
    () => reportsAPI.getMemberContestReport(filters),
    {
      cacheTime: 0,
    },
  )

  const handleChangeFilters = (notFormatedFilters: Record<string, unknown>) => {
    setFilters(formatFilters(notFormatedFilters))
  }

  const handleResetFilters = () => setFilters({})

  return (
    <Container maxWidth="xl">
      <TitleWithBackButton
        title={reportsConfig.memberContest.title}
        to="/reports"
      />

      <Box sx={{ my: 5 }}>
        <DownloadButton
          onClick={() => reportsAPI.downloadMemberContestReport(filters)}
        />
        <MembersContestFilters
          onSubmit={handleChangeFilters}
          onReset={handleResetFilters}
        />
        <MemberContestTable isLoading={isLoading} reports={reports || []} />
      </Box>
    </Container>
  )
}
