import React, { useEffect } from 'react'
import { membershipsAPI } from '@/api/memberships'
import { formatForm } from '@/utils/format-form'
import { Button, Container, Grid, Box } from '@mui/material'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory, useParams } from 'react-router'
import { toast } from 'react-toastify'
import { TitleWithBackButton } from '@/components/layout/TitleWIthBackButton'
import { Loader } from '@/components/Loader'
import { MembershipsForm } from '@/components/memberships'
import { MembershipShortPreview } from '@/components/memberships/MembershipShortPreview'
import { membersAPI } from '@/api/members'
import { Link } from 'react-router-dom'
import { getErrorMessage } from '@/api/getErrorMessage'

export const MembershipsEdit = () => {
  const { id } = useParams<{ id: string }>()
  const queryClient = useQueryClient()

  const {
    data: membership,
    isLoading: isMembershipLoading,
    isError,
  } = useQuery(['membership', id], () => membershipsAPI.getOne(+id))
  const history = useHistory()
  useEffect(() => {
    if (isError) {
      history.push('/memberships')
    }
  }, [history, isError])

  const { data: members, isLoading: isMembersLoading } = useQuery(
    ['membersForMembership', id],
    () =>
      membersAPI.getAll({
        membership: +id,
      }),
  )

  const { isLoading, mutate: updateMutation } = useMutation(
    ({ membershipId, form }: { membershipId: string; form: any }) =>
      membershipsAPI.update(membershipId, form),
  )

  const { mutate: deleteMutation } = useMutation((memberId: string) =>
    membershipsAPI.deleteOne(memberId),
  )

  const handleSubmit = (form: any) => {
    updateMutation(
      { membershipId: id, form: formatForm(form) },
      {
        onSuccess: () => {
          toast.success('Membership successfully updated.')
          queryClient.invalidateQueries(['membership', id])
          queryClient.invalidateQueries('memberships')
          // goBack('/memberships')
        },
        onError: (error) => {
          const message = getErrorMessage(error)
          toast.error(`Membership isn't updated. ${message}`)
        },
      },
    )
  }

  const handleDelete = () => {
    deleteMutation(id, {
      onSuccess: () => {
        toast.success('Membership successfully deleted.')
        queryClient.invalidateQueries('memberships')
        history.push('/memberships')
      },
    })
  }

  const loading = isMembershipLoading || isMembersLoading

  return (
    <Container maxWidth="xl">
      <TitleWithBackButton title="Edit Membership Details" to="/memberships" />
      {loading ? (
        <Loader />
      ) : (
        <div>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <MembershipShortPreview
                membership={membership}
                members={members}
              />
            </Grid>
            <Grid item xs={8}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                {`${membership?.status}` !== '2' && (
                  <Button
                    component={Link}
                    to={`/payments/create?membership=${id}`}
                    variant="contained"
                  >
                    + Add Payment
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
          <MembershipsForm
            onSubmit={handleSubmit}
            onDelete={handleDelete}
            isDisabled={isLoading}
            values={membership}
            mode="edit"
          />
        </div>
      )}
    </Container>
  )
}
