import { Grid, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'

export const NotFound = () => {
  const { isAuthenticated, loading } = useSelector((state: any) => state.auth)

  const ctn = (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sx={{
          textAlign: 'center',
          height: '80vh',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h5">
          <b>404</b>
          <br />
          Page not found
        </Typography>
      </Grid>
    </Grid>
  )

  if (loading) {
    return ctn
  }

  if (isAuthenticated) {
    return ctn
  }

  return <Redirect to="/login" />
}
