/**
 * @description The MemberAutocomplete component.
 */
import React, { useEffect, useRef, useState } from 'react'
import { RefCallBack } from 'react-hook-form'
import { Autocomplete, CircularProgress, TextField } from '@mui/material'
import { Member, membersAPI } from '@/api/members'
import { Maybe } from '@/types'
import { useQuery } from 'react-query'

type Props = {
  label: string
  error?: string
  onChange: (id: Maybe<number>) => any
  onBlur: () => any
  inputRef: RefCallBack
  value: Maybe<number>
}

const formatOption = (value: Member) =>
  [value.first_name, value.last_name]
    .filter((v) => !!v)
    .map((l) => l.trim())
    .join(' ')

export const MemberAutocomplete: React.FC<Props> = function (props) {
  const { label, error, onChange, onBlur, inputRef, value } = props

  const [text, setText] = useState('')
  const [textToSearch, setTextToSearch] = useState('')
  const [options, setOptions] = useState<Member[]>([])
  const valueOption = options.find((opt) => opt.id === value)
  const presetCountRef = useRef(0)

  useEffect(() => {
    if (valueOption && text === formatOption(valueOption)) {
      return
    }

    const t = setTimeout(() => {
      setTextToSearch(text)
    }, 200)

    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(t)
    }
  }, [text, valueOption])

  const listQuery = useQuery(['memberAutocomplete', textToSearch], async () => {
    const res = await membersAPI.getForAutocomplete(textToSearch)
    return res
  })
  const singleQuery = useQuery(
    ['memberAutocompleteSingle', value],
    async () => {
      const res = await membersAPI.getOne(`${value}`)
      return res
    },
    {
      enabled: !!value,
    },
  )

  const loading = listQuery.isLoading || singleQuery.isLoading

  useEffect(() => {
    setOptions(
      [
        ...(singleQuery.data ? [singleQuery.data] : []),
        ...(listQuery.data || []),
      ].filter(
        (item, i, arr) => arr.findIndex((el) => el.id === item.id) === i,
      ),
    )

    if (listQuery.data && singleQuery.data && presetCountRef.current < 1) {
      setText(formatOption(singleQuery.data))
      presetCountRef.current += 1
    }
  }, [listQuery.data, singleQuery.data])

  return (
    <Autocomplete
      onBlur={onBlur}
      ref={inputRef}
      value={valueOption}
      options={options}
      loading={listQuery.isLoading}
      onChange={(_, option) => onChange(option ? option.id : null)}
      onInputChange={(_, v, reason) => {
        if (reason === 'reset' && v === '') {
          return
        }
        setText(v)
      }}
      inputValue={text}
      isOptionEqualToValue={(option, v) => option.id === v.id}
      getOptionLabel={formatOption}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          size="small"
          variant="standard"
          autoComplete="off"
          helperText={error}
          error={!!error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={15} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}
