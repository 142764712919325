import React, { useState } from 'react'
import { MemberDonationsReport, reportsAPI } from '@/api/reports'
import { Container } from '@mui/material'
import { useQuery } from 'react-query'
import { Box } from '@mui/system'
import { TitleWithBackButton } from '@/components/layout/TitleWIthBackButton'
import { DownloadButton } from '@/components/reports/shared/DownloadButton'
import { formatFilters } from '@/utils/format-filters'
import { MembersDonationsFilters } from '@/components/reports/filters/MemberDonationsFilters'
import { MembersDonationsTable } from '@/components/reports/tables/MemberDonationsTable'
import { reportsConfig } from '@/reportsConfig'

export const MembersDonations = () => {
  const [filters, setFilters] = useState({})

  const { data: reports, isLoading } = useQuery<MemberDonationsReport[], Error>(
    ['member-donations-report', filters],
    () => reportsAPI.getMemberDonationsReport(filters),
    {
      cacheTime: 0,
    },
  )

  const handleChangeFilters = (notFormatedFilters: Record<string, unknown>) => {
    setFilters(formatFilters(notFormatedFilters))
  }

  const handleResetFilters = () => setFilters({})

  return (
    <Container maxWidth="xl">
      <TitleWithBackButton
        title={reportsConfig.donations.title}
        to="/reports"
      />

      <Box sx={{ my: 5 }}>
        <DownloadButton
          onClick={() => reportsAPI.downloadMemberDonationsReport(filters)}
        />
        <MembersDonationsFilters
          onSubmit={handleChangeFilters}
          onReset={handleResetFilters}
        />
        <MembersDonationsTable isLoading={isLoading} reports={reports || []} />
      </Box>
    </Container>
  )
}
