import { combineReducers } from 'redux'
import authReducer from './auth'
import { connectRouter } from 'connected-react-router'
import { History } from 'history'

const createRootReducer = (history: History<any>) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
  })

export default createRootReducer
