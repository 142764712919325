import React, { useEffect } from 'react'
import { formatForm } from '@/utils/format-form'
import { Container } from '@mui/material'
import { useMutation, useQuery } from 'react-query'
import { useParams, useHistory } from 'react-router'
import { toast } from 'react-toastify'
import { TitleWithBackButton } from '@/components/layout/TitleWIthBackButton'
import { Loader } from '@/components/Loader'
import { CountriesForm } from '@/components/countries'
import { countriesAPI } from '@/api/countries'
import { useGoBack } from '@/hooks/useGoBack'

export const CountriesEdit = () => {
  const goBack = useGoBack()
  const history = useHistory()
  const { id } = useParams<{ id: string }>()

  const {
    data: country,
    isLoading: isCountryLoading,
    isError,
  } = useQuery(['countries', id], () => countriesAPI.getOne(+id))

  useEffect(() => {
    if (isError) {
      history.push('/countries')
    }
  }, [history, isError])

  const { isLoading, mutate: updateMutation } = useMutation(
    ({ countryId, form }: { countryId: string; form: any }) =>
      countriesAPI.update(countryId, form),
  )

  const { mutate: deleteMutation } = useMutation((countryId: string) =>
    countriesAPI.deleteOne(countryId),
  )

  const handleSubmit = (form: any) => {
    updateMutation(
      { countryId: id, form: formatForm(form) },
      {
        onSuccess: () => {
          // goBack('/countries')
          toast.success('Country successfully updated.')
        },
      },
    )
  }

  const handleDelete = () => {
    deleteMutation(id, {
      onSuccess: () => {
        toast.success('Country successfully deleted.')
        history.push('/countries')
      },
    })
  }

  return (
    <Container maxWidth="xl">
      <TitleWithBackButton title="Edit Country Details" to="/countries" />
      {isCountryLoading ? (
        <Loader />
      ) : (
        <CountriesForm
          onSubmit={handleSubmit}
          onDelete={handleDelete}
          isDisabled={isLoading}
          values={country}
          mode="edit"
        />
      )}
    </Container>
  )
}
