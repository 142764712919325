import React, { useEffect, useState } from 'react'
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  TextField,
  Box,
  Typography,
  DialogActions,
  Dialog,
  DialogTitle,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { Controller, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { membershipsAPI } from '@/api/memberships'
import { MembershipPreview } from './MembershipPreview'
import { Member } from '@/api/members'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { BackButton } from '@/components/shared/BackButton'
import { useSearchParams } from '@/hooks/useSearchParams'
import { MembershipAutocomplete } from '@/components/shared/MembershipAutocomplete'
import { MemberAutocomplete } from '@/components/shared/MemberAutocomplete'
import { validationErrors } from '@/defaults'
import { useCountries } from '@/hooks/useCountries'

export type Mode = 'edit' | 'create'

interface Props {
  onSubmit: (form: any) => void
  isDisabled: boolean
  values?: Member
  mode?: Mode
  onDelete?: () => void
}

const scheme = Yup.object().shape({
  first_name: Yup.string()
    .required('This field is required')
    .max(50, validationErrors.maxWidth(50)),
  last_name: Yup.string()
    .required('This field is required')
    .max(50, validationErrors.maxWidth(50)),
  email: Yup.string().nullable().max(250, validationErrors.maxWidth(250)),
  company: Yup.string().nullable().max(250, validationErrors.maxWidth(250)),
  phone: Yup.string().nullable().max(250, validationErrors.maxWidth(250)),
  interests: Yup.string().nullable().max(2500, validationErrors.maxWidth(2500)),
  source: Yup.string().nullable().max(2500, validationErrors.maxWidth(2500)),
  membership: Yup.number().nullable().required('This field is required'),
  young_collector: Yup.boolean(),
  yc_age: Yup.number()
    .nullable()
    .when('young_collector', {
      is: true,
      then: Yup.number()
        .typeError('You must specify a number')
        .min(1, 'Minimum value is 1.')
        .required('This field is required'),
    }),
  sponsor_member: Yup.number()
    .nullable()
    .when('young_collector', {
      is: true,
      then: Yup.number().nullable().required('This field is required'),
    }),
  sponsor_relationship: Yup.string()
    .nullable()
    .when('young_collector', {
      is: true,
      then: Yup.string().nullable().required('This field is required'),
    }),
})

export const MembersForm: React.FC<Props> = ({
  onSubmit,
  isDisabled,
  values,
  mode = 'create',
  onDelete,
}) => {
  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(scheme),
  })

  const addToMembership = useSearchParams().get('membership')
  useEffect(() => {
    if (addToMembership) {
      setValue('membership', parseInt(addToMembership, 10))
    }
  }, [addToMembership, setValue])

  const isYoungCollector = watch('young_collector')
  const source = watch('source')
  const sourceArr = watch('source_arr')
  const isCurrentMemberSource =
    !!(source && source.length && source.includes('current member')) ||
    !!(sourceArr && sourceArr.length && sourceArr.includes('current member'))

  const membershipId = watch('membership')

  const { data: membership } = useQuery(
    ['membership', membershipId],
    () => membershipsAPI.getOne(membershipId),
    {
      enabled: !!membershipId,
    },
  )
  const countries = useCountries()

  useEffect(() => {
    if (mode === 'edit') {
      reset({ ...values })
    }
  }, [reset, values, mode])

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Box sx={{ my: 5 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} sx={{ my: 5 }}>
          <Grid item xs={5}>
            <Typography variant="h6" sx={{ mb: 3 }}>
              Member Details
            </Typography>
            <TextField
              {...register('first_name')}
              label="First Name"
              variant="standard"
              type="text"
              size="small"
              fullWidth
              helperText={errors.first_name?.message}
              error={!!errors.first_name?.message}
            />
            <TextField
              {...register('last_name')}
              label="Last Name"
              variant="standard"
              type="text"
              size="small"
              fullWidth
              margin="dense"
              helperText={errors.last_name?.message}
              error={!!errors.last_name?.message}
            />
            <TextField
              {...register('email')}
              label="Email"
              variant="standard"
              type="text"
              size="small"
              fullWidth
              margin="dense"
              helperText={errors.email?.message}
              error={!!errors.email?.message}
            />
            <TextField
              {...register('phone')}
              label="Phone"
              variant="standard"
              type="text"
              size="small"
              fullWidth
              margin="dense"
              helperText={errors.phone?.message}
              error={!!errors.phone?.message}
            />
            <TextField
              {...register('company')}
              label="Company"
              variant="standard"
              type="text"
              size="small"
              fullWidth
              margin="dense"
              helperText={errors.company?.message}
              error={!!errors.company?.message}
            />
            <TextField
              {...register('interests')}
              label="Interests"
              variant="standard"
              type="text"
              size="small"
              fullWidth
              margin="dense"
              helperText={errors.interests?.message}
              error={!!errors.interests?.message}
            />
            <div>
              {mode === 'create' ? (
                <Controller
                  control={control}
                  name="source_arr"
                  defaultValue={[]}
                  render={() => (
                    <FormControl
                      sx={{ mt: 3 }}
                      component="fieldset"
                      variant="standard"
                    >
                      <FormLabel component="legend">Source</FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="retail shop"
                              {...register('source_arr')}
                            />
                          }
                          label="online search"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="online search"
                              {...register('source_arr')}
                            />
                          }
                          label="retail shop"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="magazine or newspaper ad or article"
                              {...register('source_arr')}
                            />
                          }
                          label="magazine or newspaper ad or article"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="current member"
                              {...register('source_arr')}
                            />
                          }
                          label="current member (please provide the name)"
                        />
                      </FormGroup>
                    </FormControl>
                  )}
                />
              ) : (
                <TextField
                  {...register('source')}
                  label="Source"
                  variant="standard"
                  type="text"
                  size="small"
                  fullWidth
                  margin="dense"
                />
              )}

              {isCurrentMemberSource && (
                <Box mt={2}>
                  <Controller
                    control={control}
                    name="source_member"
                    defaultValue={null}
                    render={({ field: { ref, ...rest } }) => (
                      <MemberAutocomplete
                        error={errors?.source_member?.message}
                        {...rest}
                        inputRef={ref}
                        label={
                          mode === 'edit'
                            ? 'Source Member ID'
                            : '- please select -'
                        }
                      />
                    )}
                  />
                </Box>
              )}
            </div>
            <Controller
              control={control}
              name="young_collector"
              defaultValue={false}
              render={({ field }) => (
                <FormControlLabel
                  sx={{ mt: 3 }}
                  control={<Checkbox {...field} checked={field.value} />}
                  label="Student Collector"
                />
              )}
            />
            {isYoungCollector && (
              <>
                <TextField
                  {...register('yc_age')}
                  label="Age at Enrollment"
                  variant="standard"
                  type="number"
                  size="small"
                  fullWidth
                  margin="normal"
                  helperText={errors.yc_age?.message}
                  error={!!errors.yc_age?.message}
                />

                <Box mt={2}>
                  <Controller
                    control={control}
                    name="sponsor_member"
                    defaultValue={null}
                    render={({ field: { ref, ...rest } }) => (
                      <MemberAutocomplete
                        error={errors?.source_member?.message}
                        {...rest}
                        inputRef={ref}
                        label="Sponsor"
                      />
                    )}
                  />
                </Box>

                <TextField
                  {...register('sponsor_relationship')}
                  label="Relationship"
                  variant="standard"
                  type="text"
                  size="small"
                  fullWidth
                  margin="dense"
                  helperText={errors.sponsor_relationship?.message}
                  error={!!errors.sponsor_relationship?.message}
                />
              </>
            )}
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={5}>
            <Typography variant="h6" sx={{ mb: 3 }}>
              Membership
            </Typography>
            <Controller
              control={control}
              name="membership"
              defaultValue={null}
              render={({ field: { ref, ...rest } }) => (
                <MembershipAutocomplete
                  label={
                    mode === 'edit'
                      ? 'Membership ID'
                      : 'Enter Membership Address, ID, or Directory Email'
                  }
                  error={errors?.membership?.message}
                  {...rest}
                  inputRef={ref}
                />
              )}
            />
            <MembershipPreview
              membership={membership}
              countries={countries.data}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mt: 5 }}>
          {mode === 'edit' ? (
            <>
              <Grid item xs={3} />
              <Grid item xs={2}>
                <Button
                  onClick={handleClickOpen}
                  fullWidth
                  color="error"
                  disabled={isDisabled}
                >
                  <DeleteIcon />
                  Delete
                </Button>
                <Dialog open={open} onClose={handleClose}>
                  <DialogTitle>
                    Do you really want to delete that member?
                  </DialogTitle>
                  <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button onClick={onDelete} color="error">
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            </>
          ) : null}
          {mode === 'create' && <Grid item xs={4} />}
          <Grid item xs={2}>
            <BackButton defaultTo="/members" variant="outlined" fullWidth>
              Cancel
            </BackButton>
          </Grid>
          <Grid item xs={2}>
            <Button
              disabled={isDisabled}
              type="submit"
              variant="contained"
              fullWidth
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}
