import React, { useState } from 'react'
import { Container, Typography, Button } from '@mui/material'
import { Box } from '@mui/system'

import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { PaymentsMethodsTable } from '@/components/payments-methods'
import {
  GetPaymentsMethodsResponse,
  paymentsMethodsAPI,
} from '@/api/payments-methods'
import { PageTitle } from '@/components/shared/PageTitle'

export const PaymentsMethodsList = () => {
  const [page, setPage] = useState(1)
  const { data, isLoading, error } = useQuery<
    GetPaymentsMethodsResponse,
    Error
  >(['paymentMethods', page], () => paymentsMethodsAPI.get(page))

  const handlePageChange = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    current: number,
  ) => {
    setPage(current + 1)
  }

  if (error) {
    return <p>Something went wrong. Please, try again later.</p>
  }

  return (
    <Container maxWidth="xl">
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', mt: 6, mb: 4 }}
      >
        <PageTitle>Payments Methods</PageTitle>
        <div>
          <Button
            component={Link}
            to="/payments-methods/create"
            variant="contained"
          >
            + Add New
          </Button>
        </div>
      </Box>
      <PaymentsMethodsTable
        paymentMethods={data?.results || []}
        onPageChange={handlePageChange}
        page={page - 1}
        isLoading={isLoading}
        count={data?.count || 0}
        hasNext={!!data?.next}
        hasPrevious={!!data?.previous}
      />
    </Container>
  )
}
