import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components'
import { DesktopDatePicker } from '@/components/shared/DesktopDatePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { useQuery } from 'react-query'
import { countriesAPI, GetAllCountriesResponse } from '@/api/countries'
import { MembershipStatus } from '@/api/memberships'
import {
  GetAllPaymentsMethods,
  paymentsMethodsAPI,
} from '@/api/payments-methods'

/* eslint-disable */

const fields = {
  member_first_name: '',
  member_last_name: '',
  membership_id: '',
  membership_city: '',
  membership_street: '',
  membership_state: '',
  membership_country: '',
  membership_directory_email: '',
  payment_includes_donation: '',
  membership_zip: '',
  payment_date_from: null,
  payment_date_to: null,
  total_amount_from: '',
  total_amount_to: '',
}

/* eslint-enable */

interface Props {
  onSubmit: (filters: Record<string, unknown>) => void
  onReset: () => void
}

export const PaymentsFilters: React.FC<Props> = ({ onSubmit, onReset }) => {
  const { data: methods } = useQuery<GetAllPaymentsMethods, Error>(
    'payments-methods',
    paymentsMethodsAPI.getAll,
  )

  const { data: countries } = useQuery<GetAllCountriesResponse, Error>(
    'countries',
    countriesAPI.getAll,
  )

  const { register, handleSubmit, control, reset } = useForm()

  return (
    <Box sx={{ background: '#f9f9f9' }} py={6} px={3}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Grid container spacing={2}>
            <Grid item sx={{ width: '180px', minWidth: '180px' }}>
              <TextField
                {...register('member_first_name')}
                label="First Name"
                variant="outlined"
                type="text"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item sx={{ width: '180px', minWidth: '180px' }}>
              <TextField
                {...register('member_last_name')}
                label="Last Name"
                variant="outlined"
                type="text"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item sx={{ width: '230px', minWidth: '230px' }}>
              <TextField
                {...register('membership_directory_email')}
                label="Directory Email"
                variant="outlined"
                type="text"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item sx={{ width: '180px', minWidth: '180px' }}>
              <FormControl fullWidth size="small">
                <InputLabel id="membership_status-label">
                  Payment Type
                </InputLabel>

                <Controller
                  control={control}
                  name="payment_type"
                  defaultValue=""
                  render={({ field: { onChange, ...props } }) => (
                    <Select
                      labelId="membership_status-label"
                      label="Payment Type"
                      onChange={onChange}
                      size="small"
                      {...props}
                    >
                      <MenuItem value="">- select -</MenuItem>
                      {methods?.map((method) => (
                        <MenuItem value={method.id} key={method.title}>
                          {method.title}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 1, flexWrap: 'nowrap' }}>
            <Grid item sx={{ width: '150px', minWidth: '150px' }}>
              <TextField
                {...register('membership_id')}
                label="Membership ID"
                variant="outlined"
                type="text"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item sx={{ width: '100%' }}>
              <TextField
                {...register('membership_street')}
                label="Street"
                variant="outlined"
                type="text"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item sx={{ width: '100%' }}>
              <TextField
                {...register('membership_city')}
                label="City"
                variant="outlined"
                type="text"
                size="small"
                fullWidth
              />
            </Grid>

            <Grid item sx={{ width: '150px', minWidth: '150px' }}>
              <TextField
                {...register('membership_zip')}
                label="Postal Code"
                variant="outlined"
                type="text"
                size="small"
                fullWidth
              />
            </Grid>

            <Grid item sx={{ width: '200px', minWidth: '200px' }}>
              <FormControl fullWidth size="small">
                <InputLabel id="membership_country-label">Country</InputLabel>

                <Controller
                  control={control}
                  name="membership_country"
                  defaultValue=""
                  render={({ field: { onChange, ...props } }) => (
                    <Select
                      labelId="membership_country-label"
                      label="Country"
                      onChange={onChange}
                      {...props}
                      size="small"
                    >
                      <MenuItem value="">- select -</MenuItem>
                      {countries?.map((country) => (
                        <MenuItem value={country.name} key={country.name}>
                          {country.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container spacing={2} sx={{ mt: 1, flexWrap: 'nowrap' }}>
              <Grid item sx={{ width: '100%' }}>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name="payment_date_from"
                    defaultValue={null}
                    render={({ field: { onChange, value } }) => (
                      <DesktopDatePicker
                        label=" Payment Date From"
                        inputFormat="MM/dd/yyyy"
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item sx={{ width: '100%' }}>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name="payment_date_to"
                    defaultValue={null}
                    render={({ field: { onChange, value } }) => (
                      <DesktopDatePicker
                        label="To"
                        inputFormat="MM/dd/yyyy"
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item sx={{ width: '100%' }}>
                <FormControl fullWidth>
                  <TextField
                    {...register('total_amount_from')}
                    label="Total Amount From"
                    variant="outlined"
                    type="text"
                    size="small"
                    fullWidth
                  />
                </FormControl>
              </Grid>
              <Grid item sx={{ width: '100%' }}>
                <FormControl fullWidth>
                  <TextField
                    {...register('total_amount_to')}
                    label="To"
                    variant="outlined"
                    type="text"
                    size="small"
                    fullWidth
                  />
                </FormControl>
              </Grid>

              <Grid item>
                <Controller
                  control={control}
                  name="payment_includes_donation"
                  render={({ field: { value, ...field } }) => (
                    <FormControlLabel
                      sx={{ whiteSpace: 'nowrap' }}
                      control={<Checkbox {...field} checked={!!value} />}
                      label="Payment Includes Donation"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </LocalizationProvider>

          <Grid
            container
            spacing={2}
            sx={{ mt: 1, justifyContent: 'flex-end' }}
          >
            <Grid item>
              <Button variant="outlined" type="submit">
                Apply filter
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => {
                  reset(fields)
                  onReset()
                }}
              >
                Reset filter
              </Button>
            </Grid>
          </Grid>
        </div>
      </form>
    </Box>
  )
}
