import { version } from './version.json'

const isProd = process.env.NODE_ENV === 'production'

const config = {
  version,
  name: 'ipbaMP',
  isProduction: isProd,
  api: {
    baseURL: isProd ? '/api/v1/' : '//127.0.0.1:8000/api/v1/',
  },
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const appConfig: typeof config & { [k: string]: any } = config
