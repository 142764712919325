import { Button, FormControl, Grid, TextField } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components'
import { DesktopDatePicker } from '@/components/shared/DesktopDatePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'

/* eslint-disable */

const fields = {
  payment_date_from: null,
  payment_date_to: null,
}

/* eslint-enable */

interface Props {
  onSubmit: (filters: Record<string, unknown>) => void
  onReset: () => void
}

export const MembersDonationsFilters: React.FC<Props> = ({
  onSubmit,
  onReset,
}) => {
  const { handleSubmit, control, reset } = useForm()

  return (
    <Box sx={{ background: '#f9f9f9' }} py={6} px={3} mb={6}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Grid container spacing={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name="payment_date_from"
                    defaultValue={null}
                    render={({ field: { onChange, value } }) => (
                      <DesktopDatePicker
                        label="Donation Date From"
                        inputFormat="MM/dd/yyyy"
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name="payment_date_to"
                    defaultValue={null}
                    render={({ field: { onChange, value } }) => (
                      <DesktopDatePicker
                        label="To"
                        inputFormat="MM/dd/yyyy"
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </LocalizationProvider>
            <Grid item sx={{ marginLeft: 'auto' }}>
              <Button variant="outlined" type="submit">
                Apply filter
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => {
                  reset(fields)
                  onReset()
                }}
              >
                Reset filter
              </Button>
            </Grid>
          </Grid>
        </div>
      </form>
    </Box>
  )
}
