import React, { useState } from 'react'
import { Container, Typography, Button } from '@mui/material'
import { Box } from '@mui/system'

import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { countriesAPI, GetCountriesResponse } from '@/api/countries'
import { CountriesTable } from '@/components/countries'
import { PageTitle } from '@/components/shared/PageTitle'

export const CountriesList = () => {
  const [page, setPage] = useState(1)
  const { data, isLoading, error } = useQuery<GetCountriesResponse, Error>(
    ['countries', page],
    () => countriesAPI.get(page),
  )

  const handlePageChange = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    current: number,
  ) => {
    setPage(current + 1)
  }

  if (error) {
    return <p>Something went wrong. Please, try again later.</p>
  }

  return (
    <Container maxWidth="xl">
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', mt: 6, mb: 4 }}
      >
        <PageTitle>Countries</PageTitle>
        <div>
          <Button component={Link} to="/countries/create" variant="contained">
            + Add New
          </Button>
        </div>
      </Box>
      <CountriesTable
        countries={data?.results || []}
        onPageChange={handlePageChange}
        page={page - 1}
        isLoading={isLoading}
        count={data?.count || 0}
        hasNext={!!data?.next}
        hasPrevious={!!data?.previous}
      />
    </Container>
  )
}
