import { http } from '@/services/api/http'
import { PaginatedResponse } from './types/shared'

/* eslint-disable */

export interface PaymentsMethod {
  id: number
  title: string
  date_created: string
  date_updated: string
  change_status: boolean
}

export type GetAllPaymentsMethods = PaymentsMethod[]

export type GetPaymentsMethodsResponse = PaginatedResponse<PaymentsMethod>

export type GetAllPaymentsMethosdResponse = PaymentsMethod[]

export type CreatePaymentsMethodPayload = PaymentsMethod

export type GetOnePaymentsMethodResponse = PaymentsMethod

export type UpdatePaymentsMethodPayload = PaymentsMethod

/* eslint-enable */

const RESOURCE = '/payments/methods'

const get = async (page = 1, filters = {}) => {
  const response = await http.get<GetPaymentsMethodsResponse>(`${RESOURCE}/`, {
    params: {
      page,
      ...filters,
      ordering: '-id',
    },
  })

  return response.data
}

const getAll = async () => {
  const response = await http.get<GetAllPaymentsMethods>(`${RESOURCE}/`, {
    params: {
      no_page: true,
      ordering: '-id',
    },
  })
  return response.data
}

const create = async (payload: CreatePaymentsMethodPayload) => {
  const response = await http.post(`${RESOURCE}/`, payload)

  return response.data
}

const getOne = async (id: number) => {
  const response = await http.get<GetOnePaymentsMethodResponse>(
    `${RESOURCE}/${id}/`,
  )

  return response.data
}

const update = async (id: string, payload: UpdatePaymentsMethodPayload) => {
  const response = await http.put(`${RESOURCE}/${id}/`, payload)

  return response.data
}

const deleteOne = async (id: string) => {
  const response = await http.delete(`${RESOURCE}/${id}/`)

  return response.data
}

export const paymentsMethodsAPI = {
  get,
  getAll,
  getOne,
  create,
  update,
  deleteOne,
}
