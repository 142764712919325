import { AuthAction, AuthActionTypes, User } from '../types'

interface AuthState {
  user: User | null
  isAuthenticated: boolean
  loading: boolean
}

const initialState: AuthState = {
  user: null,
  isAuthenticated: false,
  loading: true,
}

const authReducer = (state = initialState, action: AuthAction): AuthState => {
  switch (action.type) {
    case AuthActionTypes.SetLoading:
      return {
        ...state,
        loading: action.payload,
      }
    case AuthActionTypes.Login:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
        loading: false,
      }
    case AuthActionTypes.Logout:
      return {
        ...state,
        user: null,
        isAuthenticated: false,
        loading: false,
      }
    default:
      return state
  }
}

export default authReducer
