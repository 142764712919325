import { AuthAPI, LoginConfirmPayload } from '@/api/auth'
import { push } from 'connected-react-router'
import { Dispatch } from 'redux'
import { AuthActionTypes } from '../types'

export const logout =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    localStorage.removeItem('token')
    dispatch({ type: AuthActionTypes.Logout })
    push('/login')
  }

export const loginConfirm =
  (payload: { token: string }) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch({ type: AuthActionTypes.SetLoading, payload: true })
      localStorage.setItem('token', payload.token)
      const user = await AuthAPI.fetchUser()
      dispatch({ type: AuthActionTypes.Login, payload: user })
      dispatch(push('/members'))
    } catch (e) {
      dispatch({ type: AuthActionTypes.Logout })
      dispatch(push('/login'))
    }

    dispatch({ type: AuthActionTypes.SetLoading, payload: false })
  }

export const fetchUser =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: AuthActionTypes.SetLoading,
      payload: true,
    })
    try {
      const user = await AuthAPI.fetchUser()
      dispatch({
        type: AuthActionTypes.Login,
        payload: user,
      })
    } catch (e) {
      dispatch({ type: AuthActionTypes.Logout })
    }
    dispatch({
      type: AuthActionTypes.SetLoading,
      payload: false,
    })
  }
