import React from 'react'

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { MemberContestReport } from '@/api/reports'
import { styled } from '@mui/system'
import { Loader } from '@/components/Loader'
import { EmptyResult } from '@/components/shared/EmptyResult'
import { clipText } from '@/utils/clipText'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

interface Props {
  reports: MemberContestReport[]
  isLoading: boolean
}

export const MemberContestTable: React.FC<Props> = ({ reports, isLoading }) => {
  if (isLoading) {
    return <Loader />
  }

  return reports.length ? (
    <TableContainer
      sx={{
        minWidth: '100%',
        width: 'min-content',
      }}
      component={Paper}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Recruiter Member ID</TableCell>
            <TableCell>Recruiter Name</TableCell>
            <TableCell>Recruited Member ID</TableCell>
            <TableCell>Recruited Member Name</TableCell>
            <TableCell>Recruited Member Registration Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reports.map((report) => (
            <StyledTableRow
              key={report.recruited_member_id}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
              }}
            >
              <TableCell>{report.recruiter_member_id}</TableCell>
              <TableCell>{clipText(report.recruiter_name)}</TableCell>

              <TableCell>{report.recruited_member_id}</TableCell>
              <TableCell>{clipText(report.recruited_name)}</TableCell>
              <TableCell>
                {report.recruited_member_registration_date.split(', ')[0]}
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <EmptyResult />
  )
}
