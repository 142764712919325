import { http } from '@/services/api/http'
import { PaginatedResponse } from './types/shared'
import saveFile from 'save-file'
import { makeDownloadFileName } from '@/utils/makeDownloadFileName'
import { trimObjectFields } from '@/utils/trimObjectFields'

/* eslint-disable */
export interface Membership {
  id: number
  street: string
  city: string
  state: string
  zip: string
  country: number
  address_private: boolean
  expiration: string
  lifetime: boolean
  status: number
  historical_notes: string
  directory_email: string
  date_created: string
  date_updated: string
  members_number: number
  members: {
    id: number
    first_name: string
    last_name: string
    email: string
  }[]
  digital: boolean
  digital_effective_date: string | null
}

export enum MembershipStatus {
  Active,
  Inactive,
  Deceased,
}

export type MembershipOrderingField =
  | 'id'
  | 'members_count'
  | 'directory_email'
  | 'status'
  | 'lifetime'
  | 'expiration'
  | 'date_created'
  | 'members__last_name'

export type MembershipOrderBy =
  | `${MembershipOrderingField}`
  | `-${MembershipOrderingField}`

export type GetMembershipsResponse = PaginatedResponse<Membership>

export type GetAllMembershipsResponse = Membership[]

export type GetOneMembershipResponse = Membership

export type CreateMembershipPayload = Partial<Membership>

export type UpdateMembershipPayload = Partial<Membership>

/* eslint-enable */

const RESOURCE = '/memberships'

const get = async (
  page = 1,
  filters: Record<string, any> = {},
  ordering: MembershipOrderBy = '-id',
) => {
  const response = await http.get<GetMembershipsResponse>(`${RESOURCE}/`, {
    params: {
      page,
      ...trimObjectFields(filters),
      lifetime: filters.lifetime ? 'true' : undefined,
      digital: filters.digital ? 'true' : undefined,
      ordering,
    },
  })
  return response.data
}

const getAll = async () => {
  const response = await http.get<GetAllMembershipsResponse>(`${RESOURCE}/`, {
    params: {
      no_page: true,
      ordering: '-id',
    },
  })

  return response.data
}

const getForAutocomplete = async (search: string) => {
  const response = await http.get<GetAllMembershipsResponse>(`${RESOURCE}/`, {
    params: {
      no_page: true,
      search,
      limit: 100,
    },
  })

  return response.data
}

const getOne = async (id: number) => {
  const response = await http.get<GetOneMembershipResponse>(
    `${RESOURCE}/${id}/`,
  )

  return response.data
}

const create = async (payload: CreateMembershipPayload) => {
  const response = await http.post(`${RESOURCE}/`, {
    ...trimObjectFields(payload),
    state: (payload.state || '').trim(),
  })

  return response.data
}

const update = async (id: string, payload: UpdateMembershipPayload) => {
  const response = await http.put(`${RESOURCE}/${id}/`, {
    ...trimObjectFields(payload),
    state: (payload.state || '').trim(),
  })

  return response.data
}

const deleteOne = async (id: string) => {
  const response = await http.delete(`${RESOURCE}/${id}/`)

  return response.data
}

const exportToFile = async (
  filters: Record<string, string> = {},
  ordering: MembershipOrderBy = '-id',
) => {
  const response = await http.get(`${RESOURCE}/export`, {
    responseType: 'blob',
    params: {
      ...trimObjectFields(filters),
      lifetime: filters.lifetime ? 'true' : undefined,
      digital: filters.digital ? 'true' : undefined,
      ordering,
    },
  })

  await saveFile(response.data, makeDownloadFileName('ipba-memberships-list'))
}

export const membershipsAPI = {
  get,
  getOne,
  getAll,
  getForAutocomplete,
  create,
  deleteOne,
  update,
  exportToFile,
}
