/**
 * @description The LinkToMembership component.
 */
import React from 'react'
import { Link, LinkProps } from 'react-router-dom'
import { CRUDEntity, CRUDOperation } from '@/types'
import { getCRUDLink } from '@/crudConfig'

type Props = Omit<LinkProps, 'to'> & {
  entity: CRUDEntity
  operation: CRUDOperation | string
  params?: Record<string, string | number>
  queryParams?: Record<string, string | number>
  dontStyle?: boolean
}

export const LinkToEntity: React.FC<Props> = function (props) {
  const {
    entity,
    operation,
    params = {},
    children,
    style = {},
    queryParams = {},
    dontStyle,
    ...rest
  } = props

  const search = Object.entries(queryParams)
    .map(([key, value]) => `${key}=${value}`)
    .join('&')

  return (
    <Link
      style={
        // eslint-disable-next-line no-nested-ternary
        rest.component ? style : dontStyle ? {} : { color: '#8b6239', ...style }
      }
      to={`${getCRUDLink(entity, operation, params)}${
        search ? `?${search}` : ''
      }`}
      {...rest}
    >
      {children}
    </Link>
  )
}
