import { useQuery } from 'react-query'
import { membershipsAPI } from '@/api/memberships'

export function useMembership(id?: number) {
  return useQuery(
    ['membership', id],
    () => membershipsAPI.getOne(id as number),
    {
      enabled: !!id,
    },
  )
}
