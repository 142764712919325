/**
 * @description The OrderingTableButton component.
 */
import React from 'react'
import { Button } from '@mui/material'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { MembershipOrderBy } from '@/api/memberships'
import { MemberOrderBy } from '@/api/members'

type Props<T = any> = {
  asc: MembershipOrderBy | MemberOrderBy | string
  desc?: MembershipOrderBy | MemberOrderBy | string
  current: string
  onClick: (v: T) => void
}

export const OrderingTableButton: React.FC<Props> = function ({
  asc,
  onClick,
  current,
  desc,
  children,
}) {
  const descVal = desc || `-${asc}`
  const isActiveASC = current === asc
  const isActiveDESC = current === descVal
  const isActive = isActiveASC || isActiveDESC
  // eslint-disable-next-line no-nested-ternary
  const nextValue = isActive ? (isActiveASC ? descVal : asc) : asc

  return (
    <Button
      sx={{
        textTransform: 'none',
        paddingLeft: '3px',
        paddingRight: '3px',
      }}
      startIcon={
        (isActiveASC || isActiveDESC) && (
          <>
            {isActiveASC && <ArrowDownwardIcon />}
            {isActiveDESC && <ArrowUpwardIcon />}
          </>
        )
      }
      onClick={() => onClick(nextValue)}
    >
      {children}
    </Button>
  )
}
