import React from 'react'
import { Provider } from 'react-redux'
import { QueryClientProvider } from 'react-query'

import { App } from './App'
import { ConnectedRouter } from 'connected-react-router'
import { fix100vh } from '@creatorum/ff/dist/viewport/fix100vh'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { store, history } from '@/services/store'
import queryClient from './services/queryClient'
import { theme } from './services/theme'
import './styles/main.scss'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

fix100vh()

const AppWrapper: React.FC = () => (
  <StyledEngineProvider injectFirst>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <App />
            <ToastContainer />
          </ThemeProvider>
        </QueryClientProvider>
      </ConnectedRouter>
    </Provider>
  </StyledEngineProvider>
)

export default AppWrapper
