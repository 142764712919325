import React, { useEffect, useMemo, useState } from 'react'
import {
  Button,
  FormControl,
  Grid,
  TextField,
  Box,
  DialogActions,
  Dialog,
  DialogTitle,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  Typography,
  InputAdornment,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { Controller, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { membershipsAPI, Membership } from '@/api/memberships'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import {
  GetAllPaymentsMethods,
  paymentsMethodsAPI,
} from '@/api/payments-methods'
import { MembershipPreview } from '../members'
import { getAutocompleteLabel } from '@/utils/inputs'
import { Payment } from '@/api/payments'
import { BackButton } from '@/components/shared/BackButton'
import { useSearchParams } from '@/hooks/useSearchParams'
import { Maybe } from '@/types'
import { MembershipAutocomplete } from '@/components/shared/MembershipAutocomplete'
import { validationErrors } from '@/defaults'
import { parse } from 'date-fns'

/* eslint-disable react/jsx-no-duplicate-props */

export type Mode = 'edit' | 'create'

interface Props {
  onSubmit: (form: any) => void
  isDisabled: boolean
  values?: Payment
  mode?: Mode
  onDelete?: () => void
}

const scheme = Yup.object().shape({
  membership_id: Yup.number().required('This field is required').nullable(),
  donate_amount: Yup.number()
    .typeError('Must be a number')
    .required('This field is required')
    .min(0, 'Must be 0 or greater'),
  check: Yup.string()
    .nullable()
    .when('payment_type', {
      is: 1,
      then: Yup.string()
        .nullable()
        .required('This field is required')
        .max(250, validationErrors.maxWidth(250)),
    }),
  amount: Yup.number()
    .typeError('Must be a number')
    .required('This field is required')
    .min(0, 'Must be 0 or greater'),
  payment_type: Yup.number().required('This field is required'),
  date: Yup.date().required('This field is required').nullable(),
  digital: Yup.boolean(),
})

export const PaymentsForm: React.FC<Props> = ({
  onSubmit,
  isDisabled,
  values,
  mode = 'create',
  onDelete,
}) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setValue,
  } = useForm<Record<string, any>>({
    resolver: yupResolver(scheme),
    defaultValues: {
      donate_amount: 0,
      amount: 0,
      digital: false,
    },
  })

  const { data: methods } = useQuery<GetAllPaymentsMethods, Error>(
    'payments-methods',
    paymentsMethodsAPI.getAll,
  )

  const membershipId = watch('membership_id')
  const donationAmount = watch('donate_amount')
  const membershipAmount = watch('amount')
  const paymentType = watch('payment_type')
  const isCheck = `${paymentType}` === '1'
  const paymentTypeAffectsMembershipStatus = useMemo(
    () =>
      Boolean(
        methods?.find((m) => `${m.id}` === `${paymentType}`)?.change_status,
      ),
    [methods, paymentType],
  )

  const addToMembership = useSearchParams().get('membership')
  useEffect(() => {
    if (addToMembership) {
      setValue('membership_id', parseInt(addToMembership, 10))
    }
  }, [addToMembership, setValue])

  const { data: membership } = useQuery(['membership', membershipId], () => {
    if (membershipId) {
      return membershipsAPI.getOne(membershipId)
    }

    return null
  })

  useEffect(() => {
    if (mode === 'edit') {
      reset({ ...values })
    }
  }, [reset, values, mode])

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Box sx={{ my: 5 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} sx={{ my: 5 }}>
          <Grid item xs={5}>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mb: 3 }}>
                Membership
              </Typography>

              <Controller
                control={control}
                name="membership_id"
                defaultValue={null}
                render={({ field: { ref, ...rest } }) => (
                  <MembershipAutocomplete
                    label={
                      mode === 'edit'
                        ? 'Membership ID'
                        : 'Enter Membership Address, ID, or Directory Email'
                    }
                    error={errors?.membership_id?.message}
                    {...rest}
                    inputRef={ref}
                  />
                )}
              />

              <MembershipPreview membership={membership} isPaymentView />
            </Grid>
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={5}>
            <Typography variant="h6" sx={{ mb: 3 }}>
              Payment Details
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name="date"
                  defaultValue={null}
                  render={({ field: { onChange, value } }) => {
                    const originValue = value
                    if (typeof value === 'string') {
                      value = parse(value, 'yyyy-MM-dd', new Date())
                      if (Number.isNaN(value.getTime())) {
                        value = parse(originValue, 'MM/dd/yyyy', new Date())
                      }
                    }
                    return (
                      <DesktopDatePicker
                        label="Payment Date"
                        inputFormat="MM/dd/yyyy"
                        onChange={onChange}
                        value={value}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={!!errors.date?.message}
                            helperText={errors.date?.message}
                            size="small"
                          />
                        )}
                      />
                    )
                  }}
                />
              </FormControl>
            </LocalizationProvider>
            <FormControl fullWidth size="small" sx={{ mt: 4 }}>
              <InputLabel id="membership_status-label">Payment Type</InputLabel>
              <Controller
                control={control}
                name="payment_type"
                defaultValue=""
                render={({ field: { onChange, ...props } }) => (
                  <Select
                    labelId="membership_status-label"
                    label="Payment Type"
                    onChange={onChange}
                    size="small"
                    error={!!errors.payment_type?.message}
                    {...props}
                  >
                    {methods?.map((method) => (
                      <MenuItem value={method.id} key={method.title}>
                        {method.title}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            {isCheck && (
              <TextField
                sx={{ mt: 4 }}
                {...register('check')}
                label="Check Number"
                variant="standard"
                type="text"
                size="small"
                fullWidth
                error={!!errors.check?.message}
                helperText={errors.check?.message}
              />
            )}
            <TextField
              sx={{ mt: 4 }}
              {...register('donate_amount')}
              label="Donation Amount"
              variant="standard"
              size="small"
              fullWidth
              helperText={errors.donate_amount?.message}
              error={!!errors.donate_amount?.message}
              InputProps={{
                inputProps: {
                  inputMode: 'numeric',
                  pattern: '[0-9.]*',
                },
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
            <TextField
              sx={{ mt: 4 }}
              {...register('amount')}
              label="Membership Amount*"
              variant="standard"
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9.]*',
              }}
              InputProps={{
                inputProps: {
                  inputMode: 'numeric',
                  pattern: '[0-9.]*',
                },
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              size="small"
              fullWidth
              helperText={
                errors.amount?.message ||
                '* - if not $0.00, the Membership Expiration Date will be updated accordingly'
              }
              error={!!errors.amount?.message}
            />
            <TextField
              sx={{ mt: 4 }}
              label="Total Amount"
              variant="standard"
              disabled={true}
              InputProps={{
                inputProps: {
                  inputMode: 'numeric',
                  pattern: '[0-9.]*',
                  min: 1,
                },
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              size="small"
              fullWidth
              value={(
                parseFloat(donationAmount) + parseFloat(membershipAmount) || 0
              )
                .toFixed(2)
                .replace(',', '.')}
            />

            {paymentTypeAffectsMembershipStatus && (
              <Controller
                control={control}
                name="digital"
                defaultValue={false}
                render={({ field }) => (
                  <FormControlLabel
                    sx={{ mt: 3 }}
                    control={<Checkbox {...field} checked={field.value} />}
                    label="Payment for Digital Membership"
                  />
                )}
              />
            )}
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mt: 5 }}>
          {mode === 'edit' ? (
            <>
              <Grid item xs={3} />
              <Grid item xs={2}>
                <Button
                  onClick={handleClickOpen}
                  fullWidth
                  color="error"
                  disabled={isDisabled}
                >
                  <DeleteIcon />
                  Delete
                </Button>
                <Dialog open={open} onClose={handleClose}>
                  <DialogTitle>
                    Do you really want to delete that membership?
                  </DialogTitle>
                  <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button onClick={onDelete} color="error">
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            </>
          ) : null}
          {mode === 'create' && <Grid item xs={4} />}
          <Grid item xs={2}>
            <BackButton defaultTo="/payments" variant="outlined" fullWidth>
              Cancel
            </BackButton>
          </Grid>
          <Grid item xs={2}>
            <Button
              disabled={isDisabled}
              type="submit"
              variant="contained"
              fullWidth
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}
