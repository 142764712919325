import React, { useMemo } from 'react'
import { Container, Typography, Button, Box } from '@mui/material'
import { useUser } from '@/hooks/useUser'
import { User } from '@/services/store/types'
import { reportsConfig, reportTypes } from '@/reportsConfig'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { PageTitle } from '@/components/shared/PageTitle'

export const ReportsDummy: React.FC = function (props) {
  const user = useUser() as User
  const routes = useMemo(
    () =>
      reportTypes
        .map((rt) => reportsConfig[rt])
        .filter((rc) => rc.roles.some((role) => user.roles.includes(role)))
        .map((rc) => ({
          title: rc.title,
          link: rc.routePath,
        })),
    [user.roles],
  )

  return (
    <Container maxWidth="xl">
      <Box mt={6} mb={4}>
        <PageTitle>Reports</PageTitle>
      </Box>
      <Box>
        <ul>
          {routes.map((route) => (
            <Typography variant="body1" key={route.link}>
              <li style={{ marginTop: '10px', marginLeft: '15px' }}>
                <Link to={route.link}>{route.title}</Link>
              </li>
            </Typography>
          ))}
        </ul>
      </Box>
    </Container>
  )
}
