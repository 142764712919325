import React, { useEffect } from 'react'
import { MemberPreview, MembershipPreview } from '@/components/members'
import { Container, Grid, Typography } from '@mui/material'
import { useHistory, useParams } from 'react-router'
import { Box } from '@mui/system'
import { TitleWithBackButton } from '@/components/layout/TitleWIthBackButton'
import { Loader } from '@/components/Loader'
import { useMember } from '@/hooks/useMember'
import { useCountries } from '@/hooks/useCountries'
import { Country } from '@/api/countries'

export const MembersView = () => {
  const { id } = useParams<{ id: string }>()

  const countries = useCountries()
  const member = useMember(parseInt(id, 10))
  const sourceMember = useMember(member.data?.source_member)
  const sponsorMember = useMember(member.data?.sponsor_member)
  const history = useHistory()
  useEffect(() => {
    if (member.isError) {
      history.push('/members')
    }
  }, [history, member.isError])

  const loading =
    member.isLoading ||
    sourceMember.isLoading ||
    sponsorMember.isLoading ||
    countries.isLoading

  return (
    <Container maxWidth="xl">
      <TitleWithBackButton title="Vew Member Details" to="/members" />
      {/* eslint-disable-next-line no-nested-ternary */}
      {loading ? (
        <Loader />
      ) : member.data ? (
        <Box sx={{ my: 5 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h6" sx={{ mb: 3 }}>
                Member Details
              </Typography>
              <MemberPreview
                member={member.data}
                sourceMember={sourceMember.data}
                sponsorMember={sponsorMember.data}
              />
            </Grid>
            <Grid item xs={6}>
              {member.data.membership_data && (
                <>
                  <Typography variant="h6" sx={{ mb: 3 }}>
                    Membership Details
                  </Typography>
                  <MembershipPreview
                    membership={member.data.membership_data}
                    countries={countries.data as Country[]}
                  />
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      ) : null}
    </Container>
  )
}
