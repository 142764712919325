import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { formatter } from '@/utils/format'
import { Button, Grid, Typography, Box } from '@mui/material'
import { Payment } from '@/api/payments'
import { Maybe } from '@/types'
import { Membership } from '@/api/memberships'
import { MembershipPreview } from '@/components/members'
import { Country } from '@/api/countries'

interface Props {
  payment?: Payment | null
  paymentMethod: string
  membership: Maybe<Membership>
  countries?: Country[]
}

export const StyledMembershipPreview = styled.article`
  margin: 30px 0 0;

  .row {
    margin: 10px 0 0;
    display: flex;

    .label {
      min-width: 200px;
      color: #717070;
      margin: 0 20px 0 0;
      text-align: right;
    }

    .link {
      color: #8b6239 !important;
    }

    .text {
      color: #000;
    }
  }

  .button-container {
    margin: 40px 0 0;
    display: flex;
    justify-content: center;
  }
`

export const PaymentPreview: React.FC<Props> = ({
  payment,
  paymentMethod,
  membership,
  countries,
}) =>
  payment ? (
    <StyledMembershipPreview>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h6">Membership</Typography>
          <MembershipPreview
            countries={countries}
            isPaymentView={true}
            membership={membership}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6">Payment</Typography>
          <Box className="row" sx={{ marginTop: '30px !important' }}>
            <span className="label"> Payment Date </span>
            <span className="text">{formatter.date(payment.date)}</span>
          </Box>
          <div className="row">
            <span className="label"> Payment Method </span>
            <span className="text">{paymentMethod}</span>
          </div>
          {payment.check && (
            <div className="row">
              <span className="label"> Check Number </span>
              <span className="text">{payment.check}</span>
            </div>
          )}
          {/* <div className="row"> */}
          {/*  <span className="label"> Date Created </span> */}
          {/*  <span className="text">{formatter.date(payment.date_created)}</span> */}
          {/* </div> */}
          <div className="row">
            <span className="label"> Donation Amount </span>
            <span className="text">
              {formatter.currency(payment.donate_amount)}
            </span>
          </div>
          <div className="row">
            <span className="label"> Membership Amount </span>
            <span className="text">{formatter.currency(payment.amount)}</span>
          </div>
          <div className="row">
            <span className="label"> Total Amount </span>
            <span className="text">
              {formatter.currency(payment.total_amount)}
            </span>
          </div>
          <div className="row">
            <span className="label">
              Payment
              <br /> for Digital Membership
            </span>
            <span className="text">{payment.digital ? 'Yes' : 'No'}</span>
          </div>
        </Grid>
      </Grid>

      <div className="button-container">
        <Button
          variant="contained"
          component={Link}
          to={`/payments/edit/${payment.id}`}
        >
          Edit Payment
        </Button>
      </div>
    </StyledMembershipPreview>
  ) : null
