import React from 'react'
import { MembershipsForm } from '@/components/memberships'
import { Container } from '@mui/material'
import { useMutation, useQueryClient } from 'react-query'
import { formatForm } from '@/utils/format-form'
import { membershipsAPI } from '@/api/memberships'
import { toast } from 'react-toastify'
import { TitleWithBackButton } from '@/components/layout/TitleWIthBackButton'
import { useHistory } from 'react-router'
import { getErrorMessage } from '@/api/getErrorMessage'

export const MembershipsCreate = () => {
  const history = useHistory()
  const queryClient = useQueryClient()

  const { isLoading, mutate } = useMutation((form: any) =>
    membershipsAPI.create(form),
  )

  const handleSubmit = (form: any) => {
    mutate(formatForm(form), {
      onSuccess: (data) => {
        toast.success('Membership created')
        queryClient.invalidateQueries('memberships')
        if (data?.id) {
          history.push(`/memberships/edit/${data.id}`)
        }
      },
      onError: (error) => {
        const message = getErrorMessage(error)
        toast.error(`Membership isn't created. ${message}`)
      },
    })
  }

  return (
    <Container maxWidth="xl">
      <TitleWithBackButton title="New Membership Details" to="/memberships" />
      <MembershipsForm onSubmit={handleSubmit} isDisabled={isLoading} />
    </Container>
  )
}
