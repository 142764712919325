import React from 'react'

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { MemberDonationsReport } from '@/api/reports'
import { styled } from '@mui/system'
import { Loader } from '@/components/Loader'
import { EmptyResult } from '@/components/shared/EmptyResult'
import { formatter } from '@/utils/format'
import { clipText } from '@/utils/clipText'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

interface Props {
  reports: MemberDonationsReport[]
  isLoading: boolean
}

export const MembersDonationsTable: React.FC<Props> = ({
  reports,
  isLoading,
}) => {
  if (isLoading) {
    return <Loader />
  }

  return reports.length ? (
    <TableContainer
      sx={{
        minWidth: '100%',
        width: 'min-content',
      }}
      component={Paper}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {/* <TableCell>Payment ID</TableCell> */}
            <TableCell>Date</TableCell>
            <TableCell>Member Name</TableCell>
            <TableCell>Donations Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reports.map((report) => (
            <StyledTableRow
              key={report.payment_id}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
              }}
            >
              {/* <TableCell>{report.payment_id}</TableCell> */}
              <TableCell>{report.payment_date}</TableCell>
              <TableCell>{clipText(report.member_full_name)}</TableCell>
              <TableCell>
                {formatter.currency(report.donation_amount)}
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <EmptyResult />
  )
}
