import React, { useEffect, useState } from 'react'
import { Box, Button, Container, TextField, Typography } from '@mui/material'
import { useHistory, useLocation } from 'react-router'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { AuthAPI, SetNewPasswordPayload } from '@/api/auth'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { AxiosError } from 'axios'

interface Location {
  query: string
}

type Credentials = Pick<SetNewPasswordPayload, 'auth' | 'token'>

/* eslint-disable */

type Form = {
  new_password: string
  confirm_new_password: string
}

/* eslint-enable */

const scheme = Yup.object().shape({
  new_password: Yup.string()
    .required('This field is requied')
    .min(6, 'Must be at least 6 characters'),
  confirm_new_password: Yup.string()
    .required('This field is required')
    .oneOf([Yup.ref('new_password'), null], 'Passwords must match'),
})

const TOKEN_IS_NOT_VALID_MESSAGE =
  'Token is not valid, please request a new one'

export const ConfirmPasswordReset = () => {
  const history = useHistory()
  const location = useLocation<Location>()

  const [credentials, setCredentials] = useState<Credentials | null>(null)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(scheme) })

  useEffect(() => {
    try {
      const query = new URLSearchParams(location.search)
      const auth = query.get('auth')
      const token = query.get('token')

      if (!auth || !token) {
        history.push('/login')
        return
      }

      setCredentials({
        auth,
        token,
      })
    } catch (e) {
      console.error(e)
    }
  }, [location, history])

  /* eslint-disable */

  const { isLoading, mutate, isError, isSuccess } = useMutation(
    ({ new_password, auth, token }: SetNewPasswordPayload) =>
      AuthAPI.setNewPassword({ new_password, auth, token }),
    {
      onSuccess: () => {
        toast.success('Password successfully changed.')
        history.push('/login')
      },
      onError: (e: AxiosError) => {
        if (e.response?.status === 400) {
          if (e.response.data.error === TOKEN_IS_NOT_VALID_MESSAGE) {
            history.push('/login')
          } else {
            toast.error(e.response.data.error)
          }
        } else {
          toast.error('Error happened. Please try again.')
          history.push('/login')
        }
      },
    },
  )

  /* eslint-enable */

  const onSubmit = (form: Form) => {
    if (credentials) {
      mutate({ ...form, ...credentials })
    } else {
      history.push('/login')
    }
  }

  return (
    <Container maxWidth="md">
      <Box sx={{ display: 'flex', alignItems: 'center' }} mt={8}>
        <Box sx={{ width: '100%' }}>
          <Typography
            variant="h3"
            gutterBottom
            fontFamily="Lateef"
            fontSize="65px"
            color="primary"
          >
            IPBA Membership Database
          </Typography>
          <Typography variant="h6" gutterBottom>
            Enter new password
          </Typography>
          <Box sx={{ mt: 3, maxWidth: '500px' }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box sx={{ my: 3 }}>
                <TextField
                  {...register('new_password')}
                  fullWidth
                  label="New Password"
                  variant="standard"
                  type="password"
                  disabled={isLoading}
                  helperText={errors.new_password?.message}
                  error={!!errors.new_password?.message}
                />
                <TextField
                  {...register('confirm_new_password')}
                  fullWidth
                  label="Confirm New Password"
                  variant="standard"
                  type="password"
                  disabled={isLoading}
                  helperText={errors.confirm_new_password?.message}
                  error={!!errors.confirm_new_password?.message}
                  sx={{ mt: 3 }}
                />
              </Box>

              <Button
                type="submit"
                variant="contained"
                disabled={isLoading}
                sx={{ mt: 2 }}
              >
                Submit
              </Button>
            </form>
          </Box>
        </Box>
      </Box>
    </Container>
  )
}
