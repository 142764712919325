import { UserRole } from '@/services/store/types'

export type ReportConfig = {
  title: string
  routePath: string
  roles: UserRole[]
}

export const reportTypes = [
  'membershipSummary',
  'mailingList',
  'membershipDirectory',
  'emailListing',
  'memberContest',
  'donations',
] as const

export type ReportType = typeof reportTypes[number]

export const reportsConfig: Record<ReportType, ReportConfig> = {
  membershipSummary: {
    title: 'Membership Summary Report',
    routePath: '/reports/membership-summary',
    roles: ['administrator', 'president'],
  },
  mailingList: {
    title: 'Mailing List Report',
    routePath: '/reports/mailing-list',
    roles: ['administrator', 'publisher'],
  },
  membershipDirectory: {
    title: 'Membership Directory Report',
    routePath: '/reports/membership-directory',
    roles: ['administrator', 'president'],
  },
  emailListing: {
    title: 'Email Listing Report for eNews',
    routePath: '/reports/email-listing',
    roles: ['administrator', 'news_editor'],
  },
  memberContest: {
    title: 'Member Contest Report',
    routePath: '/reports/member-contest',
    roles: ['administrator', 'president'],
  },
  donations: {
    title: 'Member Donations Report',
    routePath: '/reports/donations',
    roles: ['administrator', 'president'],
  },
}
