import { Button, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

/* eslint-disable */

interface Props {
  values: {
    email: string
    first_name: string
    last_name: string
  }
  onSubmit: (form: any) => void
  isDisabled: boolean
}

/* eslint-enable */

export const ProfileSettings: React.FC<Props> = ({
  values,
  onSubmit,
  isDisabled,
}) => {
  const { register, handleSubmit, reset } = useForm()

  useEffect(() => {
    reset(values)
  }, [reset, values])

  return (
    <Box sx={{ my: 5 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h6" sx={{ mb: 3 }}>
          Profile Settings
        </Typography>
        <Box sx={{ my: 4 }}>
          <TextField
            {...register('first_name')}
            label="First Name"
            variant="outlined"
            type="text"
            size="small"
            fullWidth
            disabled={isDisabled}
          />
          <TextField
            {...register('last_name')}
            label="Last Name"
            variant="outlined"
            type="text"
            size="small"
            fullWidth
            sx={{ mt: 4 }}
            disabled={isDisabled}
          />
          <TextField
            {...register('email')}
            label="Email"
            variant="outlined"
            type="email"
            size="small"
            fullWidth
            sx={{ mt: 4 }}
            disabled={true}
          />
        </Box>
        <Button type="submit" variant="contained" disabled={isDisabled}>
          Save
        </Button>
      </form>
    </Box>
  )
}
