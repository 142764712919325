import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { DesktopDatePicker } from '@/components/shared/DesktopDatePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { useQuery } from 'react-query'
import { countriesAPI, GetAllCountriesResponse } from '@/api/countries'
import { MembershipStatus } from '@/api/memberships'
import { renderMembershipStatus } from '@/utils/renderMembershipStatus'

/* eslint-disable */

const fields = {
  company: '',
  date_created_from: null,
  date_created_to: null,
  email: '',
  first_name: '',
  interests: '',
  last_edited_from: null,
  last_edited_to: null,
  last_name: '',
  membership_country: '',
  membership_state: '',
  membership_city: '',
  membership_street: '',
  membership_zip: '',
  membership_expiration_date_from: null,
  membership_expiration_date_to: null,
  membership: '',
  membership_status: MembershipStatus.Active,
  source: '',
  yc_age: '',
  young_collector: false,
}

/* eslint-enable */

interface Props {
  onSubmit: (filters: Record<string, unknown>) => void
  onReset: () => void
}

export const MembersFilters: React.FC<Props> = ({ onSubmit, onReset }) => {
  const { data } = useQuery<GetAllCountriesResponse, Error>(
    'countries',
    countriesAPI.getAll,
  )

  const countries = data || []

  const { register, handleSubmit, control, reset } = useForm({
    defaultValues: fields,
  })

  return (
    <Box sx={{ background: '#f9f9f9' }} py={6} px={3}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Grid container spacing={2} flexWrap="nowrap">
            <Grid item={true} sx={{ width: '100%' }}>
              <TextField
                {...register('first_name')}
                label="First Name"
                variant="outlined"
                type="text"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item sx={{ width: '100%' }}>
              <TextField
                {...register('last_name')}
                label="Last Name"
                variant="outlined"
                type="text"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item sx={{ width: '100%' }}>
              <TextField
                {...register('email')}
                label="Email"
                variant="outlined"
                type="text"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item sx={{ width: '150px', minWidth: '150px' }}>
              <TextField
                {...register('company')}
                label="Company"
                variant="outlined"
                type="text"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item sx={{ width: '137px', minWidth: '137px' }}>
              <Controller
                control={control}
                name="young_collector"
                render={({ field: { value, ...field } }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={!!value} />}
                    label="S-Collector"
                  />
                )}
              />
            </Grid>
            <Grid item sx={{ width: '140px', minWidth: '140px' }}>
              <TextField
                {...register('yc_age')}
                label="S-C Age at Enr."
                variant="outlined"
                type="text"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item sx={{ width: '160px', minWidth: '160px' }}>
              <TextField
                {...register('interests')}
                label="Interests"
                variant="outlined"
                type="text"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item sx={{ width: '150px', minWidth: '150px' }}>
              <TextField
                {...register('source')}
                label="Source"
                variant="outlined"
                type="text"
                size="small"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} flexWrap="nowrap" sx={{ mt: 1 }}>
            <Grid item sx={{ width: '150px', minWidth: '150px' }}>
              <TextField
                {...register('membership')}
                label="Membership ID"
                variant="outlined"
                type="text"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item sx={{ width: '100%' }}>
              <TextField
                {...register('membership_street')}
                label="Street"
                variant="outlined"
                type="text"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item sx={{ width: '100%' }}>
              <TextField
                {...register('membership_city')}
                label="City"
                variant="outlined"
                type="text"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item sx={{ width: '150px', minWidth: '150px' }}>
              <TextField
                {...register('membership_zip')}
                label="Postal Code"
                variant="outlined"
                type="text"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item sx={{ width: '200px', minWidth: '200px' }}>
              <FormControl fullWidth size="small">
                <InputLabel id="membership_country-label">Country</InputLabel>

                <Controller
                  control={control}
                  name="membership_country"
                  render={({ field: { onChange, ...props } }) => (
                    <Select
                      labelId="membership_country-label"
                      label="Country"
                      onChange={onChange}
                      {...props}
                      size="small"
                    >
                      <MenuItem value="">- select -</MenuItem>
                      {countries.map((country) => (
                        <MenuItem value={country.name} key={country.name}>
                          {country.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item sx={{ width: '190px', minWidth: '190px' }}>
              <FormControl fullWidth size="small">
                <InputLabel id="membership_status-label">
                  Membership Status
                </InputLabel>

                <Controller
                  control={control}
                  name="membership_status"
                  render={({ field: { onChange, ...props } }) => (
                    <Select
                      labelId="membership_status-label"
                      label="Membership Status"
                      onChange={onChange}
                      size="small"
                      {...props}
                    >
                      <MenuItem value="">- select -</MenuItem>
                      <MenuItem value={MembershipStatus.Active}>
                        {renderMembershipStatus(MembershipStatus.Active)}
                      </MenuItem>
                      <MenuItem value={MembershipStatus.Inactive}>
                        {renderMembershipStatus(MembershipStatus.Inactive)}
                      </MenuItem>
                      <MenuItem value={MembershipStatus.Deceased}>
                        {renderMembershipStatus(MembershipStatus.Deceased)}
                      </MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={2}>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name="membership_expiration_date_from"
                    render={({ field: { onChange, value } }) => (
                      <DesktopDatePicker
                        label="Expiration Date From"
                        inputFormat="MM/dd/yyyy"
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name="membership_expiration_date_to"
                    render={({ field: { onChange, value } }) => (
                      <DesktopDatePicker
                        label="To"
                        inputFormat="MM/dd/yyyy"
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name="date_created_from"
                    render={({ field: { onChange, value } }) => (
                      <DesktopDatePicker
                        label="Date Added From"
                        inputFormat="MM/dd/yyyy"
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name="date_created_to"
                    render={({ field: { onChange, value } }) => (
                      <DesktopDatePicker
                        label="To"
                        inputFormat="MM/dd/yyyy"
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name="last_edited_from"
                    render={({ field: { onChange, value } }) => (
                      <DesktopDatePicker
                        label="Last Edited From"
                        inputFormat="MM/dd/yyyy"
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name="last_edited_to"
                    render={({ field: { onChange, value } }) => (
                      <DesktopDatePicker
                        label="To"
                        inputFormat="MM/dd/yyyy"
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </LocalizationProvider>
          <Grid
            container
            spacing={2}
            sx={{ mt: 1, justifyContent: 'flex-end' }}
          >
            <Grid item>
              <Button variant="outlined" type="submit">
                Apply filter
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => {
                  reset(fields)
                  onReset()
                }}
              >
                Reset filter
              </Button>
            </Grid>
          </Grid>
        </div>
      </form>
    </Box>
  )
}
