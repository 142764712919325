import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import { Loader } from './Loader'
import { UserRole } from '@/services/store/types'

type Props = {
  component: React.ComponentType
  roles: UserRole[]
} & RouteProps

export const PrivateRoute: React.FC<Props> = (props) => {
  const { isAuthenticated, user, loading } = useSelector(
    (state: any) => state.auth,
  )

  const { component: Component, roles, ...rest } = props

  const userHasRequiredRole = useMemo(
    () => !!(user && roles.some((role) => user.roles.includes(role))),
    [roles, user],
  )

  if (loading) {
    return <Loader />
  }

  return (
    <Route
      {...rest}
      render={(componentProps) =>
        isAuthenticated && userHasRequiredRole ? (
          <Component {...componentProps} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  )
}
