import React from 'react'
import { MembershipDirectoryReport, reportsAPI } from '@/api/reports'
import { Container, Typography } from '@mui/material'
import { useQuery } from 'react-query'
import { Box } from '@mui/system'
import { TitleWithBackButton } from '@/components/layout/TitleWIthBackButton'
import { DownloadButton } from '@/components/reports/shared/DownloadButton'
import { MembershipDirectoryTable } from '@/components/reports/tables/MembershipDirectoryTable'
import { reportsConfig } from '@/reportsConfig'

export const MembershipDirectory = () => {
  const { data: reports, isLoading } = useQuery<
    MembershipDirectoryReport[],
    Error
  >(
    ['membership-direcotory-report'],
    () => reportsAPI.getMembershipDirectoryReport(),
    {
      cacheTime: 0,
    },
  )

  return (
    <Container maxWidth="xl">
      <TitleWithBackButton
        title={reportsConfig.membershipDirectory.title}
        to="/reports"
      />
      <DownloadButton
        onClick={() => reportsAPI.downloadMembershipDirectoryReport()}
      />
      <Typography textAlign="right">
        Email address and collecting interests can be found in the export XLSX
        file
      </Typography>
      <Box sx={{ my: 5 }}>
        <MembershipDirectoryTable
          isLoading={isLoading}
          reports={reports || []}
        />
      </Box>
    </Container>
  )
}
