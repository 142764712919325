import React, { Suspense, useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { CssBaseline, Box } from '@mui/material'
import { Login } from './pages/Login'
import { PrivateRoute } from './components/PrivateRoute'
import { fetchUser, logout } from './services/store/actions/auth'
import { useDispatch } from 'react-redux'
import { Header } from '@/components/layout/Header'
import { NotFound } from './components/NotFound'
import { Footer } from './components/layout/Footer'
import { PasswordReset } from './pages/password-reset/PasswordReset'
import { ConfirmPasswordReset } from './pages/password-reset/ConfirmPasswordReset'
import { routes } from './routes'
import * as authEvent from '@/events/auth'

type Props = Record<string, unknown>

export const App: React.FC<Props> = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    const cb = () => {
      dispatch(logout())
    }
    authEvent.addEventListener(cb)
    return () => {
      authEvent.removeEventListener(cb)
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchUser())
  }, [dispatch])

  return (
    <>
      <CssBaseline />
      <Suspense fallback="Loading...">
        <div className="wrapper">
          <Box display="flex" flexDirection="column" minHeight="100vh">
            <Header />
            <Box>
              <Switch>
                <Redirect exact from="/" to="/reports" />
                <Route path="/login" exact component={Login} />
                <Route
                  path="/login/reset-password"
                  exact
                  component={PasswordReset}
                />
                <Route
                  path="/password-reset/confirm"
                  exact
                  component={ConfirmPasswordReset}
                />
                {routes.map((route) => (
                  <PrivateRoute key={route.path} exact {...route} />
                ))}
                <Route path="*" exact component={NotFound} />
              </Switch>
            </Box>
            <Box mt="auto">
              <Footer />
            </Box>
          </Box>
        </div>
      </Suspense>
    </>
  )
}
