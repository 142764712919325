import React from 'react'
import styled from 'styled-components'

const StyledFooter = styled.footer`
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  color: #fff;
`

export const Footer = () => (
  <StyledFooter>
    <span className="copyright">
      © International Perfume Bottle Association
    </span>
  </StyledFooter>
)
