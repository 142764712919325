import React, { useMemo } from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import { Box, styled } from '@mui/system'
import { formatter } from '@/utils/format'
import { Loader } from '../Loader'
import { Payment } from '@/api/payments'
import { labelDisplayedRows } from '@/utils/inputs'
import { LinkToEntity } from '@/components/shared/LinkToEntity'
import { PaymentsMethod } from '@/api/payments-methods'
import { EmptyResult } from '@/components/shared/EmptyResult'
import { TablePaginationActions } from '@/components/shared/TablePaginationActions'
import { renderMembershipMembersNames } from '@/utils/renderMembershipMembersNames'
import { clipText } from '@/utils/clipText'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

interface Props {
  payments: Payment[]
  methods?: PaymentsMethod[]
  isLoading: boolean
  count: number
  hasNext: boolean
  hasPrevious: boolean
  page: number
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number,
  ) => void
}

export const PaymentsTable: React.FC<Props> = ({
  payments,
  isLoading,
  count,
  hasNext,
  hasPrevious,
  page,
  onPageChange,
  methods,
}) => {
  const methodsMap = useMemo(() => {
    const out: Record<number, string> = {}
    if (!methods) return out
    methods.forEach((m) => {
      out[m.id] = m.title
    })
    return out
  }, [methods])

  if (isLoading) {
    return <Loader />
  }

  const renderPagination = () => (
    <TablePagination
      component="div"
      count={count}
      rowsPerPage={100}
      rowsPerPageOptions={[100]}
      labelDisplayedRows={labelDisplayedRows}
      page={page}
      onPageChange={onPageChange}
      backIconButtonProps={{
        disabled: !hasPrevious,
      }}
      nextIconButtonProps={{
        disabled: !hasNext,
      }}
      ActionsComponent={TablePaginationActions}
    />
  )

  return (
    <Box sx={{ mt: 5 }}>
      {payments.length ? (
        <>
          {renderPagination()}
          <TableContainer
            sx={{
              minWidth: '100%',
              width: 'min-content',
            }}
            component={Paper}
          >
            <Table
              sx={{ minWidth: 650, whiteSpace: 'nowrap' }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Mbrship</TableCell>
                  <TableCell>Mbrs</TableCell>
                  <TableCell>Check #</TableCell>
                  <TableCell>Payment Method</TableCell>
                  <TableCell>Payment Date</TableCell>
                  <TableCell>Donation Amount</TableCell>
                  <TableCell>Membership Amount</TableCell>
                  <TableCell>Total Amount</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {payments.map((payment) => (
                  <StyledTableRow
                    key={payment.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {payment.id}
                    </TableCell>
                    <TableCell>
                      <LinkToEntity
                        entity="membership"
                        operation="read"
                        params={{ id: payment.membership_id }}
                      >
                        {payment.membership_id}
                      </LinkToEntity>
                    </TableCell>
                    <TableCell>
                      {clipText(renderMembershipMembersNames(payment.members))}
                    </TableCell>
                    <TableCell>{payment.check ?? ''}</TableCell>
                    <TableCell>{methodsMap[payment.payment_type]}</TableCell>
                    <TableCell>
                      {formatter.date(payment.date, 'short')}
                    </TableCell>
                    <TableCell>
                      {formatter.currency(payment.donate_amount)}
                    </TableCell>
                    <TableCell>{formatter.currency(payment.amount)}</TableCell>
                    <TableCell>
                      {formatter.currency(payment.total_amount)}
                    </TableCell>

                    <TableCell>
                      <LinkToEntity
                        entity="payment"
                        operation="read"
                        params={{ id: payment.id }}
                        style={{ marginRight: '10px' }}
                      >
                        View
                      </LinkToEntity>
                      <LinkToEntity
                        entity="payment"
                        operation="update"
                        params={{ id: payment.id }}
                      >
                        Edit
                      </LinkToEntity>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {renderPagination()}
        </>
      ) : (
        <EmptyResult />
      )}
    </Box>
  )
}
