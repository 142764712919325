import React from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import { Box, styled } from '@mui/system'
import { formatter } from '@/utils/format'
import { Loader } from '../Loader'
import { labelDisplayedRows } from '@/utils/inputs'
import { PaymentsMethod } from '@/api/payments-methods'
import { LinkToEntity } from '@/components/shared/LinkToEntity'
import { EmptyResult } from '@/components/shared/EmptyResult'
import { TablePaginationActions } from '@/components/shared/TablePaginationActions'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

interface Props {
  paymentMethods: PaymentsMethod[]
  isLoading: boolean
  count: number
  hasNext: boolean
  hasPrevious: boolean
  page: number
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number,
  ) => void
}

export const PaymentsMethodsTable: React.FC<Props> = ({
  paymentMethods,
  isLoading,
  count,
  hasNext,
  hasPrevious,
  page,
  onPageChange,
}) => {
  if (isLoading) {
    return <Loader />
  }

  const renderPagination = () => (
    <TablePagination
      component="div"
      count={count}
      rowsPerPage={100}
      rowsPerPageOptions={[100]}
      labelDisplayedRows={labelDisplayedRows}
      page={page}
      onPageChange={onPageChange}
      backIconButtonProps={{
        disabled: !hasPrevious,
      }}
      nextIconButtonProps={{
        disabled: !hasNext,
      }}
      ActionsComponent={TablePaginationActions}
    />
  )

  return (
    <Box sx={{ mt: 5 }}>
      {paymentMethods.length ? (
        <>
          {renderPagination()}
          <TableContainer
            sx={{
              minWidth: '100%',
              width: 'min-content',
            }}
            component={Paper}
          >
            <Table sx={{ minWidth: 650, whiteSpace: 'nowrap' }}>
              <TableHead>
                <TableRow>
                  <TableCell>Payment Method ID</TableCell>
                  <TableCell>Title</TableCell>

                  <TableCell>Date Created</TableCell>
                  <TableCell>Date Updated</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paymentMethods.map((method) => (
                  <StyledTableRow
                    key={method.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {method.id}
                    </TableCell>
                    <TableCell>{method.title}</TableCell>
                    <TableCell>{formatter.date(method.date_created)}</TableCell>
                    <TableCell>{formatter.date(method.date_updated)}</TableCell>
                    <TableCell>
                      <LinkToEntity
                        entity="paymentMethod"
                        operation="read"
                        params={{ id: method.id }}
                        style={{ marginRight: '10px' }}
                      >
                        View
                      </LinkToEntity>
                      <LinkToEntity
                        entity="paymentMethod"
                        operation="update"
                        params={{ id: method.id }}
                      >
                        Edit
                      </LinkToEntity>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {renderPagination()}
        </>
      ) : (
        <EmptyResult />
      )}
    </Box>
  )
}
