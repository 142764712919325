import { http } from '@/services/api/http'
import saveFile from 'save-file'
import { makeDownloadFileName } from '@/utils/makeDownloadFileName'

/* eslint-disable */

export interface MembershipSummaryReport {
  number_current_members: number
  number_current_memberships: number
  number_outstanding_renewals: number
  number_people_in_grace_period: number
  number_memberships_in_grace_period: number
  number_student_collectors: number
  number_of_traditional_memberships: number
  number_of_digital_memberships: number
  number_of_transitioning_to_digital_memberships: number
}

export interface MailingListReport {
  ZIP: string
  city: string
  country: string
  member_full_name: string
  member_id: number
  state: string
  street: string
}

export interface MembershipDirectoryReport {
  membership_id: number
  street: string
  city: string
  state: string
  ZIP: string
  country: string
  directory_email: string
  member_id: number
  first_name: string
  last_name: string
  company: string
  phone: string
  member_email: string
  interests: string
  digital: string
}

export interface EmailListingReport {
  membership_id: number
  member_id: number
  member_full_name: string
  member_email: string
  member_directory_email: string
}

export interface MemberContestReport {
  recruiter_member_id: number
  recruiter_name: string
  recruited_member_id: number
  recruited_name: string
  recruited_member_registration_date: string
}

export interface MemberDonationsReport {
  payment_id: number
  payment_date: string
  member_full_name: string
  donation_amount: number
}

/* eslint-enable */

const RESOURCE = '/reports'

const createDownloadMethod =
  (path: string) =>
  async (params: Record<string, any> = {}) => {
    const response = await http.get(`${RESOURCE}/${path}/`, {
      responseType: 'blob',
      params: {
        file: 'xlsx',
        ...params,
      },
    })

    await saveFile(response.data, makeDownloadFileName(`ipba-${path}`))
  }

const getMembershipSummaryReport = async () => {
  const response = await http.get<MembershipSummaryReport>(
    `${RESOURCE}/membership-summary-report/`,
  )
  return response.data
}

const getMailingListReport = async () => {
  const response = await http.get<MailingListReport[]>(
    `${RESOURCE}/mailing-list-report/`,
  )
  return response.data
}

const getEmailListingReport = async () => {
  const response = await http.get<EmailListingReport[]>(
    `${RESOURCE}/email-listing-report-enews/`,
  )
  return response.data
}

const getMembershipDirectoryReport = async () => {
  const response = await http.get<MembershipDirectoryReport[]>(
    `${RESOURCE}/membership-directory-report/`,
  )
  return response.data
}

const getMemberContestReport = async (filters: Record<string, unknown>) => {
  const response = await http.get<MemberContestReport[]>(
    `${RESOURCE}/member-contest-report/`,
    {
      params: {
        ...filters,
      },
    },
  )
  return response.data
}

const getMemberDonationsReport = async (filters: Record<string, unknown>) => {
  const response = await http.get<MemberDonationsReport[]>(
    `${RESOURCE}/member-donations-report/`,
    {
      params: {
        ...filters,
      },
    },
  )
  return response.data
}

export const reportsAPI = {
  getMembershipSummaryReport,
  getMailingListReport,
  getMembershipDirectoryReport,
  getEmailListingReport,
  getMemberContestReport,
  getMemberDonationsReport,

  downloadMailingListReport: createDownloadMethod('mailing-list-report'),
  downloadMembershipDirectoryReport: createDownloadMethod(
    'membership-directory-report',
  ),
  downloadEmailListingReport: createDownloadMethod(
    'email-listing-report-enews',
  ),
  downloadMemberContestReport: createDownloadMethod('member-contest-report'),
  downloadMemberDonationsReport: createDownloadMethod(
    'member-donations-report',
  ),
}
