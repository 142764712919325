import { formatter } from './format'

export const formatFilters = (
  filters: Record<string, unknown>,
): Record<string, unknown> => {
  const result: Record<string, unknown> = {}

  Object.keys(filters).forEach((key) => {
    const value = filters[key]
    if (value instanceof Date) {
      const date = filters[key] as Date
      result[key] = formatter.date(date, 'filter')
      return
    }
    if (value || value === 0) {
      result[key] = value
    }
  })

  return result
}
