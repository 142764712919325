import { CRUDEntityConfig, CRUDEntity, CRUDOperation } from '@/types'
import { generatePath } from 'react-router'

export const crudConfig: CRUDEntityConfig[] = [
  {
    entity: 'member',
    paths: {
      create: '/members/create',
      readList: '/members',
      read: '/members/view/:id',
      update: '/members/edit/:id',
    },
  },
  {
    entity: 'membership',
    paths: {
      create: '/memberships/create',
      readList: '/memberships',
      read: '/memberships/view/:id',
      update: '/memberships/edit/:id',
    },
  },
  {
    entity: 'payment',
    paths: {
      create: '/payments/create',
      readList: '/payments',
      read: '/payments/view/:id',
      update: '/payments/edit/:id',
    },
  },
  {
    entity: 'paymentMethod',
    paths: {
      create: '/payments-methods/create',
      readList: '/payments-methods',
      read: '/payments-methods/view/:id',
      update: '/payments-methods/edit/:id',
    },
  },
  {
    entity: 'country',
    paths: {
      create: '/countries/create',
      readList: '/countries',
      read: '/countries/view/:id',
      update: '/countries/edit/:id',
    },
  },
]

export const crudConfigMap: Record<CRUDEntity, CRUDEntityConfig> = (() => {
  const out: Record<string, any> = {}
  crudConfig.forEach((cc) => {
    out[cc.entity] = cc
  })
  return out as Record<CRUDEntity, CRUDEntityConfig>
})()

export const getCRUDLink = (
  entity: CRUDEntity,
  operation: CRUDOperation | string,
  params: Record<string, string | number> = {},
): string | '#' => {
  const conf = crudConfigMap[entity]
  if (conf === undefined) return '#'

  const path = conf.paths[operation]
  if (path === undefined) return '#'

  try {
    return generatePath(path, params)
  } catch (e) {
    console.error(e)
    return '#'
  }
}
