import React, { useEffect } from 'react'
import { formatForm } from '@/utils/format-form'
import { Container } from '@mui/material'
import { useMutation, useQuery } from 'react-query'
import { useParams, useHistory } from 'react-router'
import { toast } from 'react-toastify'
import { TitleWithBackButton } from '@/components/layout/TitleWIthBackButton'
import { Loader } from '@/components/Loader'
import { paymentsMethodsAPI } from '@/api/payments-methods'
import { PaymentsMethodsForm } from '@/components/payments-methods'
import { useGoBack } from '@/hooks/useGoBack'

export const PaymentsMethodsEdit = () => {
  const goBack = useGoBack()
  const history = useHistory()
  const { id } = useParams<{ id: string }>()

  const {
    data: paymentsMethod,
    isLoading: isPaymentsMethodLoading,
    isError,
  } = useQuery(['payments-methods', id], () => paymentsMethodsAPI.getOne(+id))
  useEffect(() => {
    if (isError) {
      history.push('/payments-methods')
    }
  }, [history, isError])

  const { isLoading, mutate: updateMutation } = useMutation(
    ({ paymentMethodId, form }: { paymentMethodId: string; form: any }) =>
      paymentsMethodsAPI.update(paymentMethodId, form),
  )

  const { mutate: deleteMutation } = useMutation((paymentMethodId: string) =>
    paymentsMethodsAPI.deleteOne(paymentMethodId),
  )

  const handleSubmit = (form: any) => {
    updateMutation(
      { paymentMethodId: id, form: formatForm(form) },
      {
        onSuccess: () => {
          toast.success('Payment method successfully updated.')
          // goBack('/payments-methods')
        },
      },
    )
  }

  const handleDelete = () => {
    deleteMutation(id, {
      onSuccess: () => {
        toast.success('Payment method successfully deleted.')
        history.push('/payments-methods')
      },
    })
  }

  return (
    <Container maxWidth="xl">
      <TitleWithBackButton
        title="Edit Payment Method Details"
        to="/payments-methods"
      />
      {isPaymentsMethodLoading ? (
        <Loader />
      ) : (
        <PaymentsMethodsForm
          onSubmit={handleSubmit}
          onDelete={handleDelete}
          isDisabled={isLoading}
          values={paymentsMethod}
          mode="edit"
        />
      )}
    </Container>
  )
}
