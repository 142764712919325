import React, { useState } from 'react'
import { Container, Typography, Button } from '@mui/material'
import { Box } from '@mui/system'
import { useQuery } from 'react-query'
import { MembershipsFilters, MembershipsTable } from '@/components/memberships'
import { formatFilters } from '@/utils/format-filters'
import { Link } from 'react-router-dom'
import {
  GetMembershipsResponse,
  MembershipOrderBy,
  membershipsAPI,
  MembershipStatus,
} from '@/api/memberships'
import { PageTitle } from '@/components/shared/PageTitle'
import { useCountries } from '@/hooks/useCountries'

export const MembershipsList = () => {
  const [page, setPage] = useState(1)
  const [filters, setFilters] = useState<Record<string, any>>({
    status: MembershipStatus.Active,
  })
  const [ordering, setOrdering] = useState<MembershipOrderBy>('-id')
  const countries = useCountries()
  const { data, isLoading, error } = useQuery<GetMembershipsResponse, Error>(
    ['memberships', page, filters, ordering],
    () => membershipsAPI.get(page, filters, ordering),
  )

  const handlePageChange = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    current: number,
  ) => {
    setPage(current + 1)
  }

  const handleChangeFilters = (notFormatedFilters: Record<string, unknown>) => {
    setPage(1)
    setFilters(formatFilters(notFormatedFilters))
  }

  const handleExportMembers = () => {
    membershipsAPI.exportToFile({ ...filters }, ordering)
  }

  const handleResetFilters = () =>
    setFilters({
      status: MembershipStatus.Active,
    })

  if (error) {
    return <p>Something went wrong. Please, try again later.</p>
  }

  return (
    <Container maxWidth="xl">
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', mt: 6, mb: 4 }}
      >
        <PageTitle>Memberships</PageTitle>
        <div>
          <Button component={Link} to="/memberships/create" variant="contained">
            + Add New
          </Button>
          <Button
            onClick={handleExportMembers}
            variant="contained"
            sx={{ ml: 3 }}
          >
            Export...
          </Button>
        </div>
      </Box>
      <MembershipsFilters
        onReset={handleResetFilters}
        onSubmit={handleChangeFilters}
      />
      <MembershipsTable
        memberships={data?.results || []}
        countries={countries.data}
        onPageChange={handlePageChange}
        ordering={ordering}
        onChangeOrdering={(o) => {
          setOrdering(o)
          setPage(1)
        }}
        page={page - 1}
        isLoading={isLoading}
        count={data?.count || 0}
        hasNext={!!data?.next}
        hasPrevious={!!data?.previous}
      />
    </Container>
  )
}
