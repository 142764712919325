import React from 'react'
import { MembersForm } from '@/components/members'
import { Container } from '@mui/material'
import { useMutation, useQueryClient } from 'react-query'
import { formatForm } from '@/utils/format-form'
import { membersAPI } from '@/api/members'
import { toast } from 'react-toastify'
import { TitleWithBackButton } from '@/components/layout/TitleWIthBackButton'
import { useHistory } from 'react-router'
import { getErrorMessage } from '@/api/getErrorMessage'

export const MembersCreate = () => {
  const history = useHistory()
  const queryClient = useQueryClient()

  const { isLoading, mutate } = useMutation((form: any) =>
    membersAPI.create(form),
  )

  const handleSubmit = (form: any) => {
    mutate(formatForm(form), {
      onSuccess: (data) => {
        toast.success('Member created')
        queryClient.invalidateQueries('members')
        if (data?.id) {
          history.push(`/members/edit/${data.id}`)
        }
      },
      onError: (error) => {
        const message = getErrorMessage(error)
        toast.error(`Member isn't created. ${message}`)
      },
    })
  }

  return (
    <Container maxWidth="xl">
      <TitleWithBackButton title="Add New Member" to="/members" />
      <MembersForm onSubmit={handleSubmit} isDisabled={isLoading} />
    </Container>
  )
}
