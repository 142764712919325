/**
 * @description The DesktopDatePicker component.
 */
import React from 'react'
import DatePicker, { DesktopDatePickerProps } from '@mui/lab/DesktopDatePicker'
import { TextField } from '@mui/material'

type Props = Omit<DesktopDatePickerProps, 'renderInput'> & {
  renderInput?: DesktopDatePickerProps['renderInput']
}

export const DesktopDatePicker: React.FC<Props> = function (props) {
  const { renderInput, ...rest } = props

  return (
    <DatePicker
      OpenPickerButtonProps={{
        size: 'small',
        sx: {
          'marginRight': '-0.4rem',
          '& svg': {
            fontSize: '1.2rem',
          },
        },
      }}
      renderInput={
        renderInput || ((params) => <TextField {...params} size="small" />)
      }
      {...rest}
    />
  )
}
