import React from 'react'
import { UserRole } from '@/services/store/types'
import { crudConfigMap } from '@/crudConfig'

import {
  MembersCreate,
  MembersEdit,
  MembersList,
  MembersView,
} from '@/pages/members'

import {
  MembershipsCreate,
  MembershipsEdit,
  MembershipsList,
  MembershipsView,
} from '@/pages/memberships'

import {
  CountriesCreate,
  CountriesEdit,
  CountriesList,
  CountriesView,
} from '@/pages/countries'

import {
  PaymentsCreate,
  PaymentsEdit,
  PaymentsList,
  PaymentsView,
} from '@/pages/payments'

import {
  PaymentsMethodsCreate,
  PaymentsMethodsEdit,
  PaymentsMethodsList,
  PaymentsMethodsView,
} from '@/pages/payments-methods'

import { Account } from '@/pages/Account'
import { Wordpress } from '@/pages/wordpress/Wordpress'

import { ReportsDummy } from '@/pages/reports/Reports'
import { reportsConfig } from '@/reportsConfig'
import { MembershipSummary } from '@/pages/reports/MembershipSummary'
import { MailingList } from '@/pages/reports/MailingList'
import { MembershipDirectory } from '@/pages/reports/MembershipDirectory'
import { EmailListing } from '@/pages/reports/EmailListing'
import { MemberContest } from '@/pages/reports/MemberContest'
import { MembersDonations } from '@/pages/reports/MembersDonations'

type Route = {
  path: string
  component: React.ComponentType
  roles: UserRole[]
}

export const routes: Route[] = [
  {
    path: '/account',
    component: Account,
    roles: ['administrator', 'news_editor', 'president', 'publisher'],
  },
  {
    path: crudConfigMap.member.paths.readList,
    component: MembersList,
    roles: ['administrator'],
  },
  {
    path: crudConfigMap.member.paths.create,
    component: MembersCreate,
    roles: ['administrator'],
  },
  {
    path: crudConfigMap.member.paths.update,
    component: MembersEdit,
    roles: ['administrator'],
  },
  {
    path: crudConfigMap.member.paths.read,
    component: MembersView,
    roles: ['administrator'],
  },
  {
    path: crudConfigMap.membership.paths.readList,
    component: MembershipsList,
    roles: ['administrator'],
  },
  {
    path: crudConfigMap.membership.paths.create,
    component: MembershipsCreate,
    roles: ['administrator'],
  },
  {
    path: crudConfigMap.membership.paths.update,
    component: MembershipsEdit,
    roles: ['administrator'],
  },
  {
    path: crudConfigMap.membership.paths.read,
    component: MembershipsView,
    roles: ['administrator'],
  },
  {
    path: crudConfigMap.payment.paths.readList,
    component: PaymentsList,
    roles: ['administrator'],
  },
  {
    path: crudConfigMap.payment.paths.create,
    component: PaymentsCreate,
    roles: ['administrator'],
  },
  {
    path: crudConfigMap.payment.paths.update,
    component: PaymentsEdit,
    roles: ['administrator'],
  },
  {
    path: crudConfigMap.payment.paths.read,
    component: PaymentsView,
    roles: ['administrator'],
  },
  {
    path: crudConfigMap.paymentMethod.paths.readList,
    component: PaymentsMethodsList,
    roles: ['administrator'],
  },
  {
    path: crudConfigMap.paymentMethod.paths.create,
    component: PaymentsMethodsCreate,
    roles: ['administrator'],
  },
  {
    path: crudConfigMap.paymentMethod.paths.update,
    component: PaymentsMethodsEdit,
    roles: ['administrator'],
  },
  {
    path: crudConfigMap.paymentMethod.paths.read,
    component: PaymentsMethodsView,
    roles: ['administrator'],
  },
  {
    path: crudConfigMap.country.paths.readList,
    component: CountriesList,
    roles: ['administrator'],
  },
  {
    path: crudConfigMap.country.paths.create,
    component: CountriesCreate,
    roles: ['administrator'],
  },
  {
    path: crudConfigMap.country.paths.update,
    component: CountriesEdit,
    roles: ['administrator'],
  },
  {
    path: crudConfigMap.country.paths.read,
    component: CountriesView,
    roles: ['administrator'],
  },
  {
    path: '/reports',
    component: ReportsDummy,
    roles: ['administrator', 'news_editor', 'president', 'publisher'],
  },
  {
    path: reportsConfig.membershipSummary.routePath,
    component: MembershipSummary,
    roles: reportsConfig.membershipSummary.roles,
  },
  {
    path: reportsConfig.mailingList.routePath,
    component: MailingList,
    roles: reportsConfig.mailingList.roles,
  },
  {
    path: reportsConfig.membershipDirectory.routePath,
    component: MembershipDirectory,
    roles: reportsConfig.membershipDirectory.roles,
  },
  {
    path: reportsConfig.emailListing.routePath,
    component: EmailListing,
    roles: reportsConfig.emailListing.roles,
  },
  {
    path: reportsConfig.memberContest.routePath,
    component: MemberContest,
    roles: reportsConfig.memberContest.roles,
  },
  {
    path: reportsConfig.donations.routePath,
    component: MembersDonations,
    roles: reportsConfig.donations.roles,
  },
  {
    path: '/wordpress',
    component: Wordpress,
    roles: ['administrator'],
  },
]
