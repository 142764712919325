import React, { useState } from 'react'
import { Container, Typography, Button } from '@mui/material'
import { Box } from '@mui/system'

import { useQuery } from 'react-query'
import { formatFilters } from '@/utils/format-filters'
import { Link } from 'react-router-dom'
import { PaymentsFilters, PaymentsTable } from '@/components/payments'
import { GetPaymentsResponse, paymentsAPI } from '@/api/payments'
import { PageTitle } from '@/components/shared/PageTitle'
import {
  GetAllPaymentsMethods,
  paymentsMethodsAPI,
} from '@/api/payments-methods'

export const PaymentsList = () => {
  const [page, setPage] = useState(1)
  const [filters, setFilters] = useState({})
  const { data, isLoading, error } = useQuery<GetPaymentsResponse, Error>(
    ['payments', page, filters],
    () => paymentsAPI.get(page, filters),
  )
  const { data: methods, isLoading: methodsLoading } = useQuery<
    GetAllPaymentsMethods,
    Error
  >('payments-methods', paymentsMethodsAPI.getAll)

  const handlePageChange = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    current: number,
  ) => {
    setPage(current + 1)
  }

  const handleChangeFilters = (notFormatedFilters: Record<string, unknown>) => {
    setPage(1)
    setFilters(formatFilters(notFormatedFilters))
  }

  const handleExportMembers = () => {
    paymentsAPI.exportToFile({ ...filters })
  }

  const handleResetFilters = () => setFilters({})

  if (error) {
    return <p>Something went wrong. Please, try again later.</p>
  }

  return (
    <Container maxWidth="xl">
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', mt: 6, mb: 4 }}
      >
        <PageTitle>Payments</PageTitle>
        <div>
          <Button component={Link} to="/payments/create" variant="contained">
            + Add New
          </Button>
          <Button
            onClick={handleExportMembers}
            variant="contained"
            sx={{ ml: 3 }}
          >
            Export...
          </Button>
        </div>
      </Box>
      <PaymentsFilters
        onReset={handleResetFilters}
        onSubmit={handleChangeFilters}
      />
      <PaymentsTable
        payments={data?.results || []}
        methods={methods}
        onPageChange={handlePageChange}
        page={page - 1}
        isLoading={isLoading || methodsLoading}
        count={data?.count || 0}
        hasNext={!!data?.next}
        hasPrevious={!!data?.previous}
      />
    </Container>
  )
}
